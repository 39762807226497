import React from 'react';
import { Box, Stack, IconButton } from '@mui/material';
import { TextView } from '@atoms';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { DateTime } from 'luxon';

interface HeaderProps {
  companyToken?: CompanyToken;
  dateTime: DateTime;
  onSelectRange?: (start: DateTime, end: DateTime) => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { onSelectRange, dateTime, companyToken } = props;

  const months = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  function isMonthYearValid(year: number, month: number): boolean {
    const currentDate = DateTime.local();
    const inputDate = DateTime.fromObject({ year, month });

    return inputDate < currentDate;
  }

  return (
    <Box>
      <TextView display={'initial'} fontSize={36} fontWeight={600}>
        SR Token Usage - {companyToken?.companyName}
      </TextView>
      <TextView maxWidth={'xs'} mt={1} fontWeight={400}>
        Below you will find a summary of SR Token usage for your Location. All dates and times are
        UTC-based, and data may be delayed up to 1 Day.
      </TextView>

      <Stack direction={'row'} alignItems={'center'} mt={2} mb={2}>
        <IconButton
          onClick={() => {
            const previousMonth = dateTime.minus({ months: 1 });
            // Get the start of the current month
            const startOfMonth = previousMonth.startOf('month');
            const endOfMonth = previousMonth.endOf('month');
            onSelectRange && onSelectRange(startOfMonth, endOfMonth);
          }}
        >
          <ArrowBackIos />
        </IconButton>

        <TextView display={'initial'} fontSize={26} fontWeight={600} mr={2} ml={2}>
          {months[dateTime.month]}, {dateTime.year}
        </TextView>
        <IconButton
          onClick={() => {
            if (isMonthYearValid(dateTime.year, dateTime.month)) {
              const nextMonth = dateTime.plus({ months: 1 });
              // Get the start of the current month
              const startOfMonth = nextMonth.startOf('month');

              const endOfMonth = nextMonth.endOf('month');
              onSelectRange && onSelectRange(startOfMonth, endOfMonth);
            }
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Stack>
    </Box>
  );
};
export default Header;
