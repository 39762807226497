import { DateTime } from 'luxon';

const generateRows = (tokens: Keys[], onDelete: (token: Keys) => void): TableRow[] =>
  tokens.map((token) => {
    const action: TableCellAction = {
      type: `action`,
      data: [
        {
          type: 'delete',
          linkData: {
            onClick: (value) => {
              onDelete(token);
            }
          }
        }
      ]
    };

    const payload: TableRow = {
      name: token.name ?? '',
      createdAt: DateTime.fromISO(token.createdAt).toFormat('dd LLL, yyyy hh:mm:ss'),
      action
    };

    return payload;
  });

export default generateRows;
