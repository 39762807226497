import fetchAPI from '../fetchAPI';

type CreateInviteUserParameters = {
  // roleId: string,
  companyId: string;
  phone: string;
  email: string;
  name: string;
  //  roleId: string,
  autoChatSync: boolean;
};

export const callCreateInviteUser = async (
  props: CreateInviteUserParameters
): Promise<iResponse<{ message: Agent }>> => {
  const response: iResponse<{ message: Agent }> = await fetchAPI({
    method: `POST`,
    route: `agent/invite`,
    data: props
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callCreateInviteUser;
