import React, { useEffect, useState } from 'react';
import { Box, Stack, LinearProgress, Grid } from '@mui/material';
import { Button, Text, TextView } from '../../../../atoms';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { callGetWhatsappCounts } from '@particles';
import { Token } from '@mui/icons-material';
import { CardPayment } from '@organisms';
import CustomizedYAxisTick from '../particles/CustomizedYAxisTick';
import CustomizedXAxisTick from '../particles/CustomizedXAxisTick';

interface callGetSrtTokenUsageProps {
  companyToken?: CompanyToken;
  dates: { start: DateTime; end: DateTime };
  TemplateCount: number;
  InboundCount: number;
  OutboundCount: number;
}

// whatsapp
// whatsapp_inbound
// whatsapp_outbound
const WhatappChart: React.FC<callGetSrtTokenUsageProps> = ({
  companyToken,
  dates,
  TemplateCount,
  InboundCount,
  OutboundCount
}: callGetSrtTokenUsageProps) => {
  const [values, setValues] = useState<WhatsAppCount[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const startDate = dates.start.toISO({
    suppressMilliseconds: true,
    includeOffset: false
  });
  const endDate = dates.end.toISO({
    suppressMilliseconds: true,
    includeOffset: false
  });

  useEffect(() => {
    const api = async () => {
      setLoading(true);
      if (startDate && endDate) {
        const responseSrt = await callGetWhatsappCounts({
          from: startDate,
          to: endDate,
          companyTokenId: companyToken?.id
        });

        if (responseSrt.code === 200 && responseSrt.data) {
          const counts = responseSrt.data.data;

          let day = dates.start;
          const lastDay = dates.end;

          const newValues: WhatsAppCount[] = [];
          while (day <= lastDay) {
            let metValue: WhatsAppCount = {
              date: day.toISO(),
              templateCount: 0,
              inboundCount: 0,
              outboundCount: 0
            };
            counts.forEach((item) => {
              if (item.date && DateTime.fromISO(item.date).day == day.day) {
                metValue = item;
              }
            });
            newValues.push(metValue);
            day = day.plus({ day: 1 });
          }
          setValues(newValues);
        }
      }
      setLoading(false);
    };
    api();
  }, [dates, companyToken]);

  const templateCount = values
    .map((item) => item.templateCount)
    .reduce(function (accumulator, currentValue) {
      return accumulator + currentValue;
    }, 0);
  const inboundCount = values
    .map((item) => item.inboundCount)
    .reduce(function (accumulator, currentValue) {
      return accumulator + currentValue;
    }, 0);
  const outboundCount = values
    .map((item) => item.outboundCount)
    .reduce(function (accumulator, currentValue) {
      return accumulator + currentValue;
    }, 0);

  const downloadLogs = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const apiBase = process.env.REACT_APP_API_BASE;
    const queryString = `${endDate ? `&endDate=${endDate}` : ''}${
      startDate ? `&startDate=${startDate}` : ''
    }${companyToken ? `&companyTokenId=${companyToken?.id}` : ``}`;
    const urlApi = `${apiBase}/whatsapp/csv-download?=${queryString}`;

    const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
    const hour = Number(zone[0]);
    const mint = Number(zone[1]);
    const totalMint: number = hour * 60 + mint;

    const fetchOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        timezone: `${totalMint}`
      },
      method: 'GET'
    };

    const response: Response = await fetch(urlApi, fetchOptions);

    if (response.status == 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `WhatsApp ${DateTime.now().toFormat('LLL dd yyyy, hh:mm:ss')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast('Something went wrong');
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        mt: 2
      }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <TextView $fontSize={26} $fontWeight={700}>
          Whatsapp
        </TextView>
        <Button
          label="Export"
          onClick={downloadLogs}
          size="small"
          sx={{ mr: 2 }}
          disabled={loading}
        />
      </Stack>

      {loading && <LinearProgress sx={{ mb: 1 }} />}
      <Grid container columnSpacing={4}>
        <Grid item sm={12} md={4} mt={4}>
          <CardPayment title="Template" count={`${TemplateCount}`} icon={<Token />} />
        </Grid>
        <Grid item sm={12} md={4} mt={4}>
          <CardPayment title="Inbound" count={`${InboundCount}`} icon={<Token />} />
        </Grid>
        <Grid item sm={12} md={4} mt={4}>
          <CardPayment title="Outbound" count={`${OutboundCount}`} icon={<Token />} />
        </Grid>
      </Grid>
      <Box
        mt={1}
        sx={{
          backgroundColor: 'rgba(36, 36, 35, 0.08)',
          pt: 2,
          borderRadius: 1,
          height: 300
        }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={values}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              interval={0}
              height={80}
              tick={(props) => CustomizedXAxisTick(props)}
            />
            <YAxis tick={(props) => CustomizedYAxisTick(props)} />
            <Tooltip
              itemSorter={(item) => {
                switch (item.dataKey) {
                  case 'templateCount':
                    return 0;
                  case 'inboundCount':
                    return 1;
                  case 'outboundCount':
                    return 1;
                  default:
                    return 2;
                }
              }}
            />
            <Bar dataKey="templateCount" stackId="a" fill="#086BDE" />
            <Bar dataKey="inboundCount" stackId="a" fill="#F5CB5C" />
            <Bar dataKey="outboundCount" stackId="a" fill="#fe4a49" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default WhatappChart;
