import React, { useEffect, useState } from 'react';
import { Box, Grid, LinearProgress, Stack } from '@mui/material';
import { Button, TextView } from '../../../../atoms';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';
import { callGetSrtTokens } from '@api';
import { CardPayment } from '@organisms';
import { Token } from '@mui/icons-material';
import { toast } from 'react-toastify';
import CustomizedYAxisTick from '../particles/CustomizedYAxisTick';
import CustomizedXAxisTick from '../particles/CustomizedXAxisTick';
import CustomBarLabel from '../particles/CustomBarLabel';
import CustomTooltip from '../particles/CustomTooltip';

interface callGetSrtTokenUsageProps {
  companyToken?: CompanyToken;
  dates: { start: DateTime; end: DateTime };
  TwillioInboundCallCount: number;
  TwillioOutboundCallCount: number;
  TwillioOutboundSmsCount: number;
  TwillioInboundSmsCount: number;
  TwillioOtherCount: number;
}

let twilio_other_count = 0,
  inbound_sms_count = 0,
  outbound_sms_count = 0,
  inbound_call_count = 0,
  outbound_call_count = 0;

const TwilioChart: React.FC<callGetSrtTokenUsageProps> = ({
  companyToken,
  dates,
  TwillioInboundCallCount,
  TwillioOutboundCallCount,
  TwillioOutboundSmsCount,
  TwillioInboundSmsCount,
  TwillioOtherCount
}: callGetSrtTokenUsageProps) => {
  const [chartValues, setChartValues] = useState<SrtToken[]>([]);
  const [twilio, setTwilio] = useState<{ [key: string]: SrtToken[] }>({});
  const [loading, setLoading] = useState<boolean>(false);

  const startDate = dates.start.toISO({
    suppressMilliseconds: true,
    includeOffset: false
  });

  const endDate = dates.end.toISO({
    suppressMilliseconds: true,
    includeOffset: false
  });

  const api = async () => {
    setLoading(true);
    if (startDate && endDate) {
      const values: { [key: string]: SrtToken[] } = {};

      const twilio_other = await callGetSrtTokens({
        from: startDate,
        to: endDate,
        companyTokenId: companyToken?.id,
        markupType: 'twilio_other'
      });
      if (twilio_other.code === 200 && twilio_other.data) {
        const data = twilio_other.data as SrtToken[];
        values.twilio_other = data;
        twilio_other_count =
          data.length > 0
            ? data
                ?.map((item) => item.srtToken)
                ?.reduce((accumulator, currentValue) => accumulator + currentValue)
            : 0;
      }
      const inbound_sms = await callGetSrtTokens({
        from: startDate,
        to: endDate,
        companyTokenId: companyToken?.id,
        markupType: 'inbound_sms'
      });
      if (inbound_sms.code === 200 && inbound_sms.data) {
        const data = inbound_sms.data as SrtToken[];
        values.inbound_sms = data;
        inbound_sms_count =
          data.length > 0
            ? data
                ?.map((item) => item.srtToken)
                ?.reduce((accumulator, currentValue) => accumulator + currentValue)
            : 0;
      }
      const outbound_sms = await callGetSrtTokens({
        from: startDate,
        to: endDate,
        companyTokenId: companyToken?.id,
        markupType: 'outbound_sms'
      });
      if (outbound_sms.code === 200 && outbound_sms.data) {
        const data = outbound_sms.data as SrtToken[];
        values.outbound_sms = data;
        outbound_sms_count =
          data.length > 0
            ? data
                ?.map((item) => item.srtToken)
                ?.reduce((accumulator, currentValue) => accumulator + currentValue)
            : 0;
      }
      const inbound_call = await callGetSrtTokens({
        from: startDate,
        to: endDate,
        companyTokenId: companyToken?.id,
        markupType: 'inbound_call'
      });
      if (inbound_call.code === 200 && inbound_call.data) {
        const data = inbound_call.data as SrtToken[];
        values.inbound_call = data;
        inbound_call_count =
          data.length > 0
            ? data
                ?.map((item) => item.srtToken)
                ?.reduce((accumulator, currentValue) => accumulator + currentValue)
            : 0;
      }
      const outbound_call = await callGetSrtTokens({
        from: startDate,
        to: endDate,
        companyTokenId: companyToken?.id,
        markupType: 'outbound_call'
      });
      if (outbound_call.code === 200 && outbound_call.data) {
        const data = outbound_call.data as SrtToken[];
        values.outbound_call = data;
        outbound_call_count =
          data.length > 0
            ? data
                ?.map((item) => item.srtToken)
                ?.reduce((accumulator, currentValue) => accumulator + currentValue)
            : 0;
      }
      setTwilio(values);
      setLoading(false);
    }
  };

  useEffect(() => {
    api();
  }, [dates, companyToken]);

  useEffect(() => {
    if (twilio && Object.keys(twilio).length > 0) {
      let day = dates.start;
      const lastDay = dates.end;

      const totel = Object.values(twilio).reduce((result, item) => result.concat(item));

      const values: SrtToken[] =
        totel?.reduce((result: SrtToken[], item) => {
          // Check if there's an existing entry with the same date
          const existingItem = result.find((existing) => existing.date === item.date);

          if (existingItem) {
            // If an entry with the same date exists, update the srtToken
            existingItem.srtToken += item.srtToken;
          } else {
            // If no entry with the same date exists, add a new entry
            result.push({ ...item });
          }

          return result;
        }, []) ?? [];

      const newValues: SrtToken[] = [];

      while (day <= lastDay) {
        let metValue: SrtToken = {
          id: '',
          companyTokenId: '',
          locationId: '',
          markupType: '',
          title: '',
          date: day.toISO(),
          srtToken: 0,
          createdAt: '',
          updatedAt: '',
          deletedAt: null
        };
        values.forEach((item) => {
          if (
            item.date &&
            DateTime.fromISO(item.date).day == day.day &&
            DateTime.fromISO(item.date).month == day.month &&
            DateTime.fromISO(item.date).year == day.year
          ) {
            metValue = item;
          }
        });
        newValues.push(metValue);
        day = day.plus({ day: 1 });
      }
      setChartValues(newValues);
    }
  }, [twilio]);

  const downloadLogs = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const apiBase = process.env.REACT_APP_API_BASE;

    const queryString = `
    ${companyToken?.id ? `&companyTokenId=${companyToken?.id}` : ``}
    ${`&markupType=twillio`}
    ${startDate ? `&startDate=${startDate}` : ``}
    ${endDate ? `&endDate=${endDate}` : ``}`;

    const urlApi = `${apiBase}/twilio-usage/csv-download?downloadCsv=true&${queryString}`;

    const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
    const hour = Number(zone[0]);
    const mint = Number(zone[1]);
    const totalMint: number = hour * 60 + mint;

    const fetchOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}` ?? '',
        'Content-Type': 'application/json',
        timezone: `${totalMint}`
      },
      method: 'GET'
    };

    const response: Response = await fetch(urlApi, fetchOptions);

    if (response.status == 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Twillio ${DateTime.now().toFormat('LLL dd yyyy, hh:mm:ss')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast('Something went wrong');
    }
    setLoading(false);
  };

  return (
    <Box mt={2}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <TextView $fontSize={26} $fontWeight={700}>
          Twilio
        </TextView>
        <Button
          label="Export"
          onClick={downloadLogs}
          size="small"
          sx={{ mr: 2 }}
          disabled={loading}
        />
      </Stack>
      {loading && <LinearProgress sx={{ mb: 1 }} />}
      <Grid container columnSpacing={4}>
        <Grid item sm={12} md={4} mt={2}>
          <CardPayment
            title="Call"
            count={`${TwillioInboundCallCount + TwillioOutboundCallCount}`}
            icon={<Token />}
          />
        </Grid>
        <Grid item sm={12} md={4} mt={2}>
          <CardPayment
            title="SMS"
            count={`${TwillioOutboundSmsCount + TwillioInboundSmsCount}`}
            icon={<Token />}
          />
        </Grid>
        <Grid item sm={12} md={4} mt={2}>
          <CardPayment title="Other" count={`${TwillioOtherCount}`} icon={<Token />} />
        </Grid>
      </Grid>
      <Box
        mt={1}
        sx={{
          backgroundColor: 'rgba(36, 36, 35, 0.08)',
          pt: 2,
          pb: 2,
          borderRadius: 1,
          height: 300
        }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartValues}
            margin={{
              top: 20,
              right: 20,
              left: 0,
              bottom: 5
            }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis interval={0} dataKey="date" tick={(props) => CustomizedXAxisTick(props)} />
            <YAxis tick={(props) => CustomizedYAxisTick(props)} />
            <Tooltip content={(props) => CustomTooltip(props)} />
            <Bar dataKey="srtToken" stackId="a" fill="#086BDE" label={CustomBarLabel} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default TwilioChart;
