import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { TextView } from '../../atoms';
import { DateTime } from 'luxon';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { callGetAgentEngagement } from '@particles';

interface EngagementByAgentProps {
  start?: Date;
  end?: Date;
  companyId?: string;
  team?: Team;
}

const EngagementByAgent: React.FC<EngagementByAgentProps> = ({
  start,
  end,
  companyId,
  team
}: EngagementByAgentProps) => {
  const [agentEngagement, setAgentEngagement] = useState<AgentEngagement[]>([]);

  useEffect(() => {
    const api = async () => {
      const startDate = start
        ? DateTime.fromJSDate(start).toISO({
            suppressMilliseconds: true,
            includeOffset: false
          })
        : undefined;
      const endDate = end
        ? DateTime.fromJSDate(end).toISO({
            suppressMilliseconds: true,
            includeOffset: false
          })
        : undefined;

      if (startDate && endDate) {
        const resAgentEngagement = await callGetAgentEngagement({
          from: startDate,
          to: endDate,
          companyId: companyId,
          teamId: team?.id
        });
        if (resAgentEngagement.code === 200 && resAgentEngagement.data) {
          setAgentEngagement(resAgentEngagement.data);
        }
      }
    };

    api();
  }, [start, end, companyId, team]);

  interface CustomizedAxisTickProps {
    x: number;
    y: number;
    payload: { value: any };
  }

  const CustomizedAxisTick = (props: CustomizedAxisTickProps) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={16}
          dy={10}
          textAnchor="end"
          fill="#666"
          style={{ fontFamily: 'M', fontSize: 10, fontWeight: 400 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomizedYAxisTick = (props: CustomizedAxisTickProps) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          textAnchor="end"
          fill="#666"
          style={{ fontFamily: 'Montserrat', fontSize: 10, fontWeight: 400 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <Box sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)', p: 1, borderRadius: 1, height: 300 }}>
      <TextView $fontSize={24} sx={{ marginLeft: '20px', color: 'black' }}>
        Engagement By Agents
      </TextView>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 1 }}>
        <Box sx={{ width: '24px', height: '4px', backgroundColor: '#086BDE', mr: 1 }} />
        <TextView $fontSize={12}  $fontWeight={400}>
          Received
        </TextView>
        <Box sx={{ width: '24px', height: '4px', backgroundColor: '#F5CB5C', ml: 1, mr: 1 }} />
        <TextView $fontSize={12}  $fontWeight={400}>
          Sent
        </TextView>
      </Stack>
      <ResponsiveContainer width="97%" height="80%">
        <BarChart
          data={agentEngagement}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" tick={(props) => CustomizedAxisTick(props)} />
          <YAxis tick={(props) => CustomizedYAxisTick(props)} />
          <Tooltip
            itemSorter={(item) => {
              switch (item.dataKey) {
                case 'sent':
                  return 0;
                case 'received':
                  return 1;
                default:
                  return 2;
              }
            }}
          />
          <Bar dataKey="received" stackId="a" fill="#086BDE" />
          <Bar dataKey="sent" stackId="a" fill="#F5CB5C" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default EngagementByAgent;
