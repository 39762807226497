import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

import { TextViewProps } from './index';

const StyledTextView = styled(Typography)<TextViewProps>`
  font-family: Montserrat
  ${({ $fontWeight }) => ($fontWeight ? `font-weight: ${$fontWeight};` : ``)}
  ${({ $fontColor }) => ($fontColor ? `color: ${$fontColor};` : ``)}
    ${({ $fontSize }) => ($fontSize ? `font-size: ${$fontSize}px;` : ``)}
    ${({ $backgroundColor }) => ($backgroundColor ? `background-color: ${$backgroundColor};` : ``)}
    ${({ $textTransform }) => ($textTransform ? `text-transform: ${$textTransform};` : ``)}
`;

export default StyledTextView;
