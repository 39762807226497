import React from 'react';
import { TableInstance, Row } from 'react-table';
import MaterialBody from '@mui/material/TableBody';
import MaterialRow from '@mui/material/TableRow';
import MaterialCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

import { TextView } from '../../../atoms';
import { navigate } from '@reach/router';

export interface TableBodyProps {
  /** Table columns */
  tableInstance: TableInstance<TableRow>;
  /** If provided, an expansion button will be added to the end and this component will show when expanded */
  renderSubComponent?: (row: Row<TableRow>) => React.ReactElement;
  /** If true, the API request is still awaiting results */
  isLoading: boolean;
  pageSize?: number;
}

export const TableBody: React.FC<TableBodyProps> = ({
  tableInstance,
  isLoading,
  pageSize
}: TableBodyProps) => {
  const { getTableBodyProps, page, prepareRow, visibleColumns } = tableInstance;

  const onRowClick = ({ to, href, onClick }: LinkData) => {
    if (to) return navigate(to);
    if (href) return navigate(href);
    if (onClick) return onClick(undefined);
  };

  return (
    <MaterialBody {...getTableBodyProps()}>
      {isLoading &&
        [...Array(pageSize).keys()].map((index) => (
          <MaterialRow key={index}>
            {visibleColumns.map((_col, index2) => (
              <MaterialCell key={index2}>
                <TextView variant="h5">
                  <Skeleton animation="wave" />
                </TextView>
              </MaterialCell>
            ))}
          </MaterialRow>
        ))}
      {!isLoading &&
        page.map((row) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          const linkData: LinkData = row.original.linkData as LinkData;

          return (
            <React.Fragment key={key}>
              <MaterialRow
                {...restRowProps}
                {...(linkData && {
                  onClick: () => onRowClick(linkData),
                  className: `pointer`
                })}
              >
                {row.cells.map((cell) => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  return (
                    <MaterialCell
                      key={key}
                      sx={{ fontSize: 16, overflowWrap: 'anywhere' }}
                      {...restCellProps}
                    >
                      {cell.render(`Cell`)}
                    </MaterialCell>
                  );
                })}
              </MaterialRow>
            </React.Fragment>
          );
        })}
    </MaterialBody>
  );
};

export default TableBody;
