// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyC0hCqFRDcD0-VzdshTx65kSRzCF8SGssc',
  authDomain: 'salesrook.firebaseapp.com',
  projectId: 'salesrook',
  storageBucket: 'salesrook.appspot.com',
  messagingSenderId: '649775577082',
  appId: '1:649775577082:web:6d2db76dd03d7330981643'
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
