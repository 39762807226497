import fetchAPI from '../fetchAPI';

interface callGetWhatsappCountsProps {
  from?: string;
  to?: string;
  companyTokenId?: string;
}

export const callGetWhatsappCounts = async (
  props: callGetWhatsappCountsProps
): Promise<iResponse<{ message: string; data: WhatsAppCount[] }>> => {
  const { from, to, companyTokenId } = props;
  const queryParams = new URLSearchParams();
  if (from) {
    queryParams.append('startDate', from);
  }
  if (to) {
    queryParams.append('endDate', to);
  }
  if (companyTokenId) {
    queryParams.append('companyTokenId', companyTokenId);
  }

  const queryString = queryParams.toString();

  const response: iResponse<{ message: 'success'; data: WhatsAppCount[] }> = await fetchAPI({
    method: `GET`,
    route: `whatsapp`,
    queryString
  });

  return response;
};

export default callGetWhatsappCounts;
