import fetchAPI from '../fetchAPI';

export const callDeleteCompanyToken = async (id: string): Promise<iResponse<CompanyToken>> => {
  const response: iResponse<CompanyToken> = await fetchAPI({
    method: `DELETE`,
    route: `company-token/${id}`
  });

  return response;
};

export default callDeleteCompanyToken;
