import React from 'react';
import { TypographyProps } from '@mui/material/Typography';

import StyledTextView from './textView.styles';

export interface TextViewProps extends TypographyProps {
  /** The weight of the font */
  $fontWeight?: number;
  /** The size of the font */
  $fontSize?: number;
  /** The color of the font */
  $fontColor?: string;
  /** The background color of the font */
  $backgroundColor?: string;
  /** Transforms the text with different types of capitalization */
  $textTransform?: `capitalize` | `lowercase` | `uppercase`;
}

export const TextView: React.FC<TextViewProps> = ({
  $fontColor = '#242424',
  $fontWeight = 600,
  $fontSize = 16,
  ...props
}: TextViewProps) => (
  <StyledTextView
    $fontColor={$fontColor}
    $fontWeight={$fontWeight}
    $fontSize={$fontSize}
    {...props}
  />
);

export default TextView;
