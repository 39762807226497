import fetchAPI from '../fetchAPI';

type callCreateLicenseProps = {
  // roleId: string,
  // id: string;
  companyId: string;
  amount: number;
  paymentDate: string; //'2022-06-13T19:19:28.873Z';
  users: number;
  startDate: string;
  endDate: string;
  remarks: string;
  //createdAt: string;
  //updatedAt: string;
  //deletedAt: string;
};

export const callCreateLicense = async (
  props: callCreateLicenseProps
): Promise<iResponse<{ message: Agent }>> => {
  const response: iResponse<{ message: Agent }> = await fetchAPI({
    method: `POST`,
    route: `company/license`,
    data: props
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callCreateLicense;
