import React from 'react';
import { Grid, LinearProgress } from '@mui/material';

import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconActive } from '@assets/svg/active.svg';
import { ReactComponent as IconPeople } from '@assets/svg/people.svg';
import { CommonCard } from '@organisms';

interface CardListProps {
  loading: boolean;
  accounts: number;
  users: number;
  actives: number;
}

const CardList: React.FC<CardListProps> = ({
  accounts,
  users,
  actives,
  loading
}: CardListProps) => {
  return (
    <>
      {loading && <LinearProgress sx={{ mb: 1 }} />}
      <Grid container columnSpacing={2}>
        <Grid md={4} sm={12} item>
          <CommonCard title="Total Accounts" count={`${accounts}`} icon={<IconPeople />} />
        </Grid>
        <Grid md={4} sm={12} item>
          <CommonCard title="Total Licences" count={`${users}`} icon={<IconTeam />} />
        </Grid>
        <Grid md={4} sm={12} item>
          <CommonCard title="Activated Licenses" count={`${actives}`} icon={<IconActive />} />
        </Grid>
      </Grid>
    </>
  );
};

export default CardList;
