import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@atoms';

export interface DialogProps {
  open: boolean;
  /** Title for the alert */
  title?: string;
  /** Title for the alert */
  positiveTitle?: string;
  /** Title for the alert */
  negitaiveTitle?: string;
  /** Message for the alert */
  message?: string;
  /** Callback fired when the component requests to be closed */
  onClose?: () => void;
  /** Callback fired when the component requests to be closed */
  onSuccess?: () => void;
}

export const iDialog: React.FC<DialogProps> = ({
  open,
  title,
  message,
  onClose,
  onSuccess,
  positiveTitle,
  negitaiveTitle
}: DialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{negitaiveTitle ?? 'Disagree'}</Button>
      <Button onClick={onSuccess}>{positiveTitle ?? 'Agree'}</Button>
    </DialogActions>
  </Dialog>
);

export default iDialog;
