import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { useState, useEffect, useContext } from 'react';
import ReviseLicence from '../reviseLicence';
import LicenceDetails from '../licenceDetails';
import { callGetLicense } from '@api';
import { TableContext } from '@particles';
import { DateTime } from 'luxon';
import { TextView, Button } from '@atoms';

interface BillingTableProps {
  isSuperAdmin: boolean;
  companyId: string;
  licensedCount: number;
  activeCount: number;
  license?: License;
}

const BillingTable: React.FC<BillingTableProps> = (props: BillingTableProps) => {
  const { isSuperAdmin, license, activeCount, licensedCount, companyId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [licenseHistory, setLicenseHistory] = useState<Pagination<LicenseHistory[]>>();
  const [selectedLicenseHistory, setSelectedLicenseHistory] = useState<LicenseHistory>();
  const [openDetails, setOpenDetails] = useState<boolean>();
  const [openRevise, setOpenRevise] = useState<boolean>(false);

  const {
    state: {
      billingPagination: { page, perPage }
    }
  } = useContext(TableContext);

  const apiLicenceHistory = async () => {
    if (!companyId) {
      return;
    }
    setLoading(true);
    const { code: licenseCode, data: licenseData } = await callGetLicense({
      id: companyId,
      pageNumber: page,
      pageSize: perPage
    });
    if (licenseCode == 200) {
      setLicenseHistory(licenseData);
    }
    setLoading(false);
  };

  useEffect(() => {
    apiLicenceHistory();
  }, [page, perPage]);

  const rowData: TableRow[] = licenseHistory
    ? generateRows(
        isSuperAdmin,
        licenseHistory.data,
        (licence) => {
          setOpenDetails(true);
          setSelectedLicenseHistory(licence);
        },
        (history: LicenseHistory) => {
          if (isSuperAdmin) {
            setOpenRevise(true);
            setSelectedLicenseHistory(history);
          }
        }
      )
    : [];

  const downloadFile = async () => {
    const token = localStorage.getItem('token');
    const apiBase = process.env.REACT_APP_API_BASE;
    const urlApi = `${apiBase}/company/${companyId}/license?download=true`;

    const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
    const hour = Number(zone[0]);
    const mint = Number(zone[1]);
    const totalMint: number = hour * 60 + mint;

    const fetchOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}` ?? '',
        'Content-Type': 'application/json',
        timezone: `${totalMint}`
      },
      method: 'GET'
    };

    const response: Response = await fetch(urlApi, fetchOptions);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Billinghistory.csv');
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mt: 3, mb: 4 }}>
        <TextView display={'initial'} $fontSize={30}>
          Billing History
        </TextView>
        <Box sx={{ flex: 1 }} />
        <Button
          onClick={() => {
            if (isSuperAdmin) {
              setOpenRevise(true);
            } else {
              downloadFile();
            }
          }}
        >
          {isSuperAdmin ? 'Revise licence' : 'Download history'}
        </Button>
      </Box>

      <Table
        name="billing"
        columns={columns}
        data={rowData}
        isLoading={loading}
        pagination={licenseHistory}
      />

      <ReviseLicence
        licensed={licensedCount}
        active={activeCount}
        license={license}
        companyId={companyId}
        open={openRevise}
        selectedLicenseHistory={selectedLicenseHistory}
        onClose={() => {
          setOpenRevise(false);
          setSelectedLicenseHistory(undefined);
        }}
        onSuccess={() => {
          setOpenRevise(false);
          apiLicenceHistory();
          setSelectedLicenseHistory(undefined);
        }}
      />
      <LicenceDetails
        open={openDetails ?? false}
        selectedLicenseHistory={selectedLicenseHistory}
        onClose={() => setOpenDetails(undefined)}
      />
    </Box>
  );
};

export default BillingTable;
