import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Text, TextView } from '../../../../../../atoms';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';

interface callGetSrtTokenUsageProps {
  usageData?: SrtToken[];
  dates: { start: DateTime; end: DateTime };
}

const SrtUsageChart: React.FC<callGetSrtTokenUsageProps> = ({
  usageData,
  dates
}: callGetSrtTokenUsageProps) => {
  const [values, setValues] = useState<SrtToken[]>([]);

  useEffect(() => {
    if (usageData) {
      const lastDay = dates.start.daysInMonth ?? 0;
      let day = dates.start;

      const values: SrtToken[] =
        usageData?.reduce((result: SrtToken[], item) => {
          // Check if there's an existing entry with the same date
          const existingItem = result.find((existing) => existing.date === item.date);

          if (existingItem) {
            // If an entry with the same date exists, update the srtToken
            existingItem.srtToken += item.srtToken;
          } else {
            // If no entry with the same date exists, add a new entry
            result.push({ ...item });
          }

          return result;
        }, []) ?? [];

      const newValues: SrtToken[] = [];
      for (let i = 1; i <= lastDay; i++) {
        let metValue: SrtToken = {
          id: '',
          companyTokenId: '',
          locationId: '',
          markupType: '',
          title: '',
          date: day.toISO(),
          srtToken: 0,
          createdAt: '',
          updatedAt: '',
          deletedAt: null
        };
        values.forEach((item) => {
          if (item.date && DateTime.fromISO(item.date).day == i) {
            metValue = item;
          }
        });
        newValues.push(metValue);
        day = day.plus({ day: 1 });
      }
      setValues(newValues);
    }
  }, [usageData]);

  interface CustomizedAxisTickProps {
    x: number;
    y: number;
    payload: { value: any };
  }

  const CustomizedAxisTick = (props: CustomizedAxisTickProps) => {
    const { x, y, payload } = props;

    return (
      <text
        x={x}
        y={y}
        dx={16}
        dy={10}
        textAnchor="end"
        fill="#666"
        style={{ fontFamily: 'Montserrat', fontSize: 10, fontWeight: 400 }}
      >
        {DateTime.fromISO(payload.value).toFormat('dd LLL')}
      </text>
    );
  };

  const CustomizedYAxisTick = (props: CustomizedAxisTickProps) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={4}
          textAnchor="end"
          fill="#666"
          style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400 }}
        >
          {Number(payload.value)}
        </text>
      </g>
    );
  };

  const CustomTooltip = (props: any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const data = payload[0];

      if (!data || !data.value) {
        return null;
      }

      return (
        <Box sx={{ backgroundColor: 'white', border: '1px solid #ccc', padding: 1 }}>
          <Text $fontSize={12}>{DateTime.fromISO(label).toFormat('yyyy-LL-dd')}</Text>
          <Text $fontColor={'green'} $fontSize={16}>
            {Number(data.value).toFixed(2)}
          </Text>
          {/* Add more custom content here */}
        </Box>
      );
    }

    return null;
  };

  const renderCustomBarLabel = ({
    x,
    y,
    width,
    value
  }: {
    x: number;
    y: number;
    width: number;
    value: number;
  }) => {
    return (
      <text x={x + width / 2} y={y} fill="#666" fontSize={10} textAnchor="middle" dy={-6}>{`${
        value > 0 ? value.toFixed(0) : ''
      }`}</text>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(36, 36, 35, 0.08)',
        pt: 2,
        borderRadius: 1,
        height: 400
      }}
    >
      <TextView fontSize={22} fontWeight={600} ml={4}>
        Daily usage (SR Token)
      </TextView>
      <Box mt={2} />
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          data={values}
          margin={{
            top: 20,
            right: 20,
            left: 0,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis interval={0} dataKey="date" tick={(props) => CustomizedAxisTick(props)} />
          <YAxis tick={(props) => CustomizedYAxisTick(props)} />
          <Tooltip content={(props) => CustomTooltip(props)} />
          <Bar dataKey="srtToken" stackId="a" fill="#086BDE" label={renderCustomBarLabel} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default SrtUsageChart;
