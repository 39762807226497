const columns: TableColumn[] = [
  {
    Header: `Date`,
    accessor: `date`,
    dbKey: `date`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Description`,
    accessor: `title`,
    dbKey: `title`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `SR Tokens`,
    accessor: `srtToken`,
    dbKey: `srtToken`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
