import fetchAPI from '../fetchAPI';

interface callGetCompanyUsersProps {
  companyId: string;
  teamId?: string;
  pageNumber?: number;
  pageSize?: number;
}

export const callGetCompanyUsers = async ({
  companyId,
  teamId,
  pageNumber,
  pageSize
}: callGetCompanyUsersProps): Promise<iResponse<Pagination<Agent[]>>> => {
  const queryParams = new URLSearchParams();
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  if (teamId) {
    queryParams.append('teamId', teamId);
  }

  const queryString = queryParams.toString();

  const response: iResponse<Pagination<Agent[]>> = await fetchAPI({
    method: `GET`,
    route: `company/${companyId}/all-users`,
    queryString
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);
  return response;
};

export default callGetCompanyUsers;
