import fetchAPI from '../fetchAPI';

interface callGetAllCompanyTokenProps {
  locationId?: string;
  companyTokenId?: string;
}

export const callGetCompanyToken = async (
  props: callGetAllCompanyTokenProps
): Promise<iResponse<{ message: string; data?: CompanyToken }>> => {
  const { locationId, companyTokenId } = props;

  const queryParams = new URLSearchParams();
  if (locationId) {
    queryParams.append('locationId', locationId);
  }
  if (companyTokenId) {
    queryParams.append('companyTokenId', companyTokenId);
  }
  const queryString = queryParams.toString();
  const response: iResponse<{ message: string; data?: CompanyToken }> = await fetchAPI({
    method: `GET`,
    route: `company-token/id`,
    queryString
  });

  return response;
};

export default callGetCompanyToken;
