import * as React from 'react';
import Box from '@mui/material/Box';
import { TextView } from '@atoms';
import { RouteComponentProps } from '@reach/router';

import { Grid } from '@mui/material';

const TaskReport: React.FC<RouteComponentProps> = () => {
  const url = `${process.env.REACT_APP_API_BASE}/core/task-report?url=https://sharing.clickup.com/9004070502/b/h/8caydk6-5136/779c50b9460c304`;

  return (
    <Box m={4}>
      <TextView display={'initial'} $fontSize={30}>
        Task Report
      </TextView>
      <Grid container spacing={4} mt={4}>
        <iframe height="900" width="100%" src={url}></iframe>
      </Grid>
    </Box>
  );
};

export default TaskReport;
