import fetchAPI from '../fetchAPI';

interface callAnalyticTokencountProps {
  companyTokenId?: string;
  startDate: string | null;
  endDate: string | null;
}

export const callGetAnalytictokenCount = async (
  props: callAnalyticTokencountProps
): Promise<iResponse<{ message: string; data?: TokenCounts }>> => {
  const { startDate, endDate, companyTokenId } = props;

  // Convert dates to ISO string only if they are provided (not null)
  const queryString = `${companyTokenId ? `&companyTokenId=${companyTokenId}` : ''}${
    startDate ? `&startDate=${startDate}` : ''
  }${endDate ? `&endDate=${endDate}` : ''}`;

  const response: iResponse<{ message: string; data?: TokenCounts }> = await fetchAPI({
    method: 'GET',
    route: 'company-token/token-consumption-detail',
    queryString
  });

  return response;
};

export default callGetAnalytictokenCount;
