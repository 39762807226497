import React from 'react';
import { Box, Divider } from '@mui/material';

import { ReactComponent as IconBuilding } from '@assets/svg/building.svg';
import { ReactComponent as IconWebsite } from '@assets/svg/website.svg';
import { ReactComponent as IconLocation } from '@assets/svg/location.svg';

import { Avatar, TextView } from '@atoms';

interface CompanyDetailsProps {
  user?: User;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({ user }: CompanyDetailsProps) => {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: '#E8EDDF',
        p: 4,
        boxShadow:
          '0px 7px 140px rgba(141, 152, 121, 0.1), 0px 2px 32px rgba(141, 152, 121, 0.05), 0px 1px 10px rgba(141, 152, 121, 0.04)',
        borderRadius: '6px'
      }}
    >
      <Box sx={{ alignItems: 'center', width: '33%', display: 'flex', flexDirection: 'column' }}>
        <Avatar $size="md" />
        <TextView $fontSize={24}>{user?.name}</TextView>
        <TextView  $fontWeight={400}>
          {user?.email}
        </TextView>
        <TextView  $fontWeight={400}>
          {user?.phone}
        </TextView>
      </Box>
      <Divider orientation={'vertical'} flexItem />
      <Box sx={{ pl: 4 }}>
        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <IconBuilding />
          <Box sx={{ ml: 1, textAlign: 'initial' }}>
            <TextView $fontSize={24} $fontWeight={400}>
              {user?.company?.name}
            </TextView>
            <TextView  $fontWeight={400}>
              {user?.company?.dbaName}
            </TextView>
          </Box>
        </Box>
        <Box sx={{ flexDirection: 'row', display: 'flex', p: 1, mt: 2 }}>
          <IconWebsite />
          <TextView sx={{ ml: 1 }}  $fontWeight={400}>
            {user?.company?.website}
          </TextView>
        </Box>
        <Box sx={{ flexDirection: 'row', display: 'flex', p: 1 }}>
          <IconLocation />
          <TextView sx={{ ml: 1 }}  $fontWeight={400}>
            {user?.company?.address}
          </TextView>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyDetails;
