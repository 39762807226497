import React, { useState } from 'react';
import { Box, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Button, TextView } from '@atoms';
import { DateRangePicker, Range } from 'react-date-range';
import Popover from '@mui/material/Popover';
import EventIcon from '@mui/icons-material/EventOutlined';
import { DateTime } from 'luxon';
import { materialTheme } from '@particles';
import { EngagementByAgent, EngagementOverTime } from '@organisms';

interface AccountEngagementHeaderProps {
  title: string;
  companyId?: string;
  teamList: Team[];
}

const AccountEngagementHeader: React.FC<AccountEngagementHeaderProps> = (
  props: AccountEngagementHeaderProps
) => {
  const { title, teamList, companyId } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedTeamForFilter, setSelectedTeamForFilter] = useState<Team>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [range, setRange] = useState<Range[]>([
    {
      startDate: DateTime.local().minus({ days: 6 }).toJSDate(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleChangeTeamSelected = (event: SelectChangeEvent) => {
    const _teamData = teamList.find((item) => item.id === event.target.value);
    setSelectedTeamForFilter(_teamData);
  };

  return (
    <Box>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <TextView display={'initial'} $fontSize={30}>
          {title}
        </TextView>
        <Box sx={{ flex: 1 }} />
        <TextView $fontWeight={400} $fontSize={14} mr={1}>
          Filters
        </TextView>
        <Select
          value={selectedTeamForFilter?.id ?? '0'}
          onChange={handleChangeTeamSelected}
          displayEmpty
          size={'small'}
          sx={{ backgroundColor: '#ffffff', fontSize: 14, mr: 1 }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem key={0} value={'0'}>
            {'All Team'}
          </MenuItem>
          {teamList.map((item) => {
            return (
              <MenuItem key={item.id} value={`${item.id}`}>
                {item.teamName}
              </MenuItem>
            );
          })}
        </Select>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <DateRangePicker
            onChange={(item) => setRange([item.selection])}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={range}
            direction="horizontal"
          />
        </Popover>

        <Button
          aria-describedby={id}
          sx={{ paddingLeft: 5, color: materialTheme.palette.text.primary, mr: 2 }}
          startIcon={<EventIcon />}
          variant={'outlined'}
          onClick={handleClick}
        >
          <TextView $fontWeight={400} >
            {DateTime.fromJSDate(range[0].startDate ? range[0]?.startDate : new Date()).toFormat(
              'LLLL, dd yyyy'
            )}
            {` - `}
            {DateTime.fromJSDate(range[0].endDate ? range[0]?.endDate : new Date()).toFormat(
              'LLLL, dd yyyy'
            )}
          </TextView>
        </Button>
      </Box>
      <Grid container columnSpacing={4} mt={4}>
        <Grid item sm={12} md={6}>
          <EngagementOverTime
            start={range[0]?.startDate ? range[0].startDate : new Date()}
            end={range[0]?.endDate ? range[0].endDate : new Date()}
            companyId={companyId}
            team={selectedTeamForFilter}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <EngagementByAgent
            start={range[0]?.startDate ? range[0].startDate : new Date()}
            end={range[0]?.endDate ? range[0].endDate : new Date()}
            companyId={companyId}
            team={selectedTeamForFilter}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default AccountEngagementHeader;
