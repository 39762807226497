import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Checkbox, LinearProgress, Stack } from '@mui/material';
import { callGetAllCompanyToken } from '@api';

interface SelectBillingAccounts {
  allSelected?: boolean;
  selectedAccount?: string;
  status: string;
  onSelectedAccounts: (list: string[]) => void;
}

function SelectBillingAccounts(props: SelectBillingAccounts) {
  const { allSelected, selectedAccount, onSelectedAccounts, status } = props;
  const [selectType, setSelectType] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<CompanyToken[]>([]);
  const [selectAllSelected, setSelectAllSelected] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const payload: any = {
          type: 'c',
          pageNumber: 1,
          pageSize: 10000
        };

        if (status) {
          payload.status = status;
        }

        const { data, code } = await callGetAllCompanyToken(payload);
        if (code === 200 && data) {
          const sortedData = data.data.sort((a, b) => a.companyName.localeCompare(b.companyName));
          setAccounts(sortedData);
          if (allSelected) {
            setSelectType(sortedData.map((item) => item.locationId));
          }
          if (selectedAccount) {
            setSelectType([selectedAccount]);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [status]);

  useEffect(() => {
    if (isAllSelected) {
      onSelectedAccounts([]);
    } else {
      onSelectedAccounts(selectType);
    }
  }, [selectType]);

  const handleChangelocations = (event: any) => {
    const value = event.target.value;

    if (value.includes('all')) {
      const newSelectAllSelected = !selectAllSelected;
      setSelectAllSelected(newSelectAllSelected);
      setSelectType(newSelectAllSelected ? accounts.map((item) => item.locationId) : []);
      return;
    }

    setSelectType(value);
  };

  const isAllSelected = accounts.length > 0 && selectType.length === accounts.length;

  return (
    <Stack>
      {loading && <LinearProgress color="primary" style={{ width: '100%', top: 0 }} />}
      <Select
        labelId="multiple-select-label"
        multiple
        size="small"
        value={selectType}
        style={{ width: '250px' }}
        sx={{
          backgroundColor: '#ffffff',
          fontSize: 12,
          overflowY: 'auto' // Allow vertical scrolling if the content exceeds the height
        }}
        onChange={handleChangelocations}>
        <MenuItem
          value="all"
          className={isAllSelected ? 'selected' : ''}
          style={{
            backgroundColor: isAllSelected ? '#e0e0e0' : 'inherit'
          }}>
          <Checkbox
            checked={isAllSelected}
            sx={{ padding: 0, marginRight: 1 }}
            indeterminate={selectType.length > 0 && selectType.length < accounts.length}
          />
          {'Select All'}
        </MenuItem>
        {accounts.map((account) => (
          <MenuItem
            key={account.locationId}
            value={account.locationId}
            className={selectType.includes(account.locationId) ? 'selected' : ''}
            style={{
              backgroundColor: selectType.includes(account.locationId) ? '#e0e0e0' : 'inherit'
            }}>
            <Checkbox
              sx={{ padding: 0, marginRight: 1 }}
              checked={selectType.indexOf(account.locationId) > -1}
            />
            {account.companyName}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}

export default SelectBillingAccounts;
