import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const StyledAccount = styled(Box)`
  height: 100vh;
  align-items: center;
  background-color: #e8eddf;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export default StyledAccount;
