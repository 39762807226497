import fetchAPI from '../fetchAPI';

interface callGetTotalUsersProps {
  companyId?: string;
}

export const callGetTotalUsers = async ({
  companyId
}: callGetTotalUsersProps): Promise<iResponse<TotalUser>> => {
  const response: iResponse<TotalUser> = await fetchAPI({
    method: `GET`,
    route: `crm/messages/users`,
    queryString: `${companyId ? `&companyId=${companyId}` : ``}`
  });

  return response;
};

export default callGetTotalUsers;
