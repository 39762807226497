import fetchAPI from '../fetchAPI';

export const callUpdateCustomValues = async (data: any): Promise<iResponse<UpdateCustomValue>> => {
  const response: iResponse<UpdateCustomValue> = await fetchAPI({
    method: `PUT`,
    route: `core/custom-values`,
    data
  });

  return response;
};

export default callUpdateCustomValues;
