import fetchAPI from '../fetchAPI';

export const callRemoveUserFromTeam = async (userId: string): Promise<iResponse<Team>> => {
  const response: iResponse<Team> = await fetchAPI({
    method: `POST`,
    route: `team/remove-team-from-user`,
    data: { userId }
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callRemoveUserFromTeam;
