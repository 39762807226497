import React, { useState, useContext, useEffect } from 'react';
import { Box, Modal, IconButton, Paper, Grid, LinearProgress } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import Validator from 'validator';

import { TextView, Button, TextField, Alert } from '../../atoms';
import { callDeleteTeams, callUpdateTeams, callCreateTeams } from '../../particles/api';
import { ApplicationContext } from '@particles';

interface AddCompanyTeamProps {
  open: boolean;
  companyId?: string;
  selectedTeam?: Team;
  onClose: () => void;
  onSuccess: () => void;
}

const AddCompanyTeam: React.FC<AddCompanyTeamProps> = ({
  onClose,
  open,
  onSuccess,
  selectedTeam,
  companyId
}: AddCompanyTeamProps) => {
  const {
    state: { user }
  } = useContext(ApplicationContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = React.useState({
    teamName: ''
  });

  const [error, setError] = useState<{
    title: string;
    message: string;
  }>();

  const [errors, setErrors] = useState({
    teamName: ''
  });

  useEffect(() => {
    if (selectedTeam) {
      setValues({
        teamName: selectedTeam.teamName
      });
    }
  }, [selectedTeam]);

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { teamName } = values;

    if (!user || validateFields()) {
      return;
    }

    const _companyId = companyId ?? user.companyId;

    if (selectedTeam) {
      setLoading(true);
      callUpdateTeams(selectedTeam.id, {
        teamName
      })
        .then((res) => {
          if (res.code == 200) {
            setValues({ teamName: '' });
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    } else {
      setLoading(true);
      callCreateTeams({
        teamName,
        companyId: _companyId
      })
        .then((res) => {
          if (res.code == 201) {
            setValues({ teamName: '' });
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    }
  };

  const validateFields = () => {
    let error = false;
    setErrors({ teamName: '' });
    if (Validator.isEmpty(values.teamName.trim())) {
      error = true;
      setErrors((state) => ({ ...state, teamName: 'Name is required' }));
    } else if (!Validator.isLength(values.teamName.trim(), { min: 8, max: 20 })) {
      error = true;
      setErrors((state) => ({
        ...state,
        teamName: 'Name must be between 8 and 20 characters in length.'
      }));
    }
    return error;
  };

  const api = async () => {
    if (selectedTeam) {
      setLoading(true);
      const { code, error } = await callDeleteTeams(selectedTeam.id);
      if (code >= 400 && error) {
        setError({ title: 'Error', message: error.message ?? '' });
        setTimeout(() => {
          setError(undefined);
          setLoading(false);
        }, 5000);
      } else {
        setLoading(false);
        onSuccess();
      }
    }
  };

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Grid md={4} sm={10} xs={10} item>
        <Paper sx={{ p: 4, width: '100%' }}>
          {loading && <LinearProgress />}
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <TextView $fontSize={26}>{`${selectedTeam ? 'Edit' : 'Add'} Team`}</TextView>
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                onClose();
                setValues({ teamName: '' });
              }}
            >
              <Close />
            </IconButton>
          </Box>

          <form onSubmit={onSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 3 }}>
              <TextField
                label={'Name'}
                name={'teamName'}
                errorMessage={errors.teamName}
                fullWidth
                value={values.teamName}
                onChange={handleChange('teamName')}
              />
            </Box>

            {error && <Alert severity={'error'} title={error.title} message={error.message} />}

            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                mt: 4
              }}
            >
              <Button label={`${selectedTeam ? 'Edit' : 'Add'} Team`} size="small" type="submit" />
              {selectedTeam && (
                <IconButton
                  onClick={() => {
                    api();
                  }}
                >
                  <Delete sx={{ fontSize: 40, color: 'red' }} />
                </IconButton>
              )}
            </Box>
          </form>
        </Paper>
      </Grid>
    </Modal>
  );
};
export default AddCompanyTeam;
