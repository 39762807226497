import { DateTime } from 'luxon';

const generateRows = (
  isSuperAdmin: boolean,
  licenseHistory: LicenseHistory[],
  onItemClick: (history: LicenseHistory) => void,
  onAction: (history: LicenseHistory) => void
): TableRow[] =>
  licenseHistory.map((license) => {
    const startDate = license.startDate;
    const endDate = license.endDate;
    const paymentDate = license.paymentDate;

    const edit: TableCellAction = {
      type: `action`,
      data: [
        {
          type: 'edit',
          linkData: {
            onClick: () => {
              onAction(license);
            }
          }
        }
      ]
    };

    const startEndDate: TableCellText = {
      type: `text`,
      data: {
        text: `${startDate ? DateTime.fromISO(startDate).toFormat('dd LLLL yyyy') : ''} - ${
          endDate ? DateTime.fromISO(endDate).toFormat('dd LLLL yyyy') : ''
        }`,
        fontColor: DateTime.fromISO(endDate) > DateTime.now() ? 'green' : 'red'
      }
    };

    const payload: any = {
      paymentDate: paymentDate ? DateTime.fromISO(paymentDate).toFormat('dd LLLL yyyy') : '',
      users: license.users ?? '',
      startEndDate,
      amount: license.amount ?? '',
      linkData: {
        onClick: () => {
          onItemClick(license);
        }
      }
    };

    if (isSuperAdmin) {
      payload.edit = edit;
    }

    return payload;
  });

export default generateRows;
