import fetchAPI from '../fetchAPI';

interface callGetTeamsListProps {
  companyId?: string;
}

export const callGetTeamsList = async (
  props: callGetTeamsListProps
): Promise<iResponse<Team[]>> => {
  const { companyId } = props;

  const queryParams = new URLSearchParams();
  if (companyId) {
    queryParams.append('companyId', companyId);
  }

  const queryString = queryParams.toString();

  const response: iResponse<Team[]> = await fetchAPI({
    method: `GET`,
    route: `team/get-all-teams`,
    queryString
  });

  // user?companyId=company-id
  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetTeamsList;
