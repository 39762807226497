import React, { useState, useContext } from 'react';
import { useLocation, navigate } from '@reach/router';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';

import { ReactComponent as IconSalesrook } from '@assets/svg/salesrook.svg';

import { menuConfig, MenuItem } from './partials';
import StyledSideNav from './sideNav.style';
import { ApplicationContext } from '@particles';

interface SideNavProps {
  isSuperAdmin: boolean;
}

export const SideNav: React.FC<SideNavProps> = ({ isSuperAdmin }: SideNavProps) => {
  const { dispatch } = useContext(ApplicationContext);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const location = useLocation();
  const { pathname } = location;

  // const pathname = ''

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderListItem = (item: MenuItem, itemIndex: number) => {
    const { label, Icon, cssClass, to } = item;

    const isSelected = to === pathname;
    const className = [cssClass, isSelected && `${cssClass}-selected`].filter(Boolean).join(` `);

    return (
      <Box key={itemIndex} sx={{ mt: 1 }}>
        <ListItem
          button
          selected={isSelected}
          className={className}
          onClick={() => {
            if (to === '/error-403') {
              localStorage.clear();
              dispatch({ key: 'user', value: undefined, type: 'set' });
              navigate('/login');
            } else {
              navigate(to ?? '/').then((r) => console.log(r));
            }
          }}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText
            primary={label}
            primaryTypographyProps={{ fontSize: 14, fontWeight: 500 }}
          />
        </ListItem>
      </Box>
    );
  };

  const renderMenu = () => {
    if (isSuperAdmin) {
      return menuConfig.menuConfigSuperAdmin.map((section, sectionIndex) => (
        <div key={sectionIndex}>{renderListItem(section, sectionIndex)}</div>
      ));
    } else {
      return menuConfig.menuConfigAdmin.map((section, sectionIndex) => (
        <div key={sectionIndex}>{renderListItem(section, sectionIndex)}</div>
      ));
    }
  };

  return (
    <StyledSideNav variant="permanent" $open={isCollapsed}>
      <div style={{ display: 'flex', alignSelf: !isCollapsed ? 'center' : 'auto' }}>
        {/*<HomeIcon sx={{width: '190px', height: '60px'}}/>*/}
        {isCollapsed && <IconSalesrook width={150} height={60} />}

        {isCollapsed && <Box flex={1} />}
        <IconButton onClick={handleToggle}>
          <MenuIcon sx={{color: 'white'}} />
        </IconButton>
      </div>
      <List sx={{ height: '100%' }}>{renderMenu()}</List>
    </StyledSideNav>
  );
};

export default SideNav;
