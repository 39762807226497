import { isObject } from '../../helpers';

export const applicationReducer = (
  state: ApplicationState,
  action: ApplicationReducerAction
): ApplicationState => {
  const { key, type, value } = action;

  switch (type) {
    case `set`:
      if (!key) {
        return state;
      }

      return {
        ...state,
        [key]: value
      };

    case `setMultiple`:
      if (!isObject(value)) {
        return state;
      }

      return {
        ...state,
        ...value
      };

    default:
      return state;
  }
};

export default applicationReducer;
