import React from 'react';
import AlertTitle from '@mui/material/AlertTitle';

import StyledAlert from './alert.style';

export interface AlertProps {
  /** Title for the alert */
  title?: string;
  /** Message for the alert */
  message: string;
  /** The variant to use */
  variant?: `standard` | `filled` | `outlined`;
  /** The severity to use */
  severity?: `success` | `info` | `warning` | `error`;
  /** Callback fired when the component requests to be closed */
  onClose?: (event: React.SyntheticEvent) => void;
}

export const Alert: React.FC<AlertProps> = ({ title, message, ...props }: AlertProps) => (
  <StyledAlert {...props}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {message}
  </StyledAlert>
);

export default Alert;
