import fetchAPI from '../fetchAPI';

export const callValidateGhlCode = async (code: string): Promise<iResponse<Agent[]>> => {
  const response: iResponse<Agent[]> = await fetchAPI({
    method: `GET`,
    route: `core/oauth/callback`,
    queryString: `code=${code}`
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callValidateGhlCode;
