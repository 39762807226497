import { DateTime } from 'luxon';

const generateRows = (data: SrtToken[]): TableRow[] =>
  data.map((item) => {
    const payload: TableRow = {
      date: item.date ? DateTime.fromISO(item.date).toFormat('yyyy-LL-dd') : '',
      title: `${item.title ?? ''} ${item.description ? ' - ' + item.description : ''}`,
      srtToken: item.srtToken ?? ''
    };

    return payload;
  });

export default generateRows;
