import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, LinearProgress } from '@mui/material';

import { DateTime } from 'luxon';
import { UsageChart, Header, UsageTable, UsageThisMonth } from './particles';
import { callGetCompanyToken, callGetSrtTokens } from '@api';
import { Card } from '@atoms';

interface CompanyTokenDetailsProps extends RouteComponentProps {
  locationId?: string;
  companyTokenId?: string;
  type: 'superadmin' | 'admin';
}

const CompanyTokenDetails: React.FC<CompanyTokenDetailsProps> = (
  props: CompanyTokenDetailsProps
) => {
  const { locationId, companyTokenId } = props;
  const searchParams = new URLSearchParams(props?.location?.search);

  const [start, setStart] = useState<DateTime>(
    searchParams.has('date')
      ? DateTime.fromFormat(searchParams.get('date')!, 'yyyy-LL-dd').startOf('month')
      : DateTime.now().startOf('month')
  );
  const [end, setEnd] = useState<DateTime>(
    searchParams.has('date')
      ? DateTime.fromFormat(searchParams.get('date')!, 'yyyy-LL-dd')
      : DateTime.now()
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [usageData, setUsageData] = useState<SrtToken[]>();
  const [companyToken, setCompanyToken] = useState<CompanyToken>();

  useEffect(() => {
    const api = async () => {
      const responseSrt = await callGetCompanyToken({
        locationId,
        companyTokenId
      });
      if (responseSrt.code === 200) {
        setCompanyToken(responseSrt.data?.data);
      }
    };
    api();
  }, [locationId, companyTokenId]);

  useEffect(() => {
    const api = async () => {
      setLoading(true);

      const startDate = start.toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });
      const endDate = end.toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });

      if (startDate && endDate) {
        const responseSrt = await callGetSrtTokens({
          from: startDate,
          to: endDate,
          locationId,
          companyTokenId
        });

        if (responseSrt.code === 200 && responseSrt.data) {
          setUsageData(responseSrt.data as SrtToken[]);
        }

        setLoading(false);
      }
    };

    api();
  }, [start, end, locationId, companyTokenId]);

  if (!locationId && !companyTokenId) {
    return <div>Id is Missing</div>;
  }

  return (
    <Box>
      {loading && <LinearProgress />}
      <Card sx={{ m: 4, p: 4 }}>
        <Header
          companyToken={companyToken}
          dateTime={start}
          onSelectRange={(start, end) => {
            setStart(start);
            setEnd(end);
          }}
        />

        <Box>
          <UsageChart usageData={usageData} dates={{ start, end }} />
          <UsageThisMonth usageData={usageData} company={companyToken} />
          <UsageTable
            dates={{ start, end }}
            locationId={locationId}
            companyTokenId={companyTokenId}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default CompanyTokenDetails;
