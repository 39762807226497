import React from 'react';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';

import StyledErrorAlert from './errorAlert.style';

interface ErrorAlertSharedProps {
  /** Title for the error */
  title?: string;
  /** An array of error messages to display */
  errors?: string[];
  /** The variant to use */
  variant?: `standard` | `filled` | `outlined`;
  /** Callback fired when the component requests to be closed */
  onClose?: (event: React.SyntheticEvent) => void;
}

interface ErrorAlertMessageProps extends ErrorAlertSharedProps {
  /** Message for the error */
  message: string;
  /** If provided, will override the message and errors (if type is ResponseError) props */
  error?: never;
}

interface ErrorAlertErrorProps extends ErrorAlertSharedProps {
  /** Message for the error */
  message?: never;
  /** If provided, will override the message and errors (if type is ResponseError) props */
  error: Error;
}

export type ErrorAlertProps = ErrorAlertMessageProps | ErrorAlertErrorProps;

export const ErrorAlert: React.FC<ErrorAlertProps> = ({
  title,
  message,
  errors,
  error,
  ...props
}: ErrorAlertProps) => {
  const errorMessage = error ? error.message : message;

  return (
    <StyledErrorAlert severity="error" {...props}>
      {title && <AlertTitle>{title}</AlertTitle>}
      <Box display="flex">
        <Box mr={1}>Details:</Box>
        <div>
          <Box mb={1}>{errorMessage}</Box>
        </div>
      </Box>
    </StyledErrorAlert>
  );
};

export default ErrorAlert;
