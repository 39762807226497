import fetchAPI from '../fetchAPI';

type AssignTeamToUser = {
  userId: string;
  teamId: string;
};

export const callAssignTeamToUser = async (data: AssignTeamToUser): Promise<iResponse<Team>> => {
  const response: iResponse<Team> = await fetchAPI({
    method: `POST`,
    route: `team/assign-team-to-user`,
    data
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callAssignTeamToUser;
