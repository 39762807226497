import React from 'react';
import { RouteComponentProps } from '@reach/router';

const Templates: React.FC<RouteComponentProps> = () => {
  return (
    <article>
      <section>
        <h2>Templates (Work in progress)</h2>
      </section>
    </article>
  );
};

export default Templates;
