const columns: TableColumn[] = [
  {
    Header: `Date`,
    accessor: `endDate`,
    dbKey: `endDate`,
    isString: false,
    isNumeric: false,
    isDate: true,
    isBool: false,
    isSortable: true
  },

  {
    Header: `Location Name`,
    accessor: `companyName`,
    dbKey: `companyName`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Billing`,
    accessor: `billing`,
    dbKey: `billing`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Allocated Token`,
    accessor: `allocatedToken`,
    dbKey: `allocatedToken`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Consumed Token`,
    accessor: `consumeToken`,
    dbKey: `consumeToken`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Available Token`,
    accessor: `availableToken`,
    dbKey: `availableToken`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  }
];

export default columns;
