import fetchAPI from '../fetchAPI';

interface callGetAgentEngagementProps {
  from?: string;
  to?: string;
  user?: string;
  companyId?: string;
  teamId?: string;
}

export const callGetAgentEngagement = async ({
  from,
  to,
  user,
  companyId,
  teamId
}: callGetAgentEngagementProps): Promise<iResponse<AgentEngagement[]>> => {
  const response: iResponse<AgentEngagement[]> = await fetchAPI({
    method: `GET`,
    route: `crm/messages/agent-engagement`,
    queryString: `${from ? `&from=${from}` : ''}${to ? `&to=${to}` : ''}${
      user ? `&user=${user}` : ``
    }${companyId ? `&companyId=${companyId}` : ``}${teamId ? `&teamId=${teamId}` : ``}`
  });

  return response;
};

export default callGetAgentEngagement;
