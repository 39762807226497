import fetchAPI from '../../fetchAPI';

type CoreDashboardNumbersProps = { locationId?: string; startDate?: string | null; endDate?: string | null };

export const callGetCoreDashboardNumbers = async (
  props: CoreDashboardNumbersProps
): Promise<iResponse<{ data: CoreDashboardNumbers }>> => {
  const queryParams = new URLSearchParams();
  if (props.locationId) {
    queryParams.append('locationId', props.locationId);
  }
  if (props.startDate) {
    queryParams.append('startDate', props.startDate);
  }
  if (props.endDate) {
    queryParams.append('endDate', props.endDate);
  }
  const response: iResponse<{ data: CoreDashboardNumbers }> = await fetchAPI({
    method: `GET`,
    route: `core/dashboard-numbers`,
    queryString: queryParams.toString()
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetCoreDashboardNumbers;
