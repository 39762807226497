import React, { useState } from 'react';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { TextView, Button } from '../../../../../atoms';
import { AddUserModal, UploadUserModal } from '../index';

interface HeaderProps {
  companyId?: string;
  teamList: Team[];
  onAddSuccess: () => void;
  onUploadSuccess: () => void;
  onTeamChange: (team?: Team) => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { onAddSuccess, onUploadSuccess, teamList, onTeamChange, companyId } = props;
  const [openUploadUser, setOpenUploadUser] = useState<boolean>(false);
  const [openAddUser, setOpenAddUser] = useState<boolean>(false);
  const [selectedTeamForFilter, setSelectedTeamForFilter] = useState<Team>();

  const handleChangeTeamSelected = (event: SelectChangeEvent) => {
    const _teamData = teamList.find((item) => item.id === event.target.value);
    setSelectedTeamForFilter(_teamData);
    onTeamChange(_teamData);
  };

  return (
    <Box sx={{ flexDirection: 'row', display: 'flex' }}>
      <TextView display={'initial'} $fontSize={30}>
        Team
      </TextView>
      <Box sx={{ flex: 1 }} />
      <Select
        value={selectedTeamForFilter?.id ?? '0'}
        onChange={handleChangeTeamSelected}
        displayEmpty
        size={'small'}
        sx={{ backgroundColor: '#ffffff', fontSize: 14, mr: 1 }}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem key={0} value={'0'}>
          {'All Team'}
        </MenuItem>
        {teamList.map((item) => {
          return (
            <MenuItem key={item.id} value={`${item.id}`}>
              {item.teamName}
            </MenuItem>
          );
        })}
      </Select>
      <Button primary onClick={() => setOpenAddUser(true)} sx={{ mr: 2, height: 50 }}>
        <TextView $fontSize={22}>Add User</TextView>
      </Button>
      <Button primary onClick={() => setOpenUploadUser(true)} sx={{ height: 50 }}>
        <TextView $fontSize={22}>Upload Users</TextView>
      </Button>

      <UploadUserModal
        companyId={companyId}
        open={openUploadUser}
        onClose={() => setOpenUploadUser(false)}
        onSuccess={() => {
          onAddSuccess();
          setOpenUploadUser(false);
        }}
      />

      <AddUserModal
        open={openAddUser}
        onClose={() => setOpenAddUser(false)}
        onSuccess={() => {
          onUploadSuccess();
          setOpenAddUser(false);
        }}
      />
    </Box>
  );
};
export default Header;
