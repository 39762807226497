import React from 'react';
import { TextView } from '@atoms';
import { Box, Stack } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';

interface TotalMessagesProps {
  title: string;
  title1: string;
  title2: string;
  value1: number;
  value2: number;
}

const kFormatter = (num: number) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1)) + 'k'
    : Math.sign(num) * Math.abs(num);
};

const TotalMessages: React.FC<TotalMessagesProps> = ({
  title,
  title1,
  title2,
  value1 = 0,
  value2 = 0
}: TotalMessagesProps) => {
  const data = [
    { name: title1, value: value1 },
    { name: title2, value: value2 }
  ];
  const COLORS = ['#086BDE', '#F5CB5C'];

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <Stack
          sx={{
            backgroundColor: 'black',
            pl: 1,
            pr: 1,
            borderRadius: 0.5
          }}
        >
          <TextView
            $fontColor="white"
            sx={{}}
          >{`${payload?.[0].name}: ${payload?.[0].value}`}</TextView>
        </Stack>
      );
    }

    return null;
  };

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(36, 36, 35, 0.08)',
        p: 2,
        borderRadius: 1,
        height: '91%'
      }}
    >
      <TextView $fontSize={24}>{title}</TextView>

      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: 4 }}>
        <Box sx={{ width: '24px', height: '4px', backgroundColor: '#086BDE', mr: 1 }} />
        <TextView $fontSize={10}  $fontWeight={400}>
          {title1}
        </TextView>
        <Box sx={{ width: '24px', height: '4px', backgroundColor: '#F5CB5C', ml: 1, mr: 1 }} />
        <TextView $fontSize={10}  $fontWeight={400}>
          {title2}
        </TextView>
      </Stack>
      <Box height={200}>
        {value1 !== 0 || value2 !== 0 ? (
          <Box
            height={200}
            sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
          >
            <ResponsiveContainer height={200} width={200}>
              <PieChart>
                <Pie
                  data={data}
                  innerRadius={50}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={2}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={false}
                  allowEscapeViewBox={{ x: true, y: true }}
                />
                <text
                  x={100}
                  y={100}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{ fontSize: 32, fontWeight: 600 }}
                >
                  {kFormatter(value1 + value2)}
                </text>
              </PieChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Box
            height={200}
            sx={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}
          >
            <TextView $fontSize={16}>{title} has no data.</TextView>
          </Box>
        )}

        {/* <TextView $fontSize={18}>{totalSentMessages + totalReceivedMessages}</TextView> */}
      </Box>
    </Box>
  );
};

export default TotalMessages;
