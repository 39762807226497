import React from 'react';
import { Box } from '@mui/material';
import { Button, TextView } from '../../../../atoms';

interface HeaderProps {
  title: string;
  isSuerAdmin: boolean;
  onPressUpdate?: () => void;
  onPressDelete?: () => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { title, isSuerAdmin, onPressUpdate, onPressDelete } = props;

  return (
    <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
      <TextView display={'initial'} $fontSize={30}>
        {title}
      </TextView>
      <Box sx={{ flex: 1 }} />
      {isSuerAdmin && (
        <Button
          sx={{ mr: 1, color: '#272727' }}
          variant={'outlined'}
          onClick={() => onPressDelete && onPressDelete()}
        >
          {'Delete Account'}
        </Button>
      )}
      {isSuerAdmin && (
        <Button onClick={() => onPressUpdate && onPressUpdate()}>{'Update Account'}</Button>
      )}
    </Box>
  );
};
export default Header;
