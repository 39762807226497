import { DateTime } from 'luxon';
import { routes } from '../../../../particles/common';

const generateRows = (
  tokens: CompanyToken[],
  onAction: (token: CompanyToken, value: unknown, type: 'edit' | 'delete') => void
): TableRow[] =>
  tokens.map((token) => {
    const edit: TableCellAction = {
      type: `action`,
      data: [
        {
          type: 'edit',
          linkData: {
            onClick: (value) => {
              onAction(token, value as boolean, 'edit');
            }
          }
        },
        {
          type: 'delete',
          linkData: {
            onClick: (value) => {
              onAction(token, value as boolean, 'delete');
            }
          }
        }
      ]
    };

    const twilio: TableCellChip = {
      type: 'chip',
      data: [
        {
          text: token.twilioSecret ? 'Yes' : 'No',
          backgroundColor: token.twilioSecret ? '#9ADE7B' : '#D32F33'
        }
      ]
    };

    let type: TableCellChip = {
      type: 'chip',
      data: [
        {
          text: 'C',
          backgroundColor: '#F5CB5C'
        }
      ]
    };
    if (token.locationId && token.reapitCompanyId) {
      type = {
        type: 'chip',
        data: [
          {
            text: 'N',
            backgroundColor: '#086BDE'
          },
          {
            text: 'C',
            backgroundColor: '#F5CB5C'
          }
        ]
      };
    } else if (token.locationId) {
      type = {
        type: 'chip',
        data: [
          {
            text: 'C',
            backgroundColor: '#F5CB5C'
          }
        ]
      };
    } else if (token.reapitCompanyId) {
      type = {
        type: 'chip',
        data: [
          {
            text: 'N',
            backgroundColor: '#086BDE'
          }
        ]
      };
    }

    const allocatedToken = token.totalToken ? token.totalToken : 0;
    const consumedToken = token.srtValuesBasedSubscription ? token.srtValuesBasedSubscription : 0;
    const rolling_month = token.rolling_month ? Number(token.rolling_month.toFixed(2)) : 0;
    const _availableToken = allocatedToken - consumedToken;

    const availableToken: TableCellText = {
      data: {
        text: _availableToken.toFixed(0),
        fontColor: _availableToken < 0 ? '#D32F33' : 'rgba(0, 0, 0, 0.87)'
      },
      type: 'text'
    };

    const subscriptionDate = `${DateTime.fromISO(token.subscriptionDate).toFormat(
      'dd LLL, yyyy'
    )} - ${DateTime.fromISO(token.nextSubscriptionDate).toFormat('dd LLL, yyyy')}`;

    const payload: TableRow = {
      locationId: token.locationId ?? '',
      companyName: token.companyName ?? '',
      subscriptionDate,
      // twilioSecret: token.twilioSecret ? token.twilioSecret.substring(0, 3) + '**********' : '',
      // twilioToken: token.twilioToken ? token.twilioToken.substring(0, 3) + '**********' : '',
      twilioCredentials: twilio,
      totalToken: allocatedToken,
      rolling_month,
      availableToken,
      srtValuesBasedSubscription: consumedToken > 0 ? consumedToken.toFixed(2) : consumedToken,
      type,
      action: edit,
      linkData: {
        to: `${routes.SA_BillingTokens}/${token.id}`
      }
    };

    return payload;
  });

export default generateRows;
