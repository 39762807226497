const columns: TableColumn[] = [
  {
    Header: `Payment Date`,
    accessor: `paymentDate`,
    dbKey: `paymentDate`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Active Licences`,
    accessor: `users`,
    dbKey: `users`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Licence start - end dates`,
    accessor: `startEndDate`,
    dbKey: `startEndDate`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Amount`,
    accessor: `amount`,
    dbKey: `amount`,
    isString: false,
    isNumeric: false,
    isDate: false,
    isBool: true,
    isSortable: true
  },
  {
    Header: `Action`,
    accessor: `edit`,
    dbKey: `edit`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
