import * as React from 'react';
import Box from '@mui/material/Box';
import { Table } from '@organisms';
import { useState, useContext, useEffect } from 'react';
import { TableContext, callDeleteKey, callGetKeys } from '@particles';
import { Button, TextView } from '@atoms';
import generateRows from './partials/generateRows';
import columns from './partials/columns';
import { Paper, Stack } from '@mui/material';
import AddKeyModal from './partials/createKeyModal';

const APIList: React.FC = () => {
  const {
    state: {
      apiKeys: { page, perPage, orderBy }
    }
  } = useContext(TableContext);
  const [openAddToken, setOpenAddToken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ResponseErrorObj>();
  const [keys, setKeys] = useState<Keys[]>();
  const rowData: TableRow[] = keys ? generateRows(keys, (key) => deleteKey(key)) : [];

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetKeys();
    if (code == 200) {
      setKeys(data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    api();
  }, [page, perPage, orderBy]);

  const deleteKey = async (key: Keys) => {
    setLoading(true);
    const { data, code } = await callDeleteKey(key.id);
    if (code == 200) {
      api();
    }

    setLoading(false);
  };
  return (
    <Box>
      <Stack>
        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextView display={'initial'} $fontSize={22}>
            API Keys
          </TextView>
          <Box sx={{ flex: 1 }} />
          <Button
            label="Create API Key"
            onClick={() => {
              setOpenAddToken(true);
            }}
            size="small"
          />
        </Box>
      </Stack>
      <Paper sx={{ height: 500 }}>
        <Table name="api-keys" columns={columns} data={rowData} error={error} isLoading={loading} />
      </Paper>
      <AddKeyModal
        open={openAddToken}
        onClose={() => {
          setOpenAddToken(false);
          api();
        }}
      />
    </Box>
  );
};

export default APIList;
