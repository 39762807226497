import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { materialTheme } from '../../particles';

import { ButtonProps } from './index';

const StyledButton = styled(Button)<ButtonProps>`
  border-color: ${materialTheme.palette.primary.main};
  border-width: 2px;
  font-weight: 500;
  height: fit-content;
  ${({ $fontSize }) => ($fontSize ? `font-size: ${$fontSize}rem;` : `font-size: 14px;`)}
  ${({ $hoverColor }) =>
    $hoverColor
      ? ` &:hover {
              background-color: ${$hoverColor};
          }`
      : ``}
`;

export default StyledButton;
