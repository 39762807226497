import fetchAPI from '../fetchAPI';

export const callUpdateTeams = async (id: string, data: any): Promise<iResponse<Team>> => {
  const response: iResponse<Team> = await fetchAPI({
    method: `PATCH`,
    route: `team/${id}`,
    data
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callUpdateTeams;
