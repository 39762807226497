import fetchAPI from '../fetchAPI';

interface callGetCompanyUsersProps {
  from?: string;
  to?: string;
  companyId?: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string[];
}

export const callGetCompaniesDetailedList = async ({
  from,
  to,
  companyId,
  pageNumber,
  pageSize,
  sortBy
}: callGetCompanyUsersProps): Promise<iResponse<Pagination<CompanyDetailed[]>>> => {
  const queryParams = new URLSearchParams();
  if (from) {
    queryParams.append('from', from);
  }
  if (to) {
    queryParams.append('to', to);
  }
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  if (companyId) {
    queryParams.append('companyId', companyId);
  }

  const queryString = queryParams.toString();

  const response: iResponse<Pagination<CompanyDetailed[]>> = await fetchAPI({
    method: `GET`,
    route: `company/detailed-list`,
    queryString
  });

  return response;
};

export default callGetCompaniesDetailedList;
