import fetchAPI from '../fetchAPI';

export const callUpdateCompany = async (id: string, data: unknown): Promise<iResponse<User>> => {
  const response: iResponse<User> = await fetchAPI({
    method: `PATCH`,
    route: `company/${id}`,
    data
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callUpdateCompany;
