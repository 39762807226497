import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Stack } from '@mui/material';
import { Text, TextView, Button } from '@atoms';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DateTime } from 'luxon';
import { callGetSrtTokens } from '@api';
import { toast } from 'react-toastify';
import CustomTooltip from '../particles/CustomTooltip';
import CustomBarLabel from '../particles/CustomBarLabel';
import CustomizedYAxisTick from '../particles/CustomizedYAxisTick';
import CustomizedXAxisTick from '../particles/CustomizedXAxisTick';

interface callGetSrtTokenUsageProps {
  companyToken?: CompanyToken;
  dates: { start: DateTime; end: DateTime };
}

const NegotiatorChart: React.FC<callGetSrtTokenUsageProps> = ({
  companyToken,
  dates
}: callGetSrtTokenUsageProps) => {
  const [values, setValues] = useState<SrtToken[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const startDate = dates.start.toISO({
    suppressMilliseconds: true,
    includeOffset: false
  });
  const endDate = dates.end.toISO({
    suppressMilliseconds: true,
    includeOffset: false
  });

  useEffect(() => {
    const api = async () => {
      setLoading(true);
      console.log('companyToken', companyToken);

      if (startDate && endDate) {
        const responseSrt = await callGetSrtTokens({
          from: startDate,
          to: endDate,
          companyTokenId: companyToken?.id,
          markupType: 'reapit'
        });

        if (responseSrt.code === 200 && responseSrt.data) {
          const srtToken = responseSrt.data as SrtToken[];

          let day = dates.start;
          const lastDay = dates.end;

          const values: SrtToken[] =
            srtToken?.reduce((result: SrtToken[], item) => {
              // Check if there's an existing entry with the same date
              const existingItem = result.find((existing) => existing.date === item.date);

              if (existingItem) {
                // If an entry with the same date exists, update the srtToken
                existingItem.srtToken += item.srtToken;
              } else {
                // If no entry with the same date exists, add a new entry
                result.push({ ...item });
              }

              return result;
            }, []) ?? [];

          const newValues: SrtToken[] = [];

          while (day <= lastDay) {
            let metValue: SrtToken = {
              id: '',
              companyTokenId: '',
              locationId: '',
              markupType: '',
              title: '',
              date: day.toISO(),
              srtToken: 0,
              createdAt: '',
              updatedAt: '',
              deletedAt: null
            };
            values.forEach((item) => {
              if (
                item.date &&
                DateTime.fromISO(item.date).day == day.day &&
                DateTime.fromISO(item.date).month == day.month &&
                DateTime.fromISO(item.date).year == day.year
              ) {
                metValue = item;
              }
            });
            newValues.push(metValue);
            day = day.plus({ day: 1 });
          }
          setValues(newValues);
        }
      }
      setLoading(false);
    };
    api();
  }, [dates, companyToken]);

  const downloadLogs = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const apiBase = process.env.REACT_APP_API_BASE;

    const queryString = `${endDate ? `&endDate=${endDate}` : ''}${startDate ? `&startDate=${startDate}` : ''}${companyToken ? `&companyTokenId=${companyToken?.id}` : ``}`
    const urlApi = `${apiBase}/reapit/csv-download?${queryString}`;

    const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
    const hour = Number(zone[0]);
    const mint = Number(zone[1]);
    const totalMint: number = hour * 60 + mint;

    const fetchOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}` ?? '',
        'Content-Type': 'application/json',
        timezone: `${totalMint}`
      },
      method: 'GET'
    };

    const response: Response = await fetch(urlApi, fetchOptions);

    if (response.status == 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Negotiator ${DateTime.now().toFormat('LLL dd yyyy, hh:mm:ss')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast('Something went wrong');
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(36, 36, 35, 0.08)',
        pt: 2,
        borderRadius: 1,
        height: 400
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <TextView $fontSize={22} $fontWeight={600} ml={4}>
          Negotiator
        </TextView>
        <Button
          label="Export"
          onClick={downloadLogs}
          size="small"
          sx={{ mr: 2 }}
          disabled={loading}
        />
      </Stack>
      {loading && <LinearProgress sx={{ mb: 1 }} />}
      <Box mt={2} />
      <ResponsiveContainer width="100%" height="80%">
        <BarChart
          data={values}
          margin={{
            top: 20,
            right: 20,
            left: 0,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis interval={0} dataKey="date" tick={(props) => CustomizedXAxisTick(props)} />
          <YAxis tick={(props) => CustomizedYAxisTick(props)} />
          <Tooltip content={(props) => CustomTooltip(props)} />
          <Bar dataKey="srtToken" stackId="a" fill="#086BDE" label={CustomBarLabel} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default NegotiatorChart;
