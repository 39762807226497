const columns: TableColumn[] = [
  {
    Header: `Name`,
    accessor: `name`,
    dbKey: `name`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Total Licences`,
    accessor: `total_licenses`,
    dbKey: `total_licenses`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  // {
  //   Header: `Activated Licenses`,
  //   accessor: `active`,
  //   dbKey: `active`,
  //   isString: true,
  //   isNumeric: false,
  //   isDate: false,
  //   isBool: false,
  //   isSortable: true
  // },
  {
    Header: `Active Users`,
    accessor: `active_users`,
    dbKey: `active_users`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Inactive Users`,
    accessor: `inactive_users`,
    dbKey: `inactive_users`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Total Users`,
    accessor: `total_users`,
    dbKey: `total_users`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Messages Sent`,
    accessor: `sent_messages`,
    dbKey: `sent_messages`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Messages Received`,
    accessor: `received_messages`,
    dbKey: `received_messages`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Total Synced`,
    accessor: `total_messages`,
    dbKey: `total_messages`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Expiry Date`,
    accessor: `endDate`,
    dbKey: `endDate`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `License Status`,
    accessor: `license_status`,
    dbKey: `license_status`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
