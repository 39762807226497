import fetchAPI from '../fetchAPI';

export const callDeleteBillingLicense = async (
  licenseBillingId: string
): Promise<iResponse<User>> => {
  const response: iResponse<User> = await fetchAPI({
    method: `DELETE`,
    route: `company/license/${licenseBillingId}`
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callDeleteBillingLicense;
