import styled from '@emotion/styled';
import OutlinedInput from '@mui/material/OutlinedInput';
import { red } from '@mui/material/colors';

import { materialTheme } from '../../particles';

import { InputBaseProps } from './index';

const widthSize = (width: Sizes) => {
  switch (width) {
    case `xs`:
      return `4ch`;
    case `sm`:
      return `10ch`;
    case `md`:
      return `20ch`;
    case `lg`:
      return `30ch`;
    case `xl`:
      return `50ch`;
    case `xxl`:
      return `80ch`;
    default:
      return `20ch`;
  }
};

const StyledInputBase = styled(OutlinedInput)<InputBaseProps>`
  .MuiInputBase-input {
    font-size: 14px;
    cursor: pointer;
  }
  .MuiInputBase-input::placeholder {
    font-size: 14px;
  }
  && {
    fieldset {
      border-color: #16242423;
      border-width: 1px;
    }

    &:hover {
      fieldset {
        border-color: ${materialTheme.palette.primary.main};
        border-width: 2px;
      }

      &.Mui-error fieldset {
        border-color: ${red[500]};
      }
    }

    ${(props) => {
      const { fullWidth, $width } = props;
      if (fullWidth || !$width) return;

      return `width: ${widthSize($width)};`;
    }}

    legend {
      ${({ $shrink }) =>
        $shrink !== undefined ? ($shrink ? `max-width: 100%;` : `max-width: 0%`) : ``}
    }
  }
`;

export default StyledInputBase;
