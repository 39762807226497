import styled from '@emotion/styled';
import { red } from '@mui/material/colors';

import { materialTheme } from '../../particles';
import { Stack } from '@mui/material';

const StyledTextField = styled(Stack)`
  font-family: Montserrat
  .MuiInputLabel-shrink {
    color: ${materialTheme.palette.primary.main};
  }

  .MuiFormLabel-root.Mui-error {
    color: ${red[500]};
  }
`;

export default StyledTextField;
