const columns: TableColumn[] = [
  {
    Header: `Name`,
    accessor: `name`,
    dbKey: `name`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Action`,
    accessor: `edit`,
    dbKey: `edit`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
