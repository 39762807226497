import React, { useEffect, useState } from 'react';
import { TextView, TextField } from '@atoms';
import { Box, Card, Grid, LinearProgress, Paper, Stack } from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { callGetMarkupValues, callUpdateMarupValues } from '@api';

const SuperAdminMarkupMargins: React.FC<RouteComponentProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [aiProcessing, setAiProcessing] = useState<number>(0);
  const [reapit, setReapit] = useState<number>(0);
  const [whatsappInbound, setWhatsappInbound] = useState<number>(0);
  const [whatsappOutbound, setWhatsappOutnbound] = useState<number>(0);
  const [whatsappInboundPrice, setWhatsappInboundPrice] = useState<number>(0);
  const [whatsappOutboundPrice, setWhatsappOutnboundPrice] = useState<number>(0);
  const [inboundSms, setInboundSms] = useState<number>(0);
  const [inboundCall, setInboundCall] = useState<number>(0);
  const [outboundSms, setoutboundSms] = useState<number>(0);
  const [outboundCall, setoutboundCall] = useState<number>(0);
  const [twilioOther, setTwilioOther] = useState<number>(0);
  const [whatsapp, setWhatsapp] = useState<number>(0);

  function changeEvent(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string) {
    const num = Number(e.target.value);
    switch (type) {
      case 'ai_processing':
        setAiProcessing(num);
        callUpdateMarupValues({ type: 'ai_processing', muvalue: num });
        break;
      case 'reapit':
        setReapit(num);
        callUpdateMarupValues({ type: 'reapit', muvalue: num });
        break;
      case 'inbound_sms':
        setInboundSms(num);
        callUpdateMarupValues({ type: 'inbound_sms', muvalue: num });
        break;
      case 'outbound_sms':
        setoutboundSms(num);
        callUpdateMarupValues({ type: 'outbound_sms', muvalue: num });
        break;
      case 'inbound_call':
        setInboundCall(num);
        callUpdateMarupValues({ type: 'inbound_call', muvalue: num });
        break;
      case 'outbound_call':
        setoutboundCall(num);
        callUpdateMarupValues({ type: 'outbound_call', muvalue: num });
        break;
      case 'twilio_other':
        setTwilioOther(num);
        callUpdateMarupValues({ type: 'twilio_other', muvalue: num });
        break;
      case 'whatsapp':
        setWhatsapp(num);
        callUpdateMarupValues({ type: 'whatsapp', muvalue: num });
        break;
      case 'whatsapp_inbound':
        setWhatsappInbound(num);
        callUpdateMarupValues({ type: 'whatsapp_inbound', muvalue: num });
        break;
      case 'whatsapp_inbound_price':
        setWhatsappInboundPrice(num);
        callUpdateMarupValues({ type: 'whatsapp_inbound', price: num });
        break;
      case 'whatsapp_outbound':
        setWhatsappOutnbound(num);
        callUpdateMarupValues({ type: 'whatsapp_outbound', muvalue: num });
        break;
      case 'whatsapp_outbound_price':
        setWhatsappOutnboundPrice(num);
        callUpdateMarupValues({ type: 'whatsapp_outbound', price: num });
        break;
    }
  }

  useEffect(() => {
    const api = async () => {
      setLoading(true);
      const { data, code } = await callGetMarkupValues();
      if (code === 200 && data && data.data) {
        const dataArray = data.data;
        for (let i = 0; i < dataArray.length; i++) {
          const obj = dataArray[i];
          switch (obj.type) {
            case 'ai_processing':
              setAiProcessing(obj.muvalue);
              break;
            case 'inbound_sms':
              setInboundSms(obj.muvalue);
              break;
            case 'outbound_sms':
              setoutboundSms(obj.muvalue);
              break;
            case 'inbound_call':
              setInboundCall(obj.muvalue);
              break;
            case 'outbound_call':
              setoutboundCall(obj.muvalue);
              break;
            case 'twilio_other':
              setTwilioOther(obj.muvalue);
              break;
            case 'whatsapp':
              setWhatsapp(obj.muvalue);
              break;
            case 'reapit':
              setReapit(obj.muvalue);
              break;
            case 'whatsapp_inbound':
              setWhatsappInbound(obj.muvalue);
              setWhatsappInboundPrice(obj.price);
              break;
            case 'whatsapp_outbound':
              setWhatsappOutnbound(obj.muvalue);
              setWhatsappOutnboundPrice(obj.price);
              break;
          }
        }
      }
      setLoading(false);
    };
    api();
  }, []);

  return (
    <Box sx={{ m: 5 }}>
      {loading && <LinearProgress />}
      <TextView display={'initial'} $fontSize={30}>
        Markup Margins
      </TextView>
      <Grid sm={12} lg={8} mt={3} container>
        <Card sx={{ p: 3 }}>
          <Grid spacing={2} container>
            <Grid item sm={12} lg={4}>
              <TextField
                value={aiProcessing}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'ai_processing')}
                label={'AI Processing'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextField
                value={reapit}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'reapit')}
                label={'Negotiator'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextField
                value={twilioOther}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'twilio_other')}
                label={'Other'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextField
                value={whatsapp}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'whatsapp')}
                label={'WhatsApp'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
            </Grid>
            <Grid display={'flex'} direction={'row'} item sm={12} lg={4}>
              <TextField
                value={whatsappInbound}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'whatsapp_inbound')}
                label={'WhatsApp Inbound'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
              <Box sx={{ ml: 1, width: 80 }}>
                <TextField
                  fullWidth
                  value={whatsappInboundPrice}
                  size="small"
                  type="number"
                  onChange={(e) => changeEvent(e, 'whatsapp_inbound_price')}
                  label={'$ Price'}
                  labelSize={16}
                  labelWeight={700}
                  name={''}
                />
              </Box>
            </Grid>
            <Grid display={'flex'} direction={'row'} item sm={12} lg={4}>
              <TextField
                value={whatsappOutbound}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'whatsapp_outbound')}
                label={'WhatsApp Outbound'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
              <Box sx={{ ml: 1, width: 80 }}>
                <TextField
                  value={whatsappOutboundPrice}
                  fullWidth
                  size="small"
                  type="number"
                  onChange={(e) => changeEvent(e, 'whatsapp_outbound_price')}
                  label={'$ Price'}
                  labelSize={16}
                  labelWeight={700}
                  name={''}
                />
              </Box>
            </Grid>

            <Grid item sm={12} lg={4}>
              <TextField
                value={inboundSms}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'inbound_sms')}
                label={'Inbound SMS'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextField
                value={outboundSms}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'outbound_sms')}
                label={'Outbound SMS'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextField
                value={inboundCall}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'inbound_call')}
                label={'Inbound Call'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              <TextField
                value={outboundCall}
                size="small"
                type="number"
                onChange={(e) => changeEvent(e, 'outbound_call')}
                label={'Outbound Call'}
                labelSize={16}
                labelWeight={700}
                name={''}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Box>
  );
};

export default SuperAdminMarkupMargins;
