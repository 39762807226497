import React, { useState } from 'react';
import { Grid } from '@mui/material';

import { ReactComponent as IconActive } from '@assets/svg/active.svg';
import { ReactComponent as IconDownload } from '@assets/svg/download.svg';
import { CommonCard } from '@organisms';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { LoadingCircle } from '@atoms';

interface TotalTokenCusumeProps {
  twillioCount?: number;
  aiCount: number;
  reapitCount: number;
  whatsAppTotalCount: number;
  totalTokenConsumption: number;
  eventBookCount: number;
  start: DateTime;
  end: DateTime;
  companyToken: CompanyToken | undefined;
}

const TotalTokenCusume: React.FC<TotalTokenCusumeProps> = ({
  twillioCount,
  aiCount,
  reapitCount,
  whatsAppTotalCount,
  totalTokenConsumption,
  eventBookCount,
  start,
  end,
  companyToken
}: TotalTokenCusumeProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const downloadExport = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const apiBase = process.env.REACT_APP_API_BASE;

    const startDate = start.toISO({
      suppressMilliseconds: true,
      includeOffset: false
    });
    const endDate = end.toISO({
      suppressMilliseconds: true,
      includeOffset: false
    });

    const queryString = `
    ${companyToken ? (companyToken.locationId ? `&locationId=${companyToken.locationId}` : ``) : ``}
    ${startDate ? `&startDate=${startDate}` : ``}
    ${endDate ? `&endDate=${endDate}` : ``}`;

    const urlApi = `${apiBase}/lead-event/qualified/export?=${queryString}`;

    const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
    const hour = Number(zone[0]);
    const mint = Number(zone[1]);
    const totalMint: number = hour * 60 + mint;

    const fetchOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}` ?? '',
        'Content-Type': 'application/json',
        timezone: `${totalMint}`
      },
      method: 'GET'
    };

    const response: Response = await fetch(urlApi, fetchOptions);

    if (response.status == 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Qualified ${DateTime.now().toFormat('LLL dd yyyy, hh:mm:ss')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast('Something went wrong');
    }
    setLoading(false);
  };

  return (
    <Grid container columnSpacing={2} mt={2}>
      {totalTokenConsumption !== null && totalTokenConsumption !== 0 && (
        <Grid md={2} sm={12} item>
          <CommonCard
            title="Total Token Consumption"
            count={`${totalTokenConsumption}`}
            icon={<IconActive width={60} height={50} />}
          />
        </Grid>
      )}

      {twillioCount !== null && twillioCount !== 0 && (
        <Grid md={2} sm={12} item>
          <CommonCard
            title="Total Twillio Usage"
            count={`${twillioCount}`}
            icon={<IconActive width={60} height={50} />}
          />
        </Grid>
      )}

      {aiCount !== null && aiCount !== 0 && (
        <Grid md={2} sm={12} item>
          <CommonCard
            title="Total AI Usage"
            count={`${aiCount}`}
            icon={<IconActive width={60} height={50} />}
          />
        </Grid>
      )}

      {reapitCount !== null && reapitCount !== 0 && (
        <Grid md={2} sm={12} item>
          <CommonCard
            title="Total Negotiator Usage"
            count={`${reapitCount}`}
            icon={<IconActive width={60} height={50} />}
          />
        </Grid>
      )}

      {whatsAppTotalCount !== null && whatsAppTotalCount !== 0 && (
        <Grid md={2} sm={12} item>
          <CommonCard
            title="Total WhatsApp Usage"
            count={`${whatsAppTotalCount}`}
            icon={<IconActive width={60} height={50} />}
          />
        </Grid>
      )}

      {eventBookCount !== null && eventBookCount !== 0 && (
        <Grid md={2} sm={12} item>
          <CommonCard
            title="Total Qualified Event"
            count={`${eventBookCount}`}
            icon={
              loading ? (
                <LoadingCircle size={50} />
              ) : (
                <IconDownload width={60} height={50} onClick={downloadExport} />
              )
            }
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TotalTokenCusume;
