import fetchAPI from '../fetchAPI';

interface callGhlBookingEventProps {
  startDate: string | null;
  endDate: string | null;
  type: string;
  locationId?: string;
}

export const callLeadEvent = async ({
  type,
  locationId,
  startDate,
  endDate
}: callGhlBookingEventProps): Promise<iResponse<{ message?: string; count?: number }>> => {
  const query = new URLSearchParams();

  if (locationId) query.append('locationId', locationId);
  if (endDate) query.append('endDate', endDate);
  if (startDate) query.append('startDate', startDate);

  const response: iResponse<{ message?: string; count?: number }> = await fetchAPI({
    method: `GET`,
    route: `lead-event/${type}`,
    queryString: query.toString()
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callLeadEvent;
