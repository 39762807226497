import fetchAPI from '../fetchAPI';

interface callGetMessagesProps {
  from: string;
  to: string;
}

export const callGetMessages = async ({
  from,
  to
}: callGetMessagesProps): Promise<iResponse<TotalMessages>> => {
  const response: iResponse<TotalMessages> = await fetchAPI({
    method: `GET`,
    route: `crm/messages`,
    queryString: `from=${from}&to=${to}`
  });

  return response;
};

export default callGetMessages;
