import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callGetCoreUsers } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Alert, AlertPopUp, Button, Text, TextField, TextView } from '@atoms';
import { Grid, Stack } from '@mui/material';
import AddOffice from './addOffices';

const CoreOffices: React.FC<{ locationId: string }> = ({ locationId }) => {
  const {
    state: {
      coreUsersPagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);
  const [openOfficeAddress, setOfficeAddress] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<Pagination<CoreUser[]>>();
  //const [search, setSearch] = useState<string>();

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetCoreUsers({
      locationId,
      pageNumber: page,
      pageSize: perPage,
      sortBy: orderBy
    });
    if (code == 200) {
      setData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    api();
  }, [page, perPage, locationId, orderBy]);

  const rowData: TableRow[] = data ? generateRows(data.data) : [];

  return (
    <Box>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'space-between' }}>
        <TextView display={'initial'} $fontSize={22}>
          Office
        </TextView>
        <Button variant="contained" onClick={() => setOfficeAddress(true)}>
          <Text>Add Offices</Text>
        </Button>
      </Box>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert message={error} severity={'error'} />
        </Box>
      )}

      <Table name="core_users" columns={columns} data={[]} isLoading={loading} pagination={data} />
      <AddOffice
        open={openOfficeAddress}
        onClose={function (): void {
          setOfficeAddress(false);
        }}
        onSuccess={function (): void {
          setOfficeAddress(false);
        }}
      />
    </Box>
  );
};

export default CoreOffices;
