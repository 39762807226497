import React from 'react';
import { Grid } from '@mui/material';

import { CommonCard } from '@organisms';

import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconLicensed } from '@assets/svg/licensed.svg';

interface CardListProps {
  location?: CoreLocation;
}

const CardList: React.FC<CardListProps> = ({ location }: CardListProps) => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item sm={12} md={6}>
        <CommonCard
          title="Total Users"
          count={`${location?.totalUsers ?? 0}`}
          icon={<IconTeam />}
          bgColor="#CFDBD5"
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <CommonCard
          title="Total Contacts"
          count={`${location?.totalContacts ?? 0}`}
          icon={<IconLicensed />}
          bgColor="#086BDE"
          fontColor="#ffffff"
        />
      </Grid>
    </Grid>
  );
};

export default CardList;
