import fetchAPI from '../fetchAPI';

export const callValidateGhlCode = async (
  locationId: string
): Promise<iResponse<{ data: { customValues: CustomValues[], code: number } }>> => {
  const response: iResponse<{ data: { customValues: CustomValues[], code: number } }> = await fetchAPI({
    method: `GET`,
    route: `core/custom-values`,
    queryString: `locationId=${locationId}`
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callValidateGhlCode;
