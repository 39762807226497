import fetchAPI from '../fetchAPI';

interface callGetCompanyEngagementProps {
  from: string;
  to: string;
}

export const callGetCompanyEngagement = async ({
  from,
  to
}: callGetCompanyEngagementProps): Promise<iResponse<Engagement[]>> => {
  const response: iResponse<Engagement[]> = await fetchAPI({
    method: `GET`,
    route: `crm/messages/company-engagement`,
    queryString: `from=${from}&to=${to}`
  });

  return response;
};

export default callGetCompanyEngagement;
