import * as React from 'react';
import { Stack, Box, Theme, SxProps } from '@mui/material';
import { TextView } from '@atoms';

interface CardProps {
  title: string;
  subtitle?: string;
  bgColor?: string;
  fontColor?: string;
  count?: string;
  icon?: JSX.Element;
}

const CardPayment = (props: CardProps) => {
  const { title, subtitle, count, icon, bgColor = 'rgba(36, 36, 35, 0.08)' } = props;
  return (
    <Stack direction={'row'} sx={{ backgroundColor: bgColor, borderRadius: 1 }}>
      <Box flex="1" ml={2} justifyContent={'center'} display={'flex'} flexDirection={'column'}>
        <TextView $fontWeight={400}>{title}</TextView>
        {count !== undefined && <TextView $fontSize={60}>{count}</TextView>}
        <TextView $fontWeight={400}>{subtitle}</TextView>
      </Box>
      {icon}
    </Stack>
  );
};

export default CardPayment;
