import { routes } from '@particles';

const generateRows = (tokens: CoreLocation[]): TableRow[] =>
  tokens.map((token) => {
    const payload: TableRow = {
      locationId: token.locationId ?? '',
      companyName: token.companyName ?? '',
      status: token.status ?? '',
      totalContacts: token.totalContacts,
      totalUsers: token.totalUsers,
      whatsAppUsageCount: token.whatsAppUsageCount,
      leadEventsCount: token.leadEventsCount,
      totalSmsCount: token.totalSmsCount,
      totalCallCount: token.totalCallCount,
      linkData: {
        to: `${routes.SA_Core}/${token.locationId}`
      }
    };

    return payload;
  });

export default generateRows;
