import React, { useReducer } from 'react';

import ApplicationContext from './applicationContext';
import applicationDefaultState from './applicationDefaultState';
import applicationReducer from './applicationReducer';

interface ApplicationStateProps {
  /** The actual application component */
  children: React.ReactNode;
}

export const ApplicationState: React.FC<ApplicationStateProps> = ({
  children
}: ApplicationStateProps) => {
  const localState: User = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user') ?? '')
    : undefined;

  const [state, dispatch] = useReducer(
    applicationReducer,
    localState ? { user: localState } : applicationDefaultState
  );

  return (
    <ApplicationContext.Provider
      value={{
        dispatch,
        state
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
export default ApplicationState;
