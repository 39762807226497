import { routes } from '@particles';
import { DateTime } from 'luxon';

const generateRows = (tokens: SrtTransactionsLogs[]): TableRow[] =>
  tokens.map((token) => {
    const availableToken: TableCellText = {
      data: {
        text: token.availableToken,
        fontColor: Number(token.availableToken) < 0 ? '#D32F33' : 'rgba(0, 0, 0, 0.87)'
      },
      type: 'text'
    };
    const payload: TableRow = {
      companyName: token.companyName ?? '',
      allocatedToken: token.allocatedToken ? token.allocatedToken : 0,
      availableToken,
      consumeToken: token.consumeToken ? token.consumeToken : 0,
      billing: `${token.startDate ? DateTime.fromISO(token.startDate).toFormat('dd LLL') : ''} - ${
        token.endDate ? DateTime.fromISO(token.endDate).toFormat('dd LLL') : ''
      }`,
      endDate: token.endDate ? DateTime.fromISO(token.endDate).toFormat('dd LLL, yyyy') : 0,
      linkData: {
        to: `${routes.SA_BillingTokens}/${token.companyTokenId}${
          token.endDate ? '?date=' + DateTime.fromISO(token.endDate).toFormat('yyyy-LL-dd') : ''
        }`
      }
    };

    return payload;
  });

export default generateRows;
