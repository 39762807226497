import React from 'react';

import { InputBase, InputBaseSingleProps } from '..';

import StyledTextField from './textField.style';
import TextView from '../textView';
export interface TextFieldProps extends InputBaseSingleProps {
  /** Text field label */
  label: string;
  /** The vertical size of the text field */
  size?: `small` | `medium`;
  /** If true, the label is shrunk and displayed above the input by default */
  shrink?: boolean;
  errorMessage?: string;
  /** Text field label */
  labelSize?: number;
  /** Text field label */
  labelWeight?: number;
}

export const TextField: React.FC<TextFieldProps> = ({
  shrink,
  label,
  errorMessage,
  size = `small`,
  fullWidth,
  onChange,
  labelSize = 14,
  labelWeight = 400,
  ...props
}: TextFieldProps) => (
  <StyledTextField sx={{ mt: 1.5, flex: fullWidth ? 1 : 0 }}>
    <TextView $fontSize={labelSize} $fontWeight={labelWeight} mb={1}>
      {label}
    </TextView>
    <InputBase $shrink={shrink} size={size} onChange={onChange} fullWidth={fullWidth} {...props} />

    {errorMessage && (
      <TextView
        $fontSize={10}
        $fontWeight={400}
        $fontColor={'red'}
        {...props}
      >
        {errorMessage}
      </TextView>
    )}
  </StyledTextField>
);

export default TextField;
