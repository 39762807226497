import fetchAPI from '../fetchAPI';

export const callCreateCompanyToken = async (data: any): Promise<iResponse<CompanyToken>> => {
  const response: iResponse<CompanyToken> = await fetchAPI({
    method: `POST`,
    route: `company-token`,
    data
  });

  return response;
};

export default callCreateCompanyToken;
