import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import {
  Alert,
  Grid,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, TextView } from '@atoms';
import { callGetPromptValues, callUpdateCustomValues } from '@api';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import VersionModal from './versionpopup';
import { useMediaQuery } from '@mui/material';
import { SelectBillingAccounts } from '@organisms';
interface BasicModalProps {
  initialValues: CustomValues | undefined;
  status: string;
  onClose: () => void;
  handleNextindex: () => void;
  handlePreview: () => void;
}

const BasicModal: React.FC<BasicModalProps> = ({
  initialValues,
  status,
  onClose,
  handleNextindex,
  handlePreview
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [openPublish, setOpenPublish] = useState<boolean>(false);
  const [versionValues, setVersionValues] = useState<PromptValues[]>();
  const isSmallScreen = useMediaQuery('(max-width:1200px)');
  const isSmallS = useMediaQuery('(max-width:599px)');
  const [selectedVersion, setSelectedVersion] = useState<PromptValues>();
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [dialogVersion, setDialogVersion] = useState<boolean>(false);
  const [dialogShow, setDialogShow] = useState<{ title: string; message: string } | undefined>(
    undefined
  );

  const [values, setValues] = useState({
    value:
      selectedVersion?.customValueId == initialValues?.id
        ? selectedVersion?.value
        : initialValues?.value,
    VersionName: undefined
  });

  useEffect(() => {
    if (versionValues) {
      // Sort the versionValues and select the first item
      const sortedVersions = [...versionValues].sort((a, b) => b.version - a.version);
      setSelectedVersion(sortedVersions[0]);
    }
  }, [versionValues]);

  useEffect(() => {
    setValues({
      value:
        selectedVersion?.customValueId == initialValues?.id
          ? selectedVersion?.value
          : initialValues?.value,
      VersionName: undefined
    });
  }, [initialValues?.id, handleNextindex, handlePreview, selectedVersion]);

  useEffect(() => {
    if (initialValues?.locationId && initialValues?.id) {
      callGetPromptValues(initialValues?.locationId, initialValues?.id).then((res) => {
        if (res.code === 200) {
          if (res?.data?.data) {
            const item = res?.data?.data;

            setVersionValues(item);
          } else {
            console.log(res);
          }
        } else {
          console.error('Error occurred:', res);
        }
      });
    }
  }, [initialValues]);

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;

      if (value !== values?.value) {
        setValues((prevValues) => ({
          ...prevValues,
          [field]: value // Update only the specific field
        }));
      }
    };

  const handleNext = () => {
    if (!initialValues || !values.value) {
      onClose(); // Close the modal if either initialText or values.value is empty
      return;
    }

    handleNextindex();
  };
  const handleUpdate = () => {
    if (values?.value && values?.value !== initialValues?.value) {
      if (selectedAccounts.length > 1) {
        setDialogVersion(true);
        return;
      }
      setOpenPublish(true);
    } else {
      setDialogShow({
        title: 'Suggestion',
        message: 'Please update your new value, as it is the same as the current version.'
      });
    }
  };

  const apiUpdateCV = (vName: string) => {
    const data = {
      locationId: selectedAccounts,
      versionName: vName || '',
      customValueId: initialValues?.id,
      name: initialValues?.name,
      value: values?.value,
      defaultValue: initialValues?.value ? initialValues?.value : ''
    };

    callUpdateCustomValues(data).then((res) => {
      if (res.code == 200) {
        setLoading(false);
        onClose();
      } else {
        setLoading(true);
      }
    });
  };

  if (initialValues === undefined) {
    return null;
  }
  const handleAccountClick = (item: any) => {
    setSelectedVersion(item);
  };

  return (
    <Modal
      open={initialValues !== undefined}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style} overflow={'auto'}>
        <Grid container direction="column">
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1
            }}>
            <TextView $fontSize={26} $fontWeight={700}>
              Update - {initialValues?.name}
            </TextView>
            <Stack direction="row" spacing={2}>
              <TextView $fontWeight={600}  $fontSize={25}>
                Locations
              </TextView>

              <SelectBillingAccounts
                status={status}
                selectedAccount={initialValues.locationId}
                onSelectedAccounts={function (list: string[]): void {
                  setSelectedAccounts(list);
                }}
              />

              <Button size="small" variant="outlined" sx={{ width: 120 }} onClick={handlePreview}>
                <ArrowBackIosIcon />
                Previous
              </Button>
              <Button size="small" variant="outlined" sx={{ width: 120 }} onClick={handleNext}>
                Next <ArrowForwardIosIcon />
              </Button>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Grid>
          <Divider />
          {loading && <LinearProgress />}
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item lg={2} sm={12} xs={2}>
            <TextView
              $fontWeight={600}
              style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              Recent Versions
            </TextView>
            <Box
              sx={{
                width: isSmallScreen ? '100%' : 'auto',
                height: isSmallScreen ? (isSmallS ? '90%' : 'auto') : '90%',
                maxHeight: '400px',
                background: '#f1f1f1',
                border: '1px solid #ccc',
                borderRadius: 2,
                boxSizing: 'border-box',
                marginTop: 1,
                overflowX: 'auto'
              }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: isSmallScreen ? (isSmallS ? 'column' : 'row') : 'column',
                  alignItems: 'center',
                  padding: '10px'
                }}>
                <TextView>In Use</TextView>
                {versionValues?.length ? (
                  versionValues
                    .sort((a, b) => b.version - a.version)
                    .map((item, index) => (
                      <ListItem
                        key={item.versionName}
                        component="div"
                        selected={selectedVersion === item}
                        className={selectedVersion === item ? 'selected' : ''}
                        style={{
                          marginTop: '8px',
                          backgroundColor: selectedVersion === item ? '#086BDE' : 'inherit',
                          color: selectedVersion === item ? 'white' : 'inherit',
                          borderRadius: selectedVersion === item ? '10px' : '0px',
                          height: '40px'
                        }}
                        onClick={() => handleAccountClick(item)}>
                        <ListItemText
                          primary={item.versionName}
                          primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            textAlign: 'center'
                          }}
                        />
                      </ListItem>
                    ))
                ) : (
                  <ListItemText secondary={<TextView>Initial version</TextView>} />
                )}
              </Box>
            </Box>
          </Grid>
          {/* Left side content */}
          <Grid item lg={5} sm={12} xs={5}>
            <TextView $fontWeight={600}>Current Custom Values</TextView>
            <textarea
              id="outlined-basic-value"
              name="value"
              value={
                selectedVersion?.customValueId == initialValues?.id
                  ? selectedVersion?.value
                  : initialValues?.value
              }
              disabled
              onChange={handleChange('value')}
              style={{
                width: '100%',
                maxWidth: '100%',
                height: '400px',
                maxHeight: '400px',
                resize: 'none',
                padding: '8px',
                marginTop: '8px',
                marginBottom: '6px',
                border: '1px solid #ccc',
                borderRadius: '6px',
                boxSizing: 'border-box'
              }}
            />
          </Grid>
          {/* Right side content */}
          <Grid item lg={5} sm={12} xs={5}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextView $fontWeight={600}>Edit Custom Values</TextView>

              <textarea
                id="outlined-basic-value"
                name="value"
                value={values.value}
                onChange={handleChange('value')}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  height: '400px',
                  maxHeight: '400px',
                  resize: 'none',
                  padding: '8px',
                  marginTop: '8px',
                  marginBottom: '6px',
                  border: '1px solid #ccc',
                  borderRadius: '6px',
                  boxSizing: 'border-box'
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ textAlign: 'end' }}>
          <Button onClick={handleUpdate}>Save</Button>
        </Box>
        {/* Update button */}
        <VersionModal
          open={openPublish}
          handleClose={() => setOpenPublish(false)}
          handlepublished={(versionName) => {
            apiUpdateCV(versionName);
          }}
        />
        <Dialog
          open={dialogShow != undefined}
          message={dialogShow?.message}
          title={dialogShow?.title}
          positiveTitle="Ok"
          negitaiveTitle="Cancel"
          onSuccess={() => {
            setDialogShow(undefined);
          }}
          onClose={() => setDialogShow(undefined)}
        />
        <Dialog
          open={dialogVersion}
          message={
            'You are updating this custom value for multiple locations. Please verify the values before proceeding with the update.'
          }
          title={'Attention'}
          positiveTitle="Update"
          negitaiveTitle="Cancel"
          onSuccess={() => {
            setDialogVersion(false);
            setOpenPublish(true);
          }}
          onClose={() => setDialogVersion(false)}
        />
      </Box>
    </Modal>
  );
};

const style = {
  height: '90%',
  width: '90%',
  marginTop: '2%',
  marginLeft: '4%',
  bgcolor: 'background.paper',
  border: '1px solid #000',

  boxShadow: 24,
  p: 2
};

export default BasicModal;
