import { tableDefaults } from '../../common';

const defaultPagination = {
  page: 1,
  perPage: tableDefaults.perPage,
  orderBy: tableDefaults.orderBy
};

const default5Pagination = {
  page: 1,
  perPage: 5,
  orderBy: tableDefaults.orderBy
};

export const defaultState: TableState = {
  coreUsersPagination: default5Pagination,
  teamPagination: defaultPagination,
  allAccountPagination: defaultPagination,
  billingPagination: defaultPagination,
  accountPerformancePagination: defaultPagination,
  companyTokenPagination: defaultPagination,
  openAiPagination: defaultPagination,
  twilioPagination: defaultPagination,
  srtPagination: defaultPagination,
  companyTransactionsLogsPagination: defaultPagination,
  apiKeys: defaultPagination
};

export default defaultState;
