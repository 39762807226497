import React, { useEffect, useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { DateTime } from 'luxon';
import { CommonCard } from './particles';
import { Grid, Box, LinearProgress } from '@mui/material';
import { ApplicationContext } from '@particles';
import { callGetEngagedPeople, callGetTotalUsers, callGetMessages } from '@api';
import { EngagementOverTime, DashboardHeader, PieChart, EngagementByAgent } from '@organisms';

const Dashboard: React.FC<RouteComponentProps> = () => {
  const { state } = useContext(ApplicationContext);

  const { user } = state;

  const [start, setStart] = useState<Date>(DateTime.local().minus({ days: 6 }).toJSDate());
  const [end, setEnd] = useState<Date>(new Date());

  const [loading, setLoading] = useState<boolean>(false);
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [sentReceived, setSentReceived] = useState<SentReceived>({ sent: 0, received: 0 });
  const [activeInactive, setActiveInactive] = useState<ActiveInactive>({ active: 0, inactive: 0 });

  useEffect(() => {
    apiCalling();
  }, [start, end, user]);

  const apiCalling = async () => {
    if (start && end && user) {
      setLoading(true);
      const startDate = DateTime.fromJSDate(start).toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });
      const endDate = DateTime.fromJSDate(end).toISO({
        suppressMilliseconds: true,
        includeOffset: false
      });

      if (startDate && endDate) {
        const resEngagedPeople = await callGetEngagedPeople({ from: startDate, to: endDate });
        if (resEngagedPeople.code === 200 && resEngagedPeople.data) {
          const company = resEngagedPeople.data.data.find((item) => item.id === user?.companyId);
          setTotalContacts(company ? company.contacts : 0);
        }
        const resMessages = await callGetMessages({ from: startDate, to: endDate });
        if (resMessages.code === 200 && resMessages.data) {
          const data = resMessages.data.data;
          setSentReceived(data);
        }
      }

      const resTotalUsers = await callGetTotalUsers({ companyId: user?.companyId });
      if (resTotalUsers.code === 200 && resTotalUsers.data) {
        const total = resTotalUsers.data.data.total;
        setActiveInactive(total);
      }

      setLoading(false);
    }
  };

  return (
    <Box sx={{ m: 2 }}>
      {loading && <LinearProgress />}
      <Box sx={{ mt: 1, mb: 1 }}>
        <DashboardHeader
          title={'Dashboard'}
          start={start}
          end={end}
          onSelectRange={(start, end) => {
            setStart(start);
            setEnd(end);
          }}
        />
      </Box>

      <Box mt={1}>
        <Grid container columnSpacing={2}>
          <Grid md={4} sm={12} item>
            <CommonCard
              totalContacts={totalContacts}
              totalMessages={sentReceived.sent + sentReceived.received}
              activeInactive={activeInactive}
            />
          </Grid>
          <Grid md={4} sm={12} item>
            <PieChart
              title={'Total Messages'}
              title1={'Received'}
              title2={'Sent'}
              value1={sentReceived.received}
              value2={sentReceived.sent}
            />
          </Grid>
          <Grid md={4} sm={12} item>
            <PieChart
              title={'Total Licences'}
              title1={'Active'}
              title2={'Inactive'}
              value1={activeInactive.active}
              value2={activeInactive.inactive}
            />
          </Grid>
          {/* <Grid item md={3} sm={12}>
            <CreatedSalesRock />
          </Grid> */}
        </Grid>

        <Box mt={2}>
          <EngagementOverTime start={start} end={end} companyId={user?.companyId} />
        </Box>
        <Box mt={2}>
          <EngagementByAgent start={start} end={end} />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
