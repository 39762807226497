import * as React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import { Button, TextView } from '@atoms';
import { Grid, TextField } from '@mui/material';

interface VersionModalProps {
  open: boolean;
  handlepublished: (arg: string) => void;
  handleClose: () => void;
}

const VersionModal: React.FC<VersionModalProps> = ({ open, handlepublished, handleClose }) => {
  const [versionName, setVersionName] = React.useState<string>('');

  const handleChange = (feild: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setVersionName(value);
  };
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid item container mb={3}>
            <Grid xs={12} display={'flex'} justifyContent={'center'}>
              <TextView $fontSize={22} $fontWeight={700}>
                Publish new version
              </TextView>
            </Grid>
            <Grid xs={12} display={'flex'} justifyContent={'center'}>
              <TextView $fontWeight={300}>You can edit the version name at any time</TextView>
            </Grid>
          </Grid>

          <Grid item container justifyContent={'center'}>
            <Grid item md={10} sm={8} xs={8} lg={12}>
              <Box sx={{ width: '100%' }}>
                <TextField
                  name="VersionName"
                  label="Version Name"
                  fullWidth
                  type="text"
                  onChange={handleChange('VersionName')}
                  placeholder="Version Name"
                />{' '}
              </Box>
            </Grid>
            <Grid item container mt={2} spacing={2} justifyContent={'center'}>
              <Grid item>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    handlepublished(versionName);
                    handleClose();
                  }}>
                  Publish
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};
export default VersionModal;
