import fetchAPI from '../../fetchAPI';

export const callGetPromptValues = async (
  locationId: string,
  customValueId: string
): Promise<iResponse<{ data: PromptValues[] }>> => {
  const response: iResponse<{ data: PromptValues[] }> = await fetchAPI({
    method: `GET`,
    route: `prompt`,
    queryString: `locationId=${locationId}&customValueId=${customValueId}`
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetPromptValues;
