import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Grid, Box, Stack, LinearProgress } from '@mui/material';
import LocationDetails from './accountDetails';
import { callGetCoreDetailedList } from '@api';
import { da } from 'date-fns/locale';
import CardList from './commonCard';
import CoreUsers from './users';
import CoreContacts from './contacts';
import { Alert, Button, LoadingCircle, Text } from '@atoms';
import CoreOffices from './offices';

interface CoreDetailsProps extends RouteComponentProps {
  locationId?: string;
}

const CoreDetails: React.FC<CoreDetailsProps> = (props: CoreDetailsProps) => {
  useEffect(() => {
    api();
  }, [props.locationId]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [companyTokens, setCompanyToken] = useState<CoreLocation>();

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetCoreDetailedList({
      locationId: props.locationId
    });
    if (data && data.data.length > 0 && code == 200) {
      setCompanyToken(data.data[0]);
    }
    setLoading(false);
  };

  if (props.locationId === undefined) {
    return (
      <Box>
        <Stack>
          <Box sx={{ mb: 1 }}>
            <Alert message={'No location id found'} severity={'error'} />
          </Box>
        </Stack>
      </Box>
    );
  }

  return (
    <Grid p={2} spacing={2} container>
      <Grid xs={12} item>
        <Box justifySelf={'right'}>
          <Button primary={false}>
            <Text $fontColor="white">Sync Information With Core</Text>
          </Button>
        </Box>
      </Grid>

      <Grid xs={12} item>
        {loading && <LinearProgress />}
        <LocationDetails location={companyTokens} />
      </Grid>
      <Grid xs={12} item>
        <CardList location={companyTokens} />
      </Grid>
      <Grid xs={12} item>
        <CoreOffices locationId={props.locationId} />
      </Grid>
      <Grid xs={12} item>
        <CoreUsers locationId={props.locationId} />
      </Grid>
      <Grid xs={12} item>
        <CoreContacts locationId={props.locationId} />
      </Grid>
    </Grid>
  );
};

export default CoreDetails;
