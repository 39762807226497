import React from 'react';

import StyledChip from './chip.style';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { ChipClasses } from '@mui/material/Chip/chipClasses';
import { OverridableStringUnion } from '@mui/types';
import {
  ChipPropsColorOverrides,
  ChipPropsSizeOverrides,
  ChipPropsVariantOverrides
} from '@mui/material/Chip/Chip';

export interface ChipProps {
  /** To assign the id to component? */
  id?: string;
  /** Background color of the chip */
  $backgroundColor?: string;
  /** To size of label with rem 0.0 - 1.0? */
  $labelSize?: number;
  /** Color of the text */
  $textColor?: string;
  /** The size of the font in the avatar component */
  $avatarFontSize?: number;
  /** Color of the avatar background */
  $avatarBackgroundColor?: string;
  /** Color of the background */
  $avatarTextColor?: string;
  /**
   * The Avatar element to display.
   */
  avatar?: React.ReactElement;
  /**
   * This prop isn't supported.
   * Use the `component` prop if you need to change the children structure.
   */
  children?: null;
  /**
   * Override or extend the styles applied to the component.
   */
  classes?: Partial<ChipClasses>;
  /**
   * If `true`, the chip will appear clickable, and will raise when pressed,
   * even if the onClick prop is not defined.
   * If `false`, the chip will not appear clickable, even if onClick prop is defined.
   * This can be used, for example,
   * along with the component prop to indicate an anchor Chip is clickable.
   * Note: this controls the UI and does not affect the onClick event.
   */
  clickable?: boolean;
  /**
   * The color of the component.
   * It supports both default and custom theme colors, which can be added as shown in the
   * [palette customization guide](https://mui.com/customization/palette/#adding-new-colors).
   * @default 'default'
   */
  color?: OverridableStringUnion<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    ChipPropsColorOverrides
  >;
  /**
   * Override the default delete icon element. Shown only if `onDelete` is set.
   */
  deleteIcon?: React.ReactElement;
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled?: boolean;
  /**
   * Icon element.
   */
  icon?: React.ReactElement;
  /**
   * The content of the component.
   */
  label?: React.ReactNode;
  /**
   * Callback fired when the delete icon is clicked.
   * If set, the delete icon will be shown.
   */
  onDelete?: React.EventHandler<any>;
  /**
   * The size of the component.
   * @default 'medium'
   */
  size?: OverridableStringUnion<'small' | 'medium', ChipPropsSizeOverrides>;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx?: SxProps<Theme>;
  /**
   * The variant to use.
   * @default 'filled'
   */
  variant?: OverridableStringUnion<'filled' | 'outlined', ChipPropsVariantOverrides>;
}

export const Chip: React.FC<ChipProps> = ({ ...props }: ChipProps) => <StyledChip {...props} />;

export default Chip;
