import React from 'react';
import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { TextView } from '@atoms';

const CustomTooltip = (props: any) => {
  const { active, payload, label } = props;
  if (active && payload && payload.length) {
    const data = payload[0];

    if (!data || !data.value) {
      return null;
    }

    return (
      <Box sx={{ backgroundColor: 'white', border: '1px solid #ccc', padding: 1 }}>
        <TextView $fontSize={12}>{DateTime.fromISO(label).toFormat('yyyy-LL-dd')}</TextView>
        <TextView $fontColor={'green'} $fontSize={16}>
          {Number(data.value).toFixed(2)}
        </TextView>
        {/* Add more custom content here */}
      </Box>
    );
  }

  return null;
};
export default CustomTooltip;
