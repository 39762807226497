import React from 'react';
import { Paper, Box } from '@mui/material';
import StyledModal from './modal.style';

export interface ModalProps {
  /** If true, the modal is open */
  open: boolean;
  /** Function fired when the component requests to be closed */
  onClose: (event: ObjectLiteral, reason: `backdropClick` | `escapeKeyDown`) => void;
  /** The contents of the modal */
  children: React.ReactElement;
}

export const Modal: React.FC<ModalProps> = ({ children, open, ...props }: ModalProps) => (
  <StyledModal open={open} {...props}>
    <Paper>
      <Box p={4}>{children}</Box>
    </Paper>
  </StyledModal>
);

export default Modal;
