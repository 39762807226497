import styled from '@emotion/styled';
import Box from '@mui/material/Box';

const StyledAccount = styled(Box)`
  .account-profile-background {
    width: 100%;
  }
`;

export default StyledAccount;
