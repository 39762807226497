import React, { useReducer } from 'react';

import TableContext from './tableContext';
import tableDefaultState from './tableDefaultState';
import tableReducer from './tableReducer';

interface TableStateProps {
  /** The actual table component */
  children: React.ReactNode;
}

export const TableState: React.FC<TableStateProps> = ({ children }: TableStateProps) => {
  const [state, dispatch] = useReducer(tableReducer, tableDefaultState);

  return (
    <TableContext.Provider
      value={{
        dispatch,
        state
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
export default TableState;
