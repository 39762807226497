import { createContext, Dispatch } from 'react';

type TableContextType = {
  dispatch: Dispatch<TableReducerAction>;
  state: TableState;
};

export const TableContext = createContext<TableContextType>({} as TableContextType);

export default TableContext;
