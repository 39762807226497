const columns: TableColumn[] = [
  {
    Header: `Key Title`,
    accessor: `name`,
    dbKey: `name`,
    isString: false,
    isNumeric: false,
    isDate: true,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Created At`,
    accessor: `createdAt`,
    dbKey: `createdAt`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Action`,
    accessor: `action`,
    dbKey: `action`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
