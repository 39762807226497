import styled from '@emotion/styled';

import { materialTheme } from '../../particles/theme';

const StyledAdmin = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: ${materialTheme.palette.background.default};

  .sidebar {
    min-height: 100vh;
    margin-right: 10px;
  }

  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .header {
    margin-bottom: 10px;
    background-color: ${materialTheme.palette.background.paper};
  }

  .main {
    flex: 1;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: ${materialTheme.palette.background.paper};
  }
`;

export default StyledAdmin;
