import React, { ReactNode } from 'react';

import StyledCard from './card.styles';
import { SxProps, Theme } from '@mui/material';

export interface CardProps {
  /** If `true`, the card will use raised styling. */
  raised?: boolean;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx?: SxProps<Theme>;
  children?: ReactNode;
}

export const Card: React.FC<CardProps> = ({ ...props }: CardProps) => <StyledCard {...props} />;

export default Card;
