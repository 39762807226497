import fetchAPI from '../fetchAPI';

export const callUpdateAccount = async (id: string, data: any): Promise<iResponse<Agent[]>> => {
  const response: iResponse<Agent[]> = await fetchAPI({
    method: `PATCH`,
    route: `agent/${id}/account`,
    data
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callUpdateAccount;
