import { createTheme } from '@mui/material/styles';

const materialTheme = createTheme({
  components: {},
  transitions: {},
  zIndex: {},
  palette: {
    primary: {
      main: `#f0cf69`,
      light: `#FFFFFF`,
      dark: `#dbbe62`,
      contrastText: `#333333`,
    },
    secondary: {
      main: `#3482cf`,
      light: `#FFFFFF`,
      dark: `#877adb`,
      contrastText: `#333333`,
    },
    text: {
      disabled: `#7C7C7C`,
      secondary: `#333333`,
      primary: `#1f1f1f`,
    }
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontSize: 48,
      fontWeight: 700
    },  
    fontSize: 16,
    button: {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Montserrat',
    },
  }






});

export default materialTheme;
