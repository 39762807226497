import React from 'react';
import { Box, Divider } from '@mui/material';

import { ReactComponent as IconPaidDate } from '@assets/svg/paid-date.svg';
import { Avatar, TextView } from '@atoms';
import { DateTime } from 'luxon';

interface LocationDetailsProps {
  location?: CoreLocation;
}

const LocationDetails: React.FC<LocationDetailsProps> = ({ location }: LocationDetailsProps) => {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: '#E8EDDF',
        p: 4,
        boxShadow:
          '0px 7px 140px rgba(141, 152, 121, 0.1), 0px 2px 32px rgba(141, 152, 121, 0.05), 0px 1px 10px rgba(141, 152, 121, 0.04)',
        borderRadius: '6px'
      }}>
      <Box sx={{ alignItems: 'center', width: '33%', display: 'flex', flexDirection: 'column' }}>
        <Avatar $size="md" />
        <TextView $fontSize={24}>{location?.companyName}</TextView>
        <TextView $fontWeight={400}>{'No email'}</TextView>
        <TextView $fontWeight={400}>{'No phone'}</TextView>
      </Box>
      <Divider orientation={'vertical'} flexItem />
      <Box sx={{ pl: 4 }}>
        <Box sx={{ flexDirection: 'row', display: 'flex', p: 1, justifyContent: 'center' }}>
          <IconPaidDate />
          <Box sx={{ flexDirection: 'column', display: 'flex', ml: 1, justifyContent: 'center' }}>
            <TextView $fontWeight={700}>{'Current Subcription Period'}</TextView>
            <TextView $fontWeight={400} sx={{mt: 1}}>
              {location?.subscriptionDate
                ? DateTime.fromISO(location?.subscriptionDate).toFormat('dd LLLL yyyy')
                : '-'}
              {' - '}
              {location?.nextSubscriptionDate
                ? DateTime.fromISO(location?.nextSubscriptionDate).toFormat('dd LLLL yyyy')
                : '-'}
            </TextView>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LocationDetails;
