import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callGetCoreUsers } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Alert, AlertPopUp, TextField, TextView } from '@atoms';

const CoreUsers: React.FC<{ locationId: string }> = ({ locationId }) => {
  const {
    state: {
      coreUsersPagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState<Pagination<CoreUser[]>>();
  //const [search, setSearch] = useState<string>();

  const api = async () => {
    setLoading(true);
    const { data, code } = await callGetCoreUsers({
      locationId,
      pageNumber: page,
      pageSize: perPage,
      sortBy: orderBy
    });
    if (code == 200) {
      setData(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    api();
  }, [page, perPage, locationId, orderBy]);

  const rowData: TableRow[] = data ? generateRows(data.data) : [];

  return (
    <Box>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mb: 1 }}>
        <TextView display={'initial'} $fontSize={22}>
          Users
        </TextView>
        {/* <TextField
          label={'Search Name'}
          name={'Search Name'}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        /> */}
      </Box>
      {error && (
        <Box sx={{ mb: 1 }}>
          <Alert message={error} severity={'error'} />
        </Box>
      )}

      <Table
        name="core_users"
        columns={columns}
        data={rowData}
        isLoading={loading}
        pagination={data}
      />
    </Box>
  );
};

export default CoreUsers;
