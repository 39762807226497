import fetchAPI from '../fetchAPI';

// Define a function callUserConnectGhl that makes an asynchronous API call
export const callUserConnectGhl = async (): Promise<
  iResponse<{ message: string;}>
> => {
  try {
    // Make an API call using fetchAPI
    const response: iResponse<{ message: string }> =
      await fetchAPI({
        method: 'DELETE',
        route: `core`,
      });

    // Return the response
    return response;
  } catch (error) {
    // Handle errors if the API call fails
    console.error('Error fetching GhlConnect:', error);
    throw error; // Throw the error to be handled by the caller
  }
};

export default callUserConnectGhl;
