import { SxProps, Theme } from '@mui/material';
import React from 'react';

import StyledAvatar from './avatar.styles';

export interface AvatarProps {
  /** The shape of the avatar */
  variant?: `circular` | `rounded` | `square`;
  /** The src attribute for the img element */
  src?: string;
  /** Used to render icon or text elements inside the Avatar if src is not set */
  children?: React.ReactNode;
  /** The size of the avatar */
  $size?: Sizes;
  /** The size of the font */
  $fontSize?: number;
  /** Color of the background */
  $backgroundColor?: string;
  /** Color of the background, based on response codes. Will override $backgroundColor */
  $responseCode?: number;
  /** The system prop that allows defining system overrides as well as additional CSS styles. */
  sx?: SxProps<Theme>;
}

export const Avatar: React.FC<AvatarProps> = ({
  $size = `md`,
  variant = `circular`,
  ...props
}: AvatarProps) => <StyledAvatar alt="Avatar" variant={variant} $size={$size} {...props} />;

export default Avatar;
