import React, { useState, useEffect } from 'react';
import { Box, Modal, IconButton, Paper, Grid, Stack, LinearProgress } from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import Validator from 'validator';
import { TextView, Button, TextField, Alert } from '../../../../atoms';
import { DateTime } from 'luxon';
import { callCreateLicense, callDeleteBillingLicense, callUpdateLicense } from '@api';

interface ReviseLicenceProps {
  open: boolean;
  companyId: string;
  licensed: number;
  active: number;
  license?: License;
  selectedLicenseHistory?: LicenseHistory;
  onClose: () => void;
  onSuccess: () => void;
}

const ReviseLicenceModel: React.FC<ReviseLicenceProps> = ({
  onClose,
  open,
  active,
  licensed,
  license,
  companyId,
  selectedLicenseHistory,
  onSuccess
}: ReviseLicenceProps) => {
  const endDate = license?.endDate;

  useEffect(() => {
    if (open) {
      if (selectedLicenseHistory) {
        setValues({
          payment: selectedLicenseHistory.amount.toString(),
          revisedUsersLimit: selectedLicenseHistory?.users.toString(),
          remarks: selectedLicenseHistory?.remarks,
          paymentDate: DateTime.fromISO(selectedLicenseHistory.paymentDate).toFormat('yyyy-LL-dd'),
          licenceStart: DateTime.fromISO(selectedLicenseHistory.startDate).toFormat('yyyy-LL-dd'),
          licenceEnd: DateTime.fromISO(selectedLicenseHistory.endDate).toFormat('yyyy-LL-dd')
        });
      } else {
        setValues({
          payment: '',
          revisedUsersLimit: '',
          remarks: '',
          paymentDate: '',
          licenceStart: '',
          licenceEnd: ''
        });
      }

      setErrors({
        payment: '',
        paymentDate: '',
        licenceStart: '',
        licenceEnd: '',
        revisedUsersLimit: '',
        remarks: ''
      });
    }
  }, [open]);

  const [loading, setLoading] = useState<boolean>(false);

  const [values, setValues] = React.useState({
    payment: '',
    paymentDate: '',
    licenceStart: '',
    licenceEnd: '',
    revisedUsersLimit: '',
    remarks: ''
  });

  const [errors, setErrors] = useState({
    payment: '',
    paymentDate: '',
    licenceStart: '',
    licenceEnd: '',
    revisedUsersLimit: '',
    remarks: ''
  });

  const [error, setError] = useState<{
    title: string;
    message: string;
  }>();

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const validateFields = () => {
    let error = false;

    setErrors({
      payment: '',
      paymentDate: '',
      licenceStart: '',
      licenceEnd: '',
      revisedUsersLimit: '',
      remarks: ''
    });

    if (Validator.isEmpty(values.payment) || Number(values.payment) <= 0) {
      error = true;
      setErrors((state) => ({ ...state, payment: 'Payment is required' }));
    }
    if (Validator.isEmpty(values.paymentDate)) {
      error = true;
      setErrors((state) => ({ ...state, paymentDate: 'Payment Date is required' }));
    }
    if (Validator.isEmpty(values.licenceStart)) {
      error = true;
      setErrors((state) => ({ ...state, licenceStart: 'License start is required' }));
    }

    if (Validator.isEmpty(values.licenceEnd)) {
      error = true;
      setErrors((state) => ({ ...state, licenceEnd: 'License end is required' }));
    }

    if (Validator.isEmpty(values.revisedUsersLimit) || Number(values.revisedUsersLimit) <= 0) {
      error = true;
      setErrors((state) => ({ ...state, revisedUsersLimit: 'Revised users limit is required' }));
    }

    if (Validator.isEmpty(values.remarks)) {
      error = true;
      setErrors((state) => ({ ...state, remarks: 'Remarks is required' }));
    }

    return error;
  };

  const onSubmit = async () => {
    const { payment, paymentDate, licenceStart, licenceEnd, revisedUsersLimit, remarks } = values;

    if (validateFields()) {
      return;
    }

    const _paymentDate = DateTime.fromFormat(paymentDate, 'yyyy-LL-dd').toISO();
    const startDate = DateTime.fromFormat(licenceStart, 'yyyy-LL-dd').toISO();
    const endDate = DateTime.fromFormat(licenceEnd, 'yyyy-LL-dd').toISO();

    if (_paymentDate && startDate && endDate) {
      const payload: {
        companyId: string;
        amount: number;
        paymentDate: string; //'2022-06-13T19:19:28.873Z';
        users: number;
        startDate: string;
        endDate: string;
        remarks: string;
      } = {
        companyId,
        amount: Number(payment),
        users: Number(revisedUsersLimit),
        paymentDate: _paymentDate,
        startDate,
        endDate,
        remarks
      };
      setLoading(true);
      if (selectedLicenseHistory) {
        callUpdateLicense(selectedLicenseHistory.id, payload).then((res) => {
          if (res.code === 200) {
            onSuccess();
            setLoading(false);
          } else if (res.error) {
            setError({ title: 'Error', message: res.error.message ?? '' });
            setTimeout(() => {
              setError(undefined);
              setLoading(false);
            }, 5000);
          }
        });
      } else {
        callCreateLicense(payload).then((res) => {
          if (res.code === 200) {
            onSuccess();
            setLoading(false);
          } else if (res.error) {
            setError({ title: 'Error', message: res.error.message ?? '' });
            setTimeout(() => {
              setError(undefined);
              setLoading(false);
            }, 5000);
          }
        });
      }
    }
  };

  const onDelete = async () => {
    if (selectedLicenseHistory) {
      setLoading(true);
      const { code, error } = await callDeleteBillingLicense(selectedLicenseHistory.id);
      if (code >= 400 && error) {
        setError({ title: 'Error', message: error.message ?? '' });
        setTimeout(() => {
          setError(undefined);
          setLoading(false);
        }, 5000);
      } else {
        setLoading(false);
        onSuccess();
      }
    }
  };

  return (
    <Modal
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Paper sx={{ p: 3, width: '40%' }}>
        <Box sx={{ flexDirection: 'row', display: 'flex', mb: 2 }}>
          <TextView $fontSize={30}>Revise licence</TextView>
          <Box sx={{ flex: 1 }} />
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        {loading && <LinearProgress sx={{ mb: 1 }} />}
        <TextView $fontSize={24}>Current licence details</TextView>

        <Grid container mt={3} mb={4}>
          <Grid sx={{ backgroundColor: 'rgba(36, 36, 35, 0.08)', borderRadius: 1, p: 1, flex: 1 }}>
            <TextView $fontSize={16} $fontWeight={400}>
              Active users
            </TextView>
            <TextView $fontSize={18} $fontWeight={400}>
              {active}
            </TextView>
          </Grid>
          <Grid
            sx={{
              backgroundColor: 'rgba(36, 36, 35, 0.08)',
              borderRadius: 1,
              p: 1,
              ml: 1,
              flex: 1
            }}
          >
            <TextView $fontSize={16} $fontWeight={400}>
              Licence users
            </TextView>
            <TextView $fontSize={18} $fontWeight={400}>
              {licensed}
            </TextView>
          </Grid>
          <Grid
            sx={{
              backgroundColor: 'rgba(36, 36, 35, 0.08)',
              borderRadius: 1,
              p: 1,
              ml: 1,
              flex: 1
            }}
          >
            <TextView $fontSize={16} $fontWeight={400}>
              Paid till date
            </TextView>
            <TextView $fontSize={18} $fontWeight={400}>
              {endDate ? DateTime.fromISO(endDate).toFormat('dd LLL yyyy') : ''}
            </TextView>
          </Grid>
        </Grid>

        <TextView $fontSize={24}>New licence details</TextView>
        <Stack direction={'row'} display={'flex'}>
          <TextField
            label={'Payment received'}
            name={'payment'}
            type="number"
            placeholder={'Enter received payment amount'}
            errorMessage={errors.payment}
            value={values.payment}
            onChange={handleChange('payment')}
            fullWidth
          />
          <TextField
            label={'Payment received date'}
            name={'payment_date'}
            placeholder={'dd/mm/yyyy'}
            errorMessage={errors.paymentDate}
            value={values.paymentDate}
            onChange={handleChange('paymentDate')}
            sx={{ ml: 1 }}
            type="date"
            fullWidth
          />
        </Stack>
        <Stack direction={'row'} display={'flex'}>
          <TextField
            label={'Licence start date'}
            name={'license_start'}
            placeholder={'dd/mm/yyyy'}
            type="date"
            errorMessage={errors.licenceStart}
            value={values.licenceStart}
            onChange={handleChange('licenceStart')}
            fullWidth
          />
          <TextField
            label={'Licence end date'}
            name={'license_end'}
            placeholder={'dd/mm/yyyy'}
            type="date"
            errorMessage={errors.licenceEnd}
            value={values.licenceEnd}
            fullWidth
            sx={{ ml: 1 }}
            onChange={handleChange('licenceEnd')}
          />
        </Stack>
        <TextField
          label={'Revised users limit'}
          name={'revised_users_limit'}
          placeholder={'Enter revised users limit'}
          value={values.revisedUsersLimit}
          errorMessage={errors.revisedUsersLimit}
          type="number"
          onChange={handleChange('revisedUsersLimit')}
        />
        <TextField
          label={'Remarks'}
          name={'remarks'}
          placeholder={'Enter new licence remarks'}
          fullWidth
          value={values.remarks}
          errorMessage={errors.remarks}
          onChange={handleChange('remarks')}
        />

        {error && <Alert severity={'error'} title={error.title} message={error.message} />}

        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            mb: 1,
            mt: 4
          }}
        >
          <Button label={'Save licence'} onClick={onSubmit} sx={{ mt: 2 }} />
          {selectedLicenseHistory && (
            <IconButton onClick={onDelete}>
              <Delete sx={{ fontSize: 40, color: 'red' }} />
            </IconButton>
          )}
        </Box>
      </Paper>
    </Modal>
  );
};
export default ReviseLicenceModel;
