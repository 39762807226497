import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Box, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import DashboardHeader from './header';
import WhatappChart from './whatsapp';
import TwilloChart from './twillo';
import AIChart from './ai';
import NegotiatorChart from './negotiator';
import TotalTokenCusume from './totalTokenCusume';
import { callGetAnalytictokenCount, callLeadEvent } from '@api';

const Analytics: React.FC<RouteComponentProps> = () => {
  const [start, setStart] = useState<DateTime>(DateTime.local().minus({ days: 6 }));
  const [end, setEnd] = useState<DateTime>(DateTime.now());
  const [companyToken, setCompanyToken] = useState<CompanyToken>();
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<TokenCounts | undefined>(undefined);
  const [eventCount, setEventCount] = useState<number>(0);
  const startDate = start.toISO({
    suppressMilliseconds: true,
    includeOffset: false
  });
  const endDate = end.toISO({
    suppressMilliseconds: true,
    includeOffset: false
  });

  useEffect(() => {
    const api = async () => {
      setLoading(true);
      if (start && end) {
        const responseSrt = await callGetAnalytictokenCount({
          startDate,
          endDate,
          companyTokenId: companyToken?.id
        });

        if (responseSrt.code === 200 && responseSrt.data) {
          const item = responseSrt.data.data;
          if (item) {
            setValues(item);
          } else {
            setValues(undefined);
          }
        }

        const payload: any = {
          startDate,
          endDate,
          type: 'qualified'
        };
        let responseEvent = undefined;
        if (companyToken) {
          if (companyToken?.locationId) {
            payload.locationId = companyToken.locationId;
            responseEvent = await callLeadEvent(payload);
          } else {
            setEventCount(0);
          }
        } else {
          responseEvent = await callLeadEvent(payload);
        }
        const count = responseEvent?.data?.count;
        if (count) {
          setEventCount(count);
        } else {
          setEventCount(0);
        }
      }
      setLoading(false);
    };
    api();
  }, [start, end, companyToken]);

  return (
    <Stack sx={{ m: 2 }}>
      <Box sx={{ mt: 1, mb: 2 }}>
        <DashboardHeader
          start={start}
          end={end}
          onSelectRange={(start, end) => {
            setStart(start);
            setEnd(end);
          }}
          onSelectedAccount={function (companyToken?: CompanyToken): void {
            setCompanyToken(companyToken);
          }}
        />
        <TotalTokenCusume
          eventBookCount={eventCount}
          twillioCount={values?.twiltioTotalCount || 0}
          aiCount={values?.aiCount || 0}
          reapitCount={values?.reapitCount || 0}
          whatsAppTotalCount={values?.whatsAppTotalCount || 0}
          totalTokenConsumption={values?.totalTokenConsumption || 0}
          start={start}
          end={end}
          companyToken={companyToken}
        />
      </Box>

      <NegotiatorChart companyToken={companyToken} dates={{ start, end }} />
      <AIChart companyToken={companyToken} dates={{ start, end }} />
      <WhatappChart
        companyToken={companyToken}
        OutboundCount={values?.outboundCount || 0}
        InboundCount={values?.inboundCount || 0}
        TemplateCount={values?.templateCount || 0}
        dates={{ start, end }}
      />
      <TwilloChart
        companyToken={companyToken}
        TwillioInboundCallCount={values?.twillioInboundCallCount || 0}
        TwillioOutboundCallCount={values?.twillioOutboundCallCount || 0}
        TwillioOutboundSmsCount={values?.twillioOutboundSmsCount || 0}
        TwillioInboundSmsCount={values?.twillioInboundSmsCount || 0}
        TwillioOtherCount={values?.twillioOtherCount || 0}
        dates={{ start, end }}
      />
    </Stack>
  );
};
export default Analytics;
