import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

import { materialTheme } from '../../particles';

import { AvatarProps } from './index';

const avatarSize = (size: Sizes) => {
  switch (size) {
    case `xs`:
      return `16px`;
    case `sm`:
      return `32px`;
    case `md`:
      return `64px`;
    case `lg`:
      return `96px`;
    case `xl`:
      return `128px`;
    case `xxl`:
      return `192px`;
    default:
      return `64px`;
  }
};

const StyledAvatar = styled(Avatar)<AvatarProps>`
  ${({ $size }) => ($size ? `height: ${avatarSize($size)};` : ``)}
  ${({ $size }) => ($size ? `width: ${avatarSize($size)};` : ``)}
  ${({ $fontSize }) => ($fontSize ? `font-size: ${$fontSize}rem;` : ``)}

  ${(props) => {
    const { $backgroundColor, $responseCode } = props;
    if (!$backgroundColor && !$responseCode) return;

    if ($responseCode) {
      let backgroundColor = materialTheme.palette.success.main;
      if ($responseCode >= 300) backgroundColor = materialTheme.palette.warning.main;
      if ($responseCode >= 400) backgroundColor = materialTheme.palette.error.main;

      return `
        background-color: ${backgroundColor};
        color: ${materialTheme.palette.getContrastText(backgroundColor)};
      `;
    } else if ($backgroundColor) {
      return `
        background-color: ${$backgroundColor};
        color: ${materialTheme.palette.getContrastText($backgroundColor)};
      `;
    }
  }}
`;

export default StyledAvatar;
