const generateRows = (
  teams: Team[],
  onSwitch: (team: Team, value: unknown, type: 'edit' | 'delete') => void
): TableRow[] =>
  teams.map((team) => {
    const actionBtn: TableCellAction = {
      type: `action`,
      data: [
        {
          type: 'delete',
          linkData: {
            onClick: (value) => {
              onSwitch(team, value as boolean, 'delete');
            }
          }
        },
        {
          type: 'edit',
          linkData: {
            onClick: (value) => {
              onSwitch(team, value as boolean, 'edit');
            }
          }
        }
      ]
    };

    const payload: TableRow = {
      name: team.teamName ?? '',
      edit: actionBtn
    };

    return payload;
  });

export default generateRows;
