import fetchAPI from '../fetchAPI';

export const callGetCompanyAccountTypeCount = async (): Promise<iResponse<AccountTypeCount[]>> => {
  const response: iResponse<AccountTypeCount[]> = await fetchAPI({
    method: `GET`,
    route: `company/account-type-count`
  });

  return response;
};

export default callGetCompanyAccountTypeCount;
