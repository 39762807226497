const columns: TableColumn[] = [
  {
    Header: `Location`,
    accessor: `companyName`,
    dbKey: `companyName`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Status`,
    accessor: `status`,
    dbKey: `status`,
    isString: false,
    isNumeric: false,
    isDate: true,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Contacts`,
    accessor: `totalContacts`,
    dbKey: `totalContacts`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Users`,
    accessor: `totalUsers`,
    dbKey: `totalUsers`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `WABA`,
    accessor: `whatsAppUsageCount`,
    dbKey: `whatsAppUsageCount`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Events`,
    accessor: `leadEventsCount`,
    dbKey: `leadEventsCount`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `SMS`,
    accessor: `totalSmsCount`,
    dbKey: `totalSmsCount`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Call`,
    accessor: `totalCallCount`,
    dbKey: `totalCallCount`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  }
];

export default columns;
