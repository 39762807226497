import styled from '@emotion/styled';
import Modal from '@mui/material/Modal';

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default StyledModal;
