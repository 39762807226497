import fetchAPI from '../fetchAPI';

export const callGetKeys = async (): Promise<
  iResponse<{
    data: Keys[];
  }>
> => {
  const response: iResponse<{
    message: 'get';
    data: Keys[];
  }> = await fetchAPI({
    method: `GET`,
    route: `user-key`
  });

  // user?companyId=company-id
  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetKeys;
