import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Button, TextView } from '@atoms';
import { DateRangePicker, Range } from 'react-date-range';
import Popover from '@mui/material/Popover';
import EventIcon from '@mui/icons-material/EventOutlined';
import { DateTime } from 'luxon';
import { callGetAllCompanyToken, materialTheme } from '@particles';
import { toast } from 'react-toastify';
interface HeaderProps {
  start: DateTime;
  end: DateTime;
  onSelectRange?: (start: DateTime, end: DateTime) => void;
  onSelectedAccount: (companyToken?: CompanyToken) => void;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { start, end, onSelectRange, onSelectedAccount } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [accounts, setAccounts] = useState<CompanyToken[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string>('0');

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [range, setRange] = useState<Range[]>([
    {
      startDate: start.toJSDate(),
      endDate: end.toJSDate(),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    const date = new Date();
    const start = range[0]?.startDate ? range[0].startDate : date;
    const end = range[0]?.endDate ? range[0].endDate : date;

    const newEnd = new Date(end);
    newEnd.setHours(23);
    newEnd.setMinutes(59);
    newEnd.setSeconds(59);
    onSelectRange && onSelectRange(DateTime.fromJSDate(start), DateTime.fromJSDate(newEnd));
  }, [range]);

  useEffect(() => {
    const api = async () => {
      const { data, code } = await callGetAllCompanyToken({ pageNumber: 1, pageSize: 10000 });
      if (code == 200 && data) {
        setAccounts(
          data.data.sort(function (a, b) {
            if (a.companyName < b.companyName) {
              return -1;
            }
            if (a.companyName > b.companyName) {
              return 1;
            }
            return 0;
          })
        );
      }
    };
    api();
  }, []);

  const downloadExport = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    const apiBase = process.env.REACT_APP_API_BASE;

    const startDate = start.toISO({
      suppressMilliseconds: true,
      includeOffset: false
    });
    const endDate = end.toISO({
      suppressMilliseconds: true,
      includeOffset: false
    });

    const queryString = `
    ${selectedAccount != '0' ? `&companyTokenId=${selectedAccount}` : ``}
    ${startDate ? `&startDate=${startDate}` : ``}
    ${endDate ? `&endDate=${endDate}` : ``}`;

    const urlApi = `${apiBase}/srt/csv-download?=${queryString}`;

    const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
    const hour = Number(zone[0]);
    const mint = Number(zone[1]);
    const totalMint: number = hour * 60 + mint;

    const fetchOptions: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}` ?? '',
        'Content-Type': 'application/json',
        timezone: `${totalMint}`
      },
      method: 'GET'
    };

    const response: Response = await fetch(urlApi, fetchOptions);

    if (response.status == 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `AI Processing ${DateTime.now().toFormat('LLL dd yyyy, hh:mm:ss')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast('Something went wrong');
    }
    setLoading(false);
  };

  const handleChangeAccount = (event: SelectChangeEvent) => {
    const companyToken = accounts.find((item) => item.id == event.target.value);
    setSelectedAccount(event.target.value);
    onSelectedAccount(companyToken);
  };

  return (
    <>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <TextView $fontSize={30}>
          Analytics (SR Token)
        </TextView>
        <Box sx={{ flex: 1 }} />
        <TextView $fontWeight={400}  $fontSize={14} mr={1}>
          Filters
        </TextView>
        <Select
          value={selectedAccount}
          onChange={handleChangeAccount}
          displayEmpty
          size={'small'}
          sx={{ backgroundColor: '#ffffff', fontSize: 14, mr: 1 }}
          inputProps={{ 'aria-label': 'Without label' }}>
          <MenuItem key={0} value={'0'}>
            {'All Accounts'}
          </MenuItem>
          {accounts.map((item) => {
            return (
              <MenuItem key={item.id} value={`${item.id}`}>
                {item.companyName}
              </MenuItem>
            );
          })}
        </Select>
        <Button
          aria-describedby={id}
          sx={{ paddingLeft: 5, color: materialTheme.palette.text.primary, mr: 2 }}
          startIcon={<EventIcon />}
          variant={'outlined'}
          onClick={handleClick}>
          <TextView $fontWeight={400} >
            {DateTime.fromJSDate(range[0].startDate ? range[0]?.startDate : new Date()).toFormat(
              'LLLL, dd yyyy'
            )}
            {` - `}
            {DateTime.fromJSDate(range[0].endDate ? range[0]?.endDate : new Date()).toFormat(
              'LLLL, dd yyyy'
            )}
          </TextView>
        </Button>
        <Button
          label="SR Tokens Export"
          onClick={downloadExport}
          size="small"
          sx={{ mr: 2 }}
          disabled={loading}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}>
          <DateRangePicker
            onChange={(item) => setRange([item.selection])}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={range}
            direction="horizontal"
          />
        </Popover>
      </Box>
    </>
  );
};
export default Header;
