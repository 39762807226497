import fetchAPI from '../fetchAPI';

interface callGetUserProps {
  email?: string;
  companyId?: string;
}

export const callGetUser = async (props: callGetUserProps): Promise<iResponse<User>> => {
  const { companyId, email } = props;
  let queryString = '';

  if (companyId && email) {
    queryString = `email=${email}&companyId=${companyId}`;
  } else if (email) {
    queryString = `email=${email}`;
  } else if (companyId) {
    queryString = `companyId=${companyId}`;
  }

  const response: iResponse<User> = await fetchAPI({
    method: `GET`,
    route: `user`,
    queryString
  });

  // user?companyId=company-id
  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetUser;
