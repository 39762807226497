import React from 'react';
import { Grid } from '@mui/material';
import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconActive } from '@assets/svg/active.svg';
import { ReactComponent as IconInActive } from '@assets/svg/inactive.svg';
import { CommonCard } from '@organisms';

interface CardListProps {
  totalUser: number;
  activeUser: number;
  inactiveUser: number;
}

const CardList: React.FC<CardListProps> = ({
  totalUser,
  activeUser,
  inactiveUser
}: CardListProps) => {
  return (
    <Grid columnSpacing={4} container mt={2}>
      <Grid md={4} item>
        <CommonCard title="Total Users" count={`${totalUser}`} icon={<IconTeam />} />
      </Grid>
      <Grid md={4} item>
        <CommonCard
          title="Active Users"
          count={`${activeUser}`}
          icon={<IconActive color="rgba(255, 255, 255, 0.16)" />}
          bgColor={'#086BDE'}
          fontColor={'#FFFFFF'}
        />
      </Grid>
      <Grid md={4} item>
        <CommonCard
          title="Inactive Users"
          count={`${inactiveUser}`}
          icon={<IconInActive />}
          bgColor={'rgba(36, 36, 35, 0.08)'}
        />
      </Grid>
    </Grid>
  );
};

export default CardList;
