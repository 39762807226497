import { createContext, Dispatch } from 'react';

type ApplicationContextType = {
  dispatch: Dispatch<ApplicationReducerAction>;
  state: ApplicationState;
};

export const ApplicationContext = createContext<ApplicationContextType>(
  {} as ApplicationContextType
);

export default ApplicationContext;
