import React from 'react';
interface CustomizedAxisTickProps {
  x: number;
  y: number;
  payload: { value: any };
}

const CustomizedYAxisTick = (props: CustomizedAxisTickProps) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="end"
        fill="#666"
        style={{ fontFamily: 'Montserrat', fontSize: 12, fontWeight: 400 }}
      >
        {Number(payload.value)}
      </text>
    </g>
  );
};
export default CustomizedYAxisTick;
