import styled from '@emotion/styled';
import Chip from '@mui/material/Chip';

import { materialTheme } from '../../particles';

import { ChipProps } from './index';

const StyledChip = styled(Chip)<ChipProps>`
  ${({ $backgroundColor }) =>
    $backgroundColor
      ? `background-color: ${$backgroundColor};`
      : `background-color: ${materialTheme.palette.primary.light};`}

  .MuiChip-label {
    ${({ $labelSize }) => ($labelSize ? `font-size: ${$labelSize}rem;` : ``)}
    ${({ $textColor }) => ($textColor ? `color: ${$textColor};` : ``)}
  }

  .MuiChip-avatar {
    ${({ $avatarFontSize }) => ($avatarFontSize ? `font-size: ${$avatarFontSize}rem;` : ``)}
    ${({ $avatarBackgroundColor }) =>
      $avatarBackgroundColor ? `background-color: ${$avatarBackgroundColor};` : ``}
    ${({ $avatarTextColor }) => ($avatarTextColor ? `color: ${$avatarTextColor};` : ``)}
  }
`;

export default StyledChip;
