import fetchAPI from '../fetchAPI';

export const callDeleteTeams = async (id: string): Promise<iResponse<Team>> => {
  const response: iResponse<Team> = await fetchAPI({
    method: `DELETE`,
    route: `team/${id}`
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callDeleteTeams;
