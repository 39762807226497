import React, { useState, useEffect } from 'react';
import { Box, Modal, IconButton, Paper, Grid, LinearProgress, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import Validator from 'validator';
import { TextView, Button, TextField, Alert, Chip } from '@atoms';
import { Delete } from '@mui/icons-material';
import { callCreateCompanyToken, callUpdateCompanyToken } from '@particles';

interface AddCompanyTokenProps {
  open: boolean;
  selectedOfficeAddress?: CoreOfficeAddress;
  onClose: () => void;
  onSuccess: () => void;
}

const defaultCompanyToken: {
  officeId: string;
  location: string;
  read_email: string;
  reapit_email: string;
  robot_email: string;
  postal_code: string[];
} = {
  officeId: '',
  location: '',
  read_email: '',
  reapit_email: '',
  robot_email: '',
  postal_code: []
};

const defaultErrorCompanyToken: {
  officeId: string;
  location: string;
  read_email: string;
  reapit_email: string;
  robot_email: string;
  postal_code: string;
} = {
  officeId: '',
  location: '',
  read_email: '',
  reapit_email: '',
  robot_email: '',
  postal_code: ''
};

const AddOffice: React.FC<AddCompanyTokenProps> = ({
  onClose,
  open,
  onSuccess,
  selectedOfficeAddress
}: AddCompanyTokenProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [postalCode, setPostalCode] = useState<string>('');
  const [values, setValues] = React.useState(defaultCompanyToken);

  const [error, setError] = useState<{
    title: string;
    message: string;
  }>();

  const [errors, setErrors] = useState(defaultErrorCompanyToken);

  useEffect(() => {
    if (selectedOfficeAddress) {
      setValues({
        officeId: selectedOfficeAddress.officeId,
        location: selectedOfficeAddress.location,
        read_email: selectedOfficeAddress.read_email,
        reapit_email: selectedOfficeAddress.reapit_email,
        robot_email: selectedOfficeAddress.robot_email,
        postal_code: selectedOfficeAddress.postal_code
      });
    }
  }, [selectedOfficeAddress]);

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (prop == 'postal_code') {
      setPostalCode(event.target.value);
      return;
    }

    setValues({ ...values, [prop]: event.target.value });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { officeId, location, read_email, reapit_email, robot_email, postal_code } = values;

    if (validateFields()) {
      return;
    }

    const companyToken = {
      officeId,
      location,
      read_email,
      reapit_email,
      robot_email,
      postal_code
    };

    if (selectedOfficeAddress) {
      setLoading(true);
      callUpdateCompanyToken(selectedOfficeAddress.id, companyToken)
        .then((res) => {
          if (res.code == 200) {
            setValues(defaultCompanyToken);
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    } else {
      setLoading(true);
      callCreateCompanyToken(companyToken)
        .then((res) => {
          if (res.code == 201) {
            setValues(defaultCompanyToken);
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    }
  };

  const validateFields = () => {
    let error = false;
    setErrors(defaultErrorCompanyToken);
    if (Validator.isEmpty(values.officeId.trim())) {
      error = true;
      setErrors((state) => ({ ...state, officeId: 'OfficeID is required' }));
    }

    if (Validator.isEmpty(values.location.trim())) {
      error = true;
      setErrors((state) => ({ ...state, location: 'Location is required' }));
    }

    if (Validator.isEmpty(values.read_email.trim())) {
      error = true;
      setErrors((state) => ({ ...state, read_email: 'Read Email is required' }));
    }

    if (Validator.isEmpty(values.reapit_email.trim())) {
      error = true;
      setErrors((state) => ({ ...state, reapit_email: 'Reapit Email is required' }));
    }

    if (Validator.isEmpty(values.robot_email.trim())) {
      error = true;
      setErrors((state) => ({ ...state, robot_email: 'Robot Email is required' }));
    }

    if (values.postal_code.length == 0) {
      error = true;
      setErrors((state) => ({ ...state, postal_code: 'PostalCode is required' }));
    }

    return error;
  };

  function validateOutwardAndInwardCode(postcode: string): boolean {
    // Define regex for outward code and the first digit of the inward code
    const pattern = /^[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9]/i;

    // Test the postcode against the pattern
    return pattern.test(postcode.trim());
  }

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        setValues(defaultCompanyToken);
        setErrors(defaultErrorCompanyToken);
        onClose();
      }}>
      <Grid md={6} sm={10} xs={10} container>
        <Paper sx={{ p: 2, width: '100%' }}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <TextView $fontSize={26}>{`${
              selectedOfficeAddress ? 'Edit' : 'Add'
            } Office Address`}</TextView>
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                onClose();
                setValues(defaultCompanyToken);
                setErrors(defaultErrorCompanyToken);
              }}>
              <Close />
            </IconButton>
          </Box>
          {loading && <LinearProgress />}
          <form onSubmit={onSubmit}>
            <Grid spacing={2} container>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Office Id'}
                  name={'officeId'}
                  errorMessage={errors.officeId}
                  fullWidth
                  placeholder="MKT"
                  inputType="text"
                  value={values.officeId}
                  onChange={handleChange('officeId')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Office Location'}
                  name={'location'}
                  placeholder="Birmingham"
                  errorMessage={errors.location}
                  fullWidth
                  inputType="text"
                  value={values.location}
                  onChange={handleChange('location')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Reapit Email'}
                  name={'reapit_email'}
                  errorMessage={errors.reapit_email}
                  fullWidth
                  inputType="email"
                  value={values.reapit_email}
                  onChange={handleChange('reapit_email')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Read Email'}
                  name={'read_email'}
                  errorMessage={errors.read_email}
                  fullWidth
                  inputType="email"
                  value={values.read_email}
                  onChange={handleChange('read_email')}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  label={'Robot Email'}
                  name={'robot_email'}
                  errorMessage={errors.robot_email}
                  fullWidth
                  inputType="email"
                  value={values.robot_email}
                  onChange={handleChange('robot_email')}
                />
              </Grid>
            </Grid>

            <TextView $fontSize={20} mt={3}>
              Postal Code
            </TextView>
            <Grid item xs={12} lg={6}>
              <Stack spacing={2} direction="row" alignItems={'end'}>
                <TextField
                  label={''}
                  name={'postal_code'}
                  fullWidth
                  inputType="text"
                  value={postalCode}
                  errorMessage={errors.postal_code}
                  onChange={handleChange('postal_code')}
                />
                <Button
                  label={'Add'}
                  size="small"
                  sx={{ bottom: errors.postal_code ? 20 : 5 }}
                  onClick={() => {
                    if (postalCode.trim().length == 0) {
                      setErrors((state) => ({ ...state, postal_code: 'PostalCode is required' }));
                      return;
                    }

                    if (validateOutwardAndInwardCode(postalCode)) {
                      setValues({ ...values, postal_code: [...values.postal_code, postalCode] });
                      setPostalCode('');
                    } else {
                      setErrors((state) => ({ ...state, postal_code: 'PostalCode Invalid' }));
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid direction={'row'} spacing={1} container mt={1}>
              {values.postal_code.map((postal_code, index) => (
                <Grid item key={index}>
                  <Chip
                    onDelete={() => {
                      values.postal_code.splice(index, 1);
                      setValues({ ...values, postal_code: [...values.postal_code] });
                    }}
                    label={postal_code}
                    variant="filled"
                    deleteIcon={<Delete />}
                    $backgroundColor="#f2f2f2"
                  />
                </Grid>
              ))}
            </Grid>
            {error && <Alert severity={'error'} title={error.title} message={error.message} />}

            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                mt: 4
              }}>
              <Button
                label={`${selectedOfficeAddress ? 'Update' : 'Add'}`}
                size="small"
                type="submit"
              />
            </Box>
          </form>
        </Paper>
      </Grid>
    </Modal>
  );
};
export default AddOffice;
