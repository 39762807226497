import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Modal,
  IconButton,
  Paper,
  Switch,
  Grid,
  FormControlLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  LinearProgress,
  Button as LinkButton
} from '@mui/material';
import { Close, Delete } from '@mui/icons-material';
import Validator from 'validator';

import { TextView, Button, TextField, Alert } from '../../../../../atoms';
import {
  callCreateInviteUser,
  callGetUserRoles,
  callUpdateUser,
  callDeleteUser,
  callGetTeamsList,
  callAssignTeamToUser,
  callRemoveUserFromTeam
} from '../../../../../particles/api';
import { ApplicationContext } from '@particles';

interface AddUserModelProps {
  open: boolean;
  companyId?: string;
  selectedAgent?: Agent;
  onClose: () => void;
  onSuccess: () => void;
}

const AddUserModel: React.FC<AddUserModelProps> = ({
  onClose,
  open,
  onSuccess,
  selectedAgent,
  companyId
}: AddUserModelProps) => {
  const {
    state: { user }
  } = useContext(ApplicationContext);

  const [chat, setChat] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const [userRoles, setUserRoles] = useState<UserRoles[]>([]);

  const [userRole, setUserRole] = React.useState<UserRoles>();

  const [teamList, setTeamList] = useState<Team[]>([]);

  const [selectedTeam, setSelectedTeam] = useState<Team>();

  const [values, setValues] = React.useState({
    name: '',
    email: '',
    phone: ''
  });

  const [error, setError] = useState<{
    title: string;
    message: string;
  }>();

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    if (selectedAgent) {
      setValues({
        name: selectedAgent.name,
        email: selectedAgent.email,
        phone: selectedAgent.phone
      });
    }

    callGetUserRoles().then((res) => {
      setUserRoles(res.data?.data ?? []);

      if (selectedAgent) {
        const _userRole = userRoles.find((item) => item.id === selectedAgent.roleId);
        if (_userRole) {
          setUserRole(_userRole);
        }
      }
    });

    // get all team list by companyId
    if (companyId) {
      callGetTeamsList({ companyId }).then((res) => {
        setTeamList(res.data ?? []);

        if (selectedAgent) {
          const _selectTeam = teamList.find((item) => item.id === selectedAgent.teamId);
          if (_selectTeam) {
            setSelectedTeam(_selectTeam);
          }
        }
      });
    }
  }, [open, selectedAgent]);

  const handleChangeSelected = (event: SelectChangeEvent) => {
    const _userRole = userRoles.find((item) => item.id === event.target.value);

    if (_userRole) {
      setUserRole(_userRole);
    }
  };

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeTeamSelected = (event: SelectChangeEvent) => {
    const _teamData = teamList.find((item) => item.id === event.target.value);
    if (_teamData) {
      assignUserToTeam(_teamData);
    } else {
      removeUserFromTeam();
    }
  };

  const assignUserToTeam = (team?: Team) => {
    setSelectedTeam(team);

    setLoading(true);
    callAssignTeamToUser({
      userId: selectedAgent?.id ?? '',
      teamId: team?.id ?? ''
    }).finally(() => {
      setLoading(false);
    });
  };
  const removeUserFromTeam = () => {
    setSelectedTeam(undefined);
    setLoading(true);
    callRemoveUserFromTeam(selectedAgent?.id ?? '').finally(() => {
      setLoading(false);
    });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { name, email, phone } = values;

    if (!user || validateFields()) {
      return;
    }

    const _companyId = companyId ?? user.companyId;

    if (selectedAgent) {
      setLoading(true);
      callUpdateUser(selectedAgent.id, {
        name,
        email,
        phone,
        roleId: userRole?.id ?? '',
        teamId: selectedTeam?.id ?? '',
        companyId: _companyId,
        autoChatSync: chat
      })
        .then((res) => {
          if (res.code == 200) {
            setValues({ name: '', email: '', phone: '' });
            setUserRole(undefined);
            setSelectedTeam(undefined);
            setChat(true);
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    } else {
      setLoading(true);
      callCreateInviteUser({
        name,
        email,
        phone,
        // roleId: userRole?.id ?? '',
        companyId: _companyId,
        autoChatSync: chat
      })
        .then((res) => {
          if (res.code == 201) {
            setValues({ name: '', email: '', phone: '' });
            setChat(true);
            onSuccess();
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore;
            setError({ title: 'Error', message: res.error?.message ?? '' });
            setTimeout(() => {
              setError(undefined);
            }, 5000);
          }
          setLoading(false);
        })
        .catch((error) => {
          alert(error);
          setLoading(false);
        });
    }
  };

  const validateFields = () => {
    let error = false;

    setErrors({
      name: '',
      email: '',
      phone: ''
    });

    if (Validator.isEmpty(values.name)) {
      error = true;
      setErrors((state) => ({ ...state, name: 'Name is required' }));
    }
    if (!Validator.isEmail(values.email)) {
      error = true;
      setErrors((state) => ({ ...state, email: 'Enter a valid email address' }));
    }
    if (!Validator.isMobilePhone(values.phone)) {
      error = true;
      setErrors((state) => ({ ...state, phone: 'Enter a valid phone number' }));
    }
    return error;
  };

  const api = async () => {
    if (selectedAgent) {
      setLoading(true);
      const { code, error } = await callDeleteUser(selectedAgent.id);
      if (code >= 400 && error) {
        setError({ title: 'Error', message: error.message ?? '' });
        setTimeout(() => {
          setError(undefined);
          setLoading(false);
        }, 5000);
      } else {
        setLoading(false);
        onSuccess();
      }
    }
  };

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Grid md={4} sm={10} xs={10} item>
        <Paper sx={{ p: 4, width: '100%' }}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <TextView $fontSize={28}>{`${selectedAgent ? 'Edit' : 'Add'} User`}</TextView>
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                onClose();
                setChat(true);
                setValues({ name: '', email: '', phone: '' });
              }}
            >
              <Close />
            </IconButton>
          </Box>
          {loading && <LinearProgress />}
          <form onSubmit={onSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 3 }}>
              <TextField
                label={'Name'}
                name={'name'}
                errorMessage={errors.name}
                fullWidth
                value={values.name}
                onChange={handleChange('name')}
              />

              <TextField
                label={'Email'}
                name={'email'}
                fullWidth
                errorMessage={errors.email}
                value={values.email}
                onChange={handleChange('email')}
              />

              <TextField
                label={'Phone'}
                name={'phone'}
                fullWidth
                errorMessage={errors.phone}
                value={values.phone}
                onChange={handleChange('phone')}
              />

              {companyId && selectedAgent && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <TextView $fontSize={14} $fontWeight={400}  sx={{ mt: 1 }}>
                    Role
                  </TextView>
                  <Select
                    value={userRole?.id ?? ''}
                    onChange={handleChangeSelected}
                    displayEmpty
                    size={'small'}
                    sx={{ backgroundColor: '#ffffff', fontSize: 14, mt: 1 }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {userRoles.map((item) => {
                      return (
                        <MenuItem key={item.id} value={`${item.id}`}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              )}

              {companyId && selectedAgent && teamList.length > 0 && (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <TextView $fontSize={14} $fontWeight={400}  sx={{ mt: 1 }}>
                    Teams
                  </TextView>
                  <Select
                    value={selectedTeam?.id ?? '0'}
                    onChange={handleChangeTeamSelected}
                    displayEmpty
                    size={'small'}
                    sx={{ backgroundColor: '#ffffff', fontSize: 14, mt: 1 }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem key={0} value={'0'}>
                      {'Select'}
                    </MenuItem>
                    {teamList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={`${item.id}`}>
                          {item.teamName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <LinkButton sx={{ mt: 1, alignSelf: 'end' }} onClick={removeUserFromTeam}>
                    <TextView
                      $fontSize={12}
                      $fontWeight={400}
                      $fontColor="red"
                      
                      sx={{ textDecoration: 'underline' }}
                    >
                      Remove user from team
                    </TextView>
                  </LinkButton>
                </Box>
              )}
            </Box>

            {!companyId && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  mb: 2,
                  borderRadius: '6px',
                  p: 1.5,
                  backgroundColor: 'rgba(36, 36, 35, 0.08)'
                }}
              >
                <TextView
                  $fontSize={16}
                  $fontWeight={400}
                  
                  sx={{ alignSelf: 'center' }}
                >
                  Auto Chat Sync
                </TextView>
                <Box sx={{ flex: 1 }} />
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      value={chat}
                      onChange={(event, checked) => {
                        setChat(checked);
                      }}
                    />
                  }
                  labelPlacement={'start'}
                  label={chat ? 'On' : 'Off'}
                />
              </Box>
            )}

            {error && <Alert severity={'error'} title={error.title} message={error.message} />}

            <Box
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                mb: 1,
                mt: 4
              }}
            >
              <Button label={`${selectedAgent ? 'Edit' : 'Add'} User`} size="small" type="submit" />
              {selectedAgent && (
                <IconButton
                  onClick={() => {
                    api();
                  }}
                >
                  <Delete sx={{ fontSize: 40, color: 'red' }} />
                </IconButton>
              )}
            </Box>
          </form>
        </Paper>
      </Grid>
    </Modal>
  );
};
export default AddUserModel;
