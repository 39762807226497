const columns: TableColumn[] = [
  // {
  //   Header: `Location ID`,
  //   accessor: `locationId`,
  //   dbKey: `locationId`,
  //   isString: true,
  //   isNumeric: false,
  //   isDate: false,
  //   isBool: false,
  //   isSortable: false
  // },
  {
    Header: ``,
    accessor: `type`,
    dbKey: `type`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Location`,
    accessor: `companyName`,
    dbKey: `companyName`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Billing Period`,
    accessor: `subscriptionDate`,
    dbKey: `subscriptionDate`,
    isString: false,
    isNumeric: false,
    isDate: true,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Allocated Tokens`,
    accessor: `totalToken`,
    dbKey: `totalToken`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Last 30D Tokens`,
    accessor: `rolling_month`,
    dbKey: `rolling_month`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Consumed Tokens`,
    accessor: `srtValuesBasedSubscription`,
    dbKey: `srtValuesBasedSubscription`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Available Tokens`,
    accessor: `availableToken`,
    dbKey: `availableToken`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Twilio`,
    accessor: `twilioCredentials`,
    dbKey: `twilioCredentials`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Action`,
    accessor: `action`,
    dbKey: `action`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
