import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import StyledLoadingCircle from './loadingCircle.style';

export interface LoadingCircleProps {
  /** The size of the loading circle */
  size?: number;
}

export const LoadingCircle: React.FC<LoadingCircleProps> = ({ size }: LoadingCircleProps) => (
  <StyledLoadingCircle
    display="flex"
    flex={1}
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="100%"
  >
    <CircularProgress size={size} />
  </StyledLoadingCircle>
);

export default LoadingCircle;
