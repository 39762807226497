import fetchAPI from '../fetchAPI';

export const callUpdateUser = async (id: string, data: any): Promise<iResponse<User>> => {
  const response: iResponse<User> = await fetchAPI({
    method: `PATCH`,
    route: `user/${id}`,
    data
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callUpdateUser;
