import fetchAPI from '../fetchAPI';

interface callGetLicenseProps {
  id: string;
  pageNumber?: number;
  pageSize?: number;
}

export const callGetLicense = async ({
  pageNumber,
  pageSize,
  id
}: callGetLicenseProps): Promise<iResponse<Pagination<LicenseHistory[]>>> => {
  const queryParams = new URLSearchParams();
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  const queryString = queryParams.toString();

  const response: iResponse<Pagination<LicenseHistory[]>> = await fetchAPI({
    method: `GET`,
    route: `company/${id}/license`,
    queryString
  });

  return response;
};

export default callGetLicense;
