import React from 'react';
const CustomBarLabel = ({
  x,
  y,
  width,
  value
}: {
  x: number;
  y: number;
  width: number;
  value: number;
}) => {
  return (
    <text x={x + width / 2} y={y} fill="#666" fontSize={8} textAnchor="middle" dy={-6}>{`${
      value > 0 ? value.toFixed(0) : ''
    }`}</text>
  );
};

export default CustomBarLabel;
