import styled from '@emotion/styled';
import Card from '@mui/material/Card';

import { CardProps } from './index';

const StyledCard = styled(Card)<CardProps>`
  padding: 1em;
`;

export default StyledCard;
