import fetchAPI from '../fetchAPI';

export const callUpdateMarupValues = async (data: any): Promise<iResponse<CompanyToken>> => {
  const response: iResponse<CompanyToken> = await fetchAPI({
    method: `PUT`,
    route: `markup`,
    data
  });

  return response;
};

export default callUpdateMarupValues;
