import styled from '@emotion/styled';
import Drawer from '@mui/material/Drawer';
import { Palette, PaletteColor } from '@mui/material/styles/createPalette';

import { materialTheme } from '../../particles/theme';

const StyledSideNav = styled(Drawer)<{ $open: boolean }>`
  height: 100%;
  display: flex;
  white-space: nowrap;
  padding: 0.7rem;
  background: linear-gradient(#3182ce, #887bdc 30%);
  border-radius: 0;

  .MuiDrawer-paper {
    width: 100%;
    background: linear-gradient(#3182ce, #887bdc 30%);
    position: unset;
    overflow-x: hidden;
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border-right: unset;
  }

  .MuiListItem-root.Mui-selected {
    background-color: ${materialTheme.palette.secondary.main};
    border-radius: 10px;
  }

  ,
  .MuiListItem-button:hover,
  .MuiListItemText-root {
    color: ${materialTheme.palette.text.secondary};
  }

  ${[`primary`].map((section) => {
    const palette = materialTheme.palette['primary' as keyof Palette] as PaletteColor;
    if (!palette) return ``;

    const { main, light } = palette;

    return `
        .${section} {
          .MuiListItemIcon-root,
          .MuiTypography-colorPrimary,
          .MuiSvgIcon-root {
            color: rgb(255 255 255 / 70%);
          }
          .MuiListItemText-root {
              color: rgb(255 255 255 / 70%);
            letter-spacing: 0.1rem;
            font-family: ${materialTheme.typography.fontFamily};
           }

          .MuiListItem-root.Mui-selected,
          .MuiListItem-button:hover {
            background-color: ${light};
            .MuiListItemIcon-root,
            .MuiTypography-colorPrimary,
            .MuiSvgIcon-root {
              color: ${main};
            }
          }

          .MuiTouchRipple-child {
            background-color: ${main};
            border-radius: 0.5rem;
          }
        }

        .${section}-selected {
          .MuiListItemIcon-root,
          .MuiTypography-colorPrimary,
          .MuiSvgIcon-root {
            color: white;
          }
          .MuiListItemText-root {
             color: white;
           }
        }
      `;
  })}

  // Open / close transition
  ${(props) => {
    if (props.$open) {
      return `
        width: 230px;
        transition: ${materialTheme.transitions.create(`width`, {
          easing: materialTheme.transitions.easing.sharp,
          duration: materialTheme.transitions.duration.enteringScreen
        })};
      `;
    } else {
      return `
        width: 55px;
        transition: ${materialTheme.transitions.create(`width`, {
          easing: materialTheme.transitions.easing.sharp,
          duration: materialTheme.transitions.duration.leavingScreen
        })};
      `;
    }
  }}
`;

export default StyledSideNav;
