import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';

import { materialTheme } from '../../particles';

const StyledTable = styled.div<{ $headerHeight: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 25px;

  &:nth-of-type(odd) {
    background-color: ${materialTheme.palette.background.default};
  }

  .pointer {
    cursor: pointer;
  }

  thead {
    tr {
      background-color: rgba(36, 36, 35, 0.08);
    }
  }

  tbody {
    tr:hover {
      background-color: ${alpha(materialTheme.palette.text.disabled, 0.1)};
    }
  }

  .table-cards {
    float: left;

    table {
      border-collapse: separate;
      border-spacing: ${materialTheme.spacing(0, 2)};
      padding: ${materialTheme.spacing(0, 2)};

      th {
        border-bottom: unset;
      }

      td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        border-top: 1px solid rgba(0, 0, 0, 0.12);

        :first-of-type {
          border-left: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: ${materialTheme.shape.borderRadius}px 0 0
            ${materialTheme.shape.borderRadius}px;
        }

        :last-of-type {
          border-right: 1px solid rgba(0, 0, 0, 0.12);
          border-radius: 0 ${materialTheme.shape.borderRadius}px
            ${materialTheme.shape.borderRadius}px 0;
        }
      }
    }

    .MuiDivider-root {
      z-index: 5;
      margin-bottom: -${materialTheme.spacing(2)}px;
    }

    .header-background {
      background-color: ${materialTheme.palette.primary.light};
      position: relative;
      z-index: 3;
      ${({ $headerHeight }) => ($headerHeight ? `height: ${$headerHeight + 1}px;` : ``)}
      top: ${materialTheme.spacing(2)}px;
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    .table-cards-wrapper {
      display: block;
      z-index: 5;
      position: relative;
      ${({ $headerHeight }) => ($headerHeight ? `top: -${$headerHeight + 1}px;` : ``)}
    }
  }
`;

export default StyledTable;
