import React, { Suspense, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { LoadingCircle } from '../../atoms';
import { SideNav } from '../../organisms';

import StyledSuperAdmin from './super-admin.style';
import { ApplicationContext } from '@particles';
import { Errors404 } from '@pages';

export interface ErrorProps {
  /** Suspense error */
  error: Error;
}

export interface PageProps extends RouteComponentProps {
  /** Page content */
  children: React.ReactNode;
}

export const SuperAdminTemplate: React.FC<PageProps> = ({ children }: PageProps) => {
  const {
    state: { user }
  } = useContext(ApplicationContext);

  const isSuperAdmin = user?.role.name === 'Super Admin';

  return (
    <StyledSuperAdmin className="App">
      <Paper className="sidebar" square elevation={0}>
        <SideNav isSuperAdmin={true} />
      </Paper>
      <div className="container">
        <Paper className="main" square elevation={0}>
          <Box component="main" height="100%" display="flex" flexDirection="column">
            <Suspense fallback={<LoadingCircle size={150} />}>
              {isSuperAdmin ? (
                React.isValidElement(children) && React.cloneElement(children)
              ) : (
                <Errors404 />
              )}
            </Suspense>
          </Box>
        </Paper>
      </div>
    </StyledSuperAdmin>
  );
};

export default SuperAdminTemplate;
