import React, { useState, useEffect } from 'react';
import { Box, Modal, IconButton, Paper } from '@mui/material';
import { Close } from '@mui/icons-material';
import Validator from 'validator';
import { TextView, Button, TextField, Avatar } from '@atoms';
import { callUpdateCompany, callUpdateUser } from '@api';

interface UploadUserProps {
  open: boolean;
  user?: User;
  onClose: () => void;
  onSuccess: (user: User) => void;
}

const EditAccountModel: React.FC<UploadUserProps> = ({
  onClose,
  open,
  onSuccess,
  user
}: UploadUserProps) => {
  useEffect(() => {
    if (user) {
      setValues({
        name: user?.name ?? '',
        email: user?.email ?? '',
        phone: user?.phone ?? '',
        companyName: user?.company?.name ?? '',
        dbaName: user?.company?.dbaName ?? '',
        website: user?.company?.website ?? '',
        address: user?.company?.address ?? ''
      });
      setErrors({
        name: '',
        email: '',
        phone: '',
        companyName: '',
        dbaName: '',
        website: '',
        address: ''
      });
    }
  }, [user]);

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [selectedUri, setSelectedUri] = useState<string | ArrayBuffer | null>(null);

  const [values, setValues] = React.useState({
    name: user?.name ?? '',
    email: user?.email ?? '',
    phone: user?.phone ?? '',
    companyName: user?.company?.name ?? '',
    dbaName: user?.company?.dbaName ?? '',
    website: user?.company?.website ?? '',
    address: user?.company?.address ?? ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    companyName: '',
    dbaName: '',
    website: '',
    address: ''
  });

  const handleChange = (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const validateFields = () => {
    let error = false;

    setErrors({
      name: '',
      email: '',
      phone: '',
      companyName: '',
      dbaName: '',
      website: '',
      address: ''
    });

    if (Validator.isEmpty(values.name)) {
      error = true;
      setErrors((state) => ({ ...state, name: 'Name is required' }));
    }
    if (!Validator.isEmail(values.email)) {
      error = true;
      setErrors((state) => ({ ...state, email: 'Enter a valid email address' }));
    }
    if (!Validator.isMobilePhone(values.phone)) {
      error = true;
      setErrors((state) => ({ ...state, phone: 'Enter a valid phone number' }));
    }

    if (Validator.isEmpty(values.companyName)) {
      error = true;
      setErrors((state) => ({ ...state, companyName: 'Name is required' }));
    }

    if (Validator.isEmpty(values.website) || !Validator.isURL(values.website)) {
      error = true;
      setErrors((state) => ({ ...state, website: 'Enter a valid website URL' }));
    }

    if (Validator.isEmpty(values.address)) {
      error = true;
      setErrors((state) => ({ ...state, address: 'Address is required' }));
    }

    return error;
  };

  const onSubmit = async () => {
    const { name, companyName, dbaName, phone, website, address } = values;

    if (validateFields()) {
      return;
    }

    if (!user) {
      return;
    }

    callUpdateUser(user.id, {
      name,
      phone,
      company: {
        name: companyName,
        website,
        address,
        dbaName
      }
    })
      .then((res) => {
        if ((res.code == 200, res.data)) {
          onSuccess(res.data);
        }
      })
      .catch((error) => {
        alert(error);
      });

    callUpdateCompany(user.companyId, {
      name: companyName,
      website,
      address,
      dbaName
    })
      .then((res) => {
        if ((res.code == 200, res.data)) {
          //console.log(res);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleUploadClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const file = event.target.files[0] ?? undefined;
    setSelectedFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSelectedUri(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Paper sx={{ p: 3 }}>
        <Box sx={{ flexDirection: 'row', display: 'flex', mb: 2 }}>
          <TextView $fontSize={26}>Edit account details</TextView>
          <Box sx={{ flex: 1 }} />
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <TextView $fontSize={18}>Personal details</TextView>

        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
          <Box sx={{ mt: 2, flexDirection: 'row', display: 'flex' }}>
            <TextField
              label={'Name'}
              name={'name'}
              placeholder={'Name'}
              errorMessage={errors.name}
              fullWidth
              value={values.name}
              onChange={handleChange('name')}
            />
            <TextField
              label={'Business Email'}
              name={'email'}
              placeholder={'Business Email'}
              errorMessage={errors.email}
              fullWidth
              disabled
              value={values.email}
              onChange={handleChange('email')}
              sx={{ ml: 1 }}
            />
          </Box>
          <Box sx={{ mt: 1, flexDirection: 'row', display: 'flex' }}>
            <TextField
              label={'Phone'}
              name={'phone'}
              placeholder={'Phone'}
              fullWidth
              errorMessage={errors.phone}
              value={values.phone}
              onChange={handleChange('phone')}
            />
            <Box
              sx={{
                flexDirection: 'row',
                display: 'flex',
                flex: 1,
                ml: 2,
                mt: 1,
                alignItems: 'center'
              }}
            >
              <Avatar />
              <Box sx={{ ml: 1 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleUploadClick}
                />
                <TextView $fontSize={12} $fontWeight={400}>
                  Profile picture
                </TextView>
                <label htmlFor="contained-button-file">
                  <TextView
                    $fontSize={12}
                    $fontWeight={400}
                    sx={{ textDecoration: 'underline', color: '#086BDE' }}
                  >
                    Choose a file
                  </TextView>
                </label>
              </Box>
            </Box>
          </Box>
        </Box>

        <TextView $fontSize={18}>Company details</TextView>

        <Box sx={{ mt: 2, flexDirection: 'row', display: 'flex' }}>
          <TextField
            label={'Company name'}
            name={'company_name'}
            placeholder={'Company name'}
            fullWidth
            value={values.companyName}
            errorMessage={errors.companyName}
            onChange={handleChange('companyName')}
          />
          <TextField
            label={'Company DBA name'}
            name={'dba'}
            placeholder={'Company DBA name'}
            fullWidth
            value={values.dbaName}
            errorMessage={errors.dbaName}
            onChange={handleChange('dbaName')}
            sx={{ ml: 1 }}
          />
        </Box>
        <Box sx={{ mt: 2, flexDirection: 'row', display: 'flex' }}>
          <TextField
            label={'Website'}
            name={'website'}
            placeholder={'Website'}
            fullWidth
            value={values.website}
            errorMessage={errors.website}
            onChange={handleChange('website')}
          />
          <TextField
            label={'Address'}
            name={'address'}
            placeholder={'Address'}
            fullWidth
            value={values.address}
            errorMessage={errors.address}
            onChange={handleChange('address')}
            sx={{ ml: 1 }}
          />
        </Box>

        <Button label={'Save account'} onClick={onSubmit} sx={{ mt: 2 }} />
      </Paper>
    </Modal>
  );
};
export default EditAccountModel;
