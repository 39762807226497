import fetchAPI from '../fetchAPI';

interface callGetEngagementOverTimeProps {
  from?: string;
  to?: string;
  companyId?: string;
  teamId?: string;
}

export const callGetEngagementOverTime = async ({
  from,
  to,
  companyId,
  teamId
}: callGetEngagementOverTimeProps): Promise<iResponse<EngagementOverTime>> => {
  const response: iResponse<EngagementOverTime> = await fetchAPI({
    method: `GET`,
    route: `crm/messages/engagement-over-time`,
    queryString: `${from ? `from=${from}` : ``}${to ? `&to=${to}` : ``}${
      companyId ? `&companyId=${companyId}` : ``
    }${teamId ? `&teamId=${teamId}` : ``}`
  });

  return response;
};

export default callGetEngagementOverTime;
