import React, { useState } from 'react';
import { Box, Modal, IconButton, Paper, Grid, LinearProgress } from '@mui/material';
import { Close } from '@mui/icons-material';
import { TextView, Button, TextField, Alert } from '@atoms';

import { callAddKey } from '@particles';

interface AddCompanyTokenProps {
  open: boolean;
  onClose: () => void;
}

const AddKeyModal: React.FC<AddCompanyTokenProps> = ({ onClose, open }: AddCompanyTokenProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = React.useState<string>();
  const [token, setToken] = React.useState<string>();

  const [error, setError] = useState<{
    title: string;
    message: string;
  }>();

  const copyKey = () => {
    navigator.clipboard.writeText(token ?? '');

    onClose();
    setTitle(``);
    setToken(``);
  };
  const onSubmit = () => {
    setLoading(true);
    callAddKey({ name: title })
      .then((res) => {
        if (res.code == 200 && res.data) {
          setToken(res.data.data.key);
          // onSuccess();
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore;
          setError({ title: 'Error', message: res.error?.message ?? '' });
          setTimeout(() => {
            setError(undefined);
          }, 5000);
        }
        setLoading(false);
      })
      .catch((error) => {
        alert(error);
        setLoading(false);
      });
  };

  return (
    <Modal
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <Grid md={4} sm={10} xs={10} item>
        <Paper sx={{ p: 2, width: '100%' }}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <TextView $fontSize={28}>{`Create`}</TextView>
            <Box sx={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <Close />
            </IconButton>
          </Box>
          {loading && <LinearProgress />}

          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 3 }}>
            <TextField
              label={'Title'}
              name={'name'}
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Box>

          {token && (
            <TextView $fontSize={12} $fontWeight={400} sx={{ overflowWrap: 'anywhere' }}>
              authentication : {token}
            </TextView>
          )}
          {error && <Alert severity={'error'} title={error.title} message={error.message} />}

          <Box
            sx={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              mb: 1,
              mt: 4
            }}
          >
            <Button
              label={token ? `Copy Key` : `Create Key`}
              size="small"
              onClick={() => (token ? copyKey() : onSubmit())}
            />
          </Box>
        </Paper>
      </Grid>
    </Modal>
  );
};
export default AddKeyModal;
