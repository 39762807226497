import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';
import { callGetSRTransactionLogs } from '@api';
import { useState, useEffect, useContext } from 'react';
import { TableContext } from '@particles';
import { Button, TextField, TextView } from '@atoms';
import { RouteComponentProps } from '@reach/router';
import { Grid, Popover, Stack } from '@mui/material';
import { DateRangePicker, Range } from 'react-date-range';
import EventIcon from '@mui/icons-material/EventOutlined';
import { DateTime } from 'luxon';
import { materialTheme } from '@particles';
import { toast } from 'react-toastify';

const CompanyTransactionsLogs: React.FC<RouteComponentProps> = () => {
  const {
    state: {
      companyTransactionsLogsPagination: { page, perPage, orderBy }
    }
  } = useContext(TableContext);

  const [start, setStart] = useState<Date>(DateTime.local().minus({ months: 3 }).toJSDate());
  const [end, setEnd] = useState<Date>(new Date());

  const [search, setSearch] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ResponseErrorObj>();
  const [companyTokens, setCompanyToken] = useState<Pagination<SrtTransactionsLogs[]>>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [range, setRange] = useState<Range[]>([
    {
      startDate: DateTime.local().minus({ months: 3 }).toJSDate(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  useEffect(() => {
    const date = new Date();
    const start = range[0]?.startDate ? range[0].startDate : date;
    const end = range[0]?.endDate ? range[0].endDate : date;

    const newEnd = new Date(end);
    newEnd.setHours(23);
    newEnd.setMinutes(59);
    newEnd.setSeconds(59);

    setStart(start);
    setEnd(end);
  }, [range]);

  const downloadMonthlyLogs = async (download?: boolean) => {
    setLoading(true);

    const startDate = DateTime.fromJSDate(start).toISO({
      suppressMilliseconds: true,
      includeOffset: false
    });

    const endDate = DateTime.fromJSDate(end).toISO({
      suppressMilliseconds: true,
      includeOffset: false
    });

    if (download) {
      const token = localStorage.getItem('token');
      const apiBase = process.env.REACT_APP_API_BASE;

      const queryString = `${page ? `pageNumber=${page}` : ``}
      ${perPage ? `&pageSize=${perPage}` : ``}
      ${startDate ? `&startDate=${startDate}` : ``}
      ${endDate ? `&endDate=${endDate}` : ``}
      ${orderBy ? `&sortBy=${orderBy}` : ``}
      ${search ? `&companyName=${search}` : ``}`;

      const urlApi = `${apiBase}/srt-transaction-log?=${queryString}&downloadCsv=true`;

      const zone: string[] = DateTime.now().toFormat('ZZ').split(':');
      const hour = Number(zone[0]);
      const mint = Number(zone[1]);
      const totalMint: number = hour * 60 + mint;

      const fetchOptions: RequestInit = {
        headers: {
          Authorization: `Bearer ${token}` ?? '',
          'Content-Type': 'application/json',
          timezone: `${totalMint}`
        },
        method: 'GET'
      };

      const response: Response = await fetch(urlApi, fetchOptions);

      if (response.status == 200) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'MonthlyLogs.csv');
        document.body.appendChild(link);
        link.click();
      } else {
        toast('Something went wrong');
      }
    } else {
      if (startDate && endDate) {
        const { data, code, error } = await callGetSRTransactionLogs({
          pageNumber: page,
          pageSize: perPage,
          companyName: search,
          startDate,
          endDate,
          sortBy: orderBy
        });
        if (code == 200) {
          setCompanyToken(data);
        } else if (error) {
          setError(error);
        }
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    downloadMonthlyLogs();
  }, [page, perPage, search, start, end, orderBy]);

  const rowData: TableRow[] = companyTokens ? generateRows(companyTokens.data) : [];

  return (
    <Box m={4}>
      <Stack>
        <TextView display={'initial'} $fontSize={30}>
          Usage History
        </TextView>

        <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            sx={{ mb: 2 }}
            label={'Search Location Name'}
            name={'Search Name'}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Box sx={{ flex: 1 }} />
          <Grid item xl={10} sm={12} sx={{ justifyContent: 'flex-end', alignItems: 'end' }}>
            <Grid mb={2} spacing={2} container>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <DateRangePicker
                  onChange={(item) => setRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={range}
                  direction="horizontal"
                />
              </Popover>
              <Grid item>
                <Button
                  aria-describedby={id}
                  sx={{ paddingLeft: 5, color: materialTheme.palette.text.primary }}
                  startIcon={<EventIcon />}
                  variant={'outlined'}
                  onClick={handleClick}
                >
                  <TextView $fontWeight={400} >
                    {DateTime.fromJSDate(
                      range[0].startDate ? range[0]?.startDate : new Date()
                    ).toFormat('LLLL, dd yyyy')}
                    {` - `}
                    {DateTime.fromJSDate(
                      range[0].endDate ? range[0]?.endDate : new Date()
                    ).toFormat('LLLL, dd yyyy')}
                  </TextView>
                </Button>
              </Grid>
              <Grid item>
                <Button label="Export" onClick={() => downloadMonthlyLogs(true)} size="small" />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Stack>

      <Table
        name="company-transactions-logs"
        columns={columns}
        data={rowData}
        error={error}
        isLoading={loading}
        pagination={companyTokens}
      />
    </Box>
  );
};

export default CompanyTransactionsLogs;
