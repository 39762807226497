import fetchAPI from '../fetchAPI';

export const callGetUserRoles = async (): Promise<iResponse<{ data: UserRoles[] }>> => {
  const response: iResponse<{ data: UserRoles[] }> = await fetchAPI({
    method: `GET`,
    route: `user-roles`
  });

  // user?companyId=company-id
  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callGetUserRoles;
