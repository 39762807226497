import fetchAPI from '../fetchAPI';

interface callGetEngagedPeopleProps {
  from: string;
  to: string;
  companyId?: string;
}

export const callGetEngagedPeople = async ({
  from,
  to,
  companyId
}: callGetEngagedPeopleProps): Promise<iResponse<EngagedPeople>> => {
  const response: iResponse<EngagedPeople> = await fetchAPI({
    method: `GET`,
    route: `crm/messages/engaged-people`,
    queryString: `from=${from}&to=${to}${companyId ? `&companyId=${companyId}` : ''}`
  });

  return response;
};

export default callGetEngagedPeople;
