import styled from '@emotion/styled';

const StyledLink = styled.div`
  .link-hide-styling {
    color: unset;
    text-decoration: none;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
      color: unset;
    }
  }
`;

export default StyledLink;
