import fetchAPI from '../fetchAPI';

export const callDeleteInviteUser = async (id: string): Promise<iResponse<User>> => {
  const response: iResponse<User> = await fetchAPI({
    method: `DELETE`,
    route: `agent/invite/${id}`
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callDeleteInviteUser;
