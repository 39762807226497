import { routes } from '@particles';

const generateRows = (list: CompanyDetailed[]): TableRow[] =>
  list.map((item) => {

    const license_status = item.license_status;
    const sent_messages = item.sent_messages ?? 0;
    const received_messages = item.received_messages ?? 0;
    const total_messages = item.total_messages ?? 0;
    const endDate = item.endDate;
    const total_licenses = item.total_licenses;
    const active_users = item.active_users;
    const inactive_users = item.inactive_users;
    const total_users = item.total_users;

    return {
      name: item.name ?? '',
      total_licenses,
      sent_messages,
      received_messages,
      total_messages,
      endDate: endDate ? new Date(endDate).toLocaleDateString() : '-',
      license_status,
      active_users,
      inactive_users,
      total_users,
     // average: isNaN(totalMessage / totalUser) ? '-' : (totalMessage / totalUser).toFixed(0),
      linkData: {
        to: `${routes.SA_NegotiatorDetails}/${item.id}`
      }
    };
  });

export default generateRows;
