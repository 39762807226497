import React from 'react';
import { Box, Stack } from '@mui/material';
import TextView from '../textView';
import Modal from '../modal';
import Button from '../button';

export interface AlertPopUpProps {
  /** Title for the alert */
  open: boolean;
  /** Title for the alert */
  title: string;
  /** Message for the alert */
  message: string;
  /** Function fired when the component requests to be closed */
  onClose: () => void;
  /** Callback fired when the component requests to be closed */
  onSuccess?: () => void;
}

export const AlertPopUp: React.FC<AlertPopUpProps> = ({
  open,
  title,
  message,
  onClose,
  onSuccess
}: AlertPopUpProps) => (
  <Modal open={open} onClose={() => onClose()}>
    <Box sx={{ width: 300 }}>
      {title && <TextView mb={1}>{title}</TextView>}
      <TextView $fontWeight={400}>{message}</TextView>
      {onSuccess && (
        <Stack direction={'row'} justifyContent={'flex-end'} mt={2}>
          <Button onClick={onSuccess}>Yes</Button>
          <Button sx={{ ml: 2 }} onClick={onClose}>
            No
          </Button>
        </Stack>
      )}
    </Box>
  </Modal>
);

export default AlertPopUp;
