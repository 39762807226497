import fetchAPI from '../fetchAPI';

export const callUpdateCompanyToken = async (
  id: string,
  data: any
): Promise<iResponse<CompanyToken>> => {
  const response: iResponse<CompanyToken> = await fetchAPI({
    method: `PATCH`,
    route: `company-token/${id}`,
    data
  });

  return response;
};

export default callUpdateCompanyToken;
