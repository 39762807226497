import fetchAPI from '../fetchAPI';

export const callSyncLocation = async (
  selectedAccounts: string[]
): Promise<iResponse<{ data: { customValues: CustomValues[]; code: number } }>> => {
  const response: iResponse<{ data: { customValues: CustomValues[]; code: number } }> =
    await fetchAPI({
      method: `POST`,
      route: `core/ghl-location`,
      data: { locationIds: selectedAccounts }
    });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callSyncLocation;
