import React, { useEffect, useState } from 'react';
import { Box, LinearProgress, Stack, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Text } from '@atoms';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));

export default function UsageThisMonth({
  company,
  usageData
}: {
  company?: CompanyToken;
  usageData?: SrtToken[];
}) {
  const [consume, setConsume] = useState<number>(0);

  useEffect(() => {
    // Initialize a variable to store the sum
    let totalSrtToken = 0;

    // Loop through the data array and accumulate srtToken values
    for (const item of usageData ?? []) {
      totalSrtToken += Number(item.srtToken);
    }
    setConsume(Number(totalSrtToken.toFixed(2)));
  }, [usageData]);

  const allocatedToken = company?.totalToken ?? 0;
  const overLimit = allocatedToken - consume;
  return (
    <Box mt={6} mb={3}>
      <Stack mb={1} direction={'row'}>
        <Text fontSize={22} fontWeight={600}>
          Usage this month
        </Text>
        <Text
          fontSize={22}
          fontWeight={400}
          sx={{ mt: 0.5, ml: 2 }}
        >{`  ( ${consume} / ${allocatedToken} )`}</Text>
      </Stack>
      {overLimit < 0 && (
        <Box mb={2}>
          <Text color={'red'}>{`You have exceeded your allocated token limit.`}</Text>
          <Text color={'red'}>
            {` If you have any questions or need
          assistance, please contact our support team.`}
          </Text>
        </Box>
      )}
      <BorderLinearProgress
        variant="determinate"
        value={overLimit > 0 ? (consume / allocatedToken) * 100 : 100}
      />
    </Box>
  );
}
